import gql from "graphql-tag";

export const DOES_SLUG_EXISTS = gql`
    query DoesSlugExists($dealWhere: JSON, $pageWhere: JSON) {
        deals(where: $dealWhere) {
            id
            slug
        }
        pages(where: $pageWhere){
            id
            slug
        }
    }
`;
