import React from 'react';
import { Modal, ModalBody, ModalHeader, Button, FormGroup, Label, Input } from 'reactstrap';

const EditImageModal = ({ values, handleChange, toggleModal, isModalVisible, imageIndex }) => {
  function handleFileNameChange(e) {}

  return (
    <Modal size="lg" isOpen={isModalVisible} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>Afbeelding uploaden</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="name">Titel</Label>
          <Input
            type="text"
            onChange={handleFileNameChange}
            value={values.media[imageIndex] ? values.media[imageIndex].name : ''}
            name="name"
            id="name"
            placeholder="titel afbeelding"
          />
        </FormGroup>
        <Button color="success">Opslaan</Button>
      </ModalBody>
    </Modal>
  );
};

export default EditImageModal;
