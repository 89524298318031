import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';

// contexts
import AuthContext from 'contexts/authContext';

// Components
import Navbar from 'components/Navbar';
import Auth from 'pages/Auth';
import DealsUpdate from 'pages/Deals/Update';
import DealsOverview from 'pages/Deals/Overview';
import Content from 'pages/Content';
import Marketing from 'pages/Marketing';
import PrivateRoute from 'containers/PrivateRoute';
import NotFound from 'pages/NotFound';
import GlobalStyles from 'styles/globalStyles';
import DealNew from 'pages/Deals/New';
import { Alert } from 'reactstrap';

require('dotenv').config();

function getUser() {
  const token = localStorage.getItem('token');
  const user = localStorage.getItem('user');
  return {
    token,
    user,
    isAuthenticated: () => {
      return Boolean(token) && Boolean(user);
    },
  };
}

function isJWTStored() {
  return Cookies.get('token');
}

//TODO: Make request to Graphql to check if user is authenticated. This must be done inside of ComponentDidMount lifecycle.

class App extends Component {
  constructor(props) {
    super(props);

    if (!isJWTStored()) {
      localStorage.clear();
      this.state = {
        token: null,
        user: null,
        loading: false,
        loginError: false,
        status: false,
      };
    } else {
      this.state = {
        token: getUser().token,
        user: getUser().user,
        loading: false,
        loginError: false,
        status: false,
      };
    }

    this.localStorageUpdated = this.localStorageUpdated.bind(this);
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      this.setState({ status: localStorage.getItem('isTokenExpired') ? true : false });

      window.addEventListener('storage', this.localStorageUpdated);
    }
  }
  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('storage', this.localStorageUpdated);
    }
  }

  localStorageUpdated() {
    if (!localStorage.getItem('isTokenExpired')) {
      this.updateState({ status: true });
    } else if (!this.state.status) {
      this.updateState({ status: false });
    }
  }

  updateState(value) {
    this.setState({ status: value });
  }

  login = (token, user) => {
    Cookies.set('token', token, { expires: 29 });
    Cookies.set('user', user, { expires: 29 });
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));
    this.setState({ token, user });
    window.location.reload(true);
  };

  logout = () => {
    Cookies.remove('token', { path: '' });
    Cookies.remove('user', { path: '' });
    localStorage.clear();
    this.setState({ token: null, user: null, status: false });
  };

  render() {
    return (
      <>
        <GlobalStyles />
        <Router>
          <AuthContext.Provider
            value={{
              token: this.state.token,
              user: this.state.user,
              login: this.login,
              logout: this.logout,
            }}
          >
            {this.state.status && (
              <Alert color="danger">
                Sessie is verlopen. Log aub opnieuw in om verder te gaan.
              </Alert>
            )}

            <Navbar />
            {this.state.loading ? (
              <h4>Loading...</h4>
            ) : (
              <Switch>
                {!this.state.token && <Redirect from="/" to="/auth" exact />}
                {this.state.token && <Redirect from="/" to="deals" exact />}
                {this.state.token && <Redirect from="/auth" to="deals" />}
                <Route path="/auth" component={Auth} />
                <PrivateRoute path="/deals" component={DealsOverview} exact />
                <PrivateRoute path="/deals/new" exact component={DealNew} />
                <PrivateRoute path="/deals/:id?" component={DealsUpdate} />
                {/* <PrivateRoute path="/content/destinations/:id" component={DestinationUpdate} />
                <PrivateRoute path="/content/accommodations/:id" component={AccommodationUpdate} /> */}
                {/* <PrivateRoute path="/content/accommodations/:id" component={Accommodation} /> */}
                <PrivateRoute path="/content" component={Content} />
                <PrivateRoute path="/marketing" component={Marketing} />
                {/* <Route path="/content/destinations" component={Destinations} /> */}
                <Route path="*" component={NotFound} />
              </Switch>
            )}
          </AuthContext.Provider>
        </Router>
      </>
    );
  }
}

export default App;
