import React, { useState, memo } from 'react';
import {
  Button,
  Collapse,
  Col,
  Row,
  Alert,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  Label,
} from 'reactstrap';
import { Mutation } from 'react-apollo';
import { CREATE_BOOKINGEXAMPLES, DELETE_BOOKINGEXAMPLE } from 'queries/deals';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import DragSortableList from 'react-drag-sortable';
import { createEventTarget } from 'utils/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faPlusCircle } from '@fortawesome/free-solid-svg-icons';

import UpdateBookingExample from 'containers/Deal/UpdateBookingExample';
import BookingExample from 'containers/Deal/BookingExample';
import DealImg from 'components/DealImg';

const baseUrl = process.env.REACT_APP_BASE_URL;

function checkIfDuplicateExists(w){
  return new Set(w).size !== w.length
}

function createPromises(bookingExamples, token) {
  return bookingExamples.map(bookingExample => {
    let formData = new FormData();
    formData.append('files', bookingExample.localFile);
    formData.append('alt', bookingExample.title);
    formData.append('type', 'booking_example');
    return axios({
      url: `${baseUrl}/upload`,
      method: 'POST',
      headers: {
        authorization: `Bearer ${token}`,
      },
      data: formData,
    });
  });
}

function getLocalFiles(files) {
  return files.map(example => {
    return example.localFile;
  });
}

const BookingExamples = memo(props => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [bookingExamples, setBookingExamples] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  // const [updateBookingExampleOpen, setUpdateBookingExampleOpen] = useState(false);
  const [selectedBookingExample, setSelectedBookingExample] = useState(null);

  const [displaySuccessMessage, setDisplaySuccessMessage] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [altError, setAltError] = useState(null);

  function toggleUpdateBookingExampleOpen(bookingExample) {
    if (!bookingExample) {
      return setSelectedBookingExample(null);
    }
    setSelectedBookingExample(bookingExample);
  }

  function handleDeleteBookingExample(event) {
    const deletedBookingExampleId = event.deleteBookingexample.bookingexample.id;
    const newBookingExamples = props.values.booking_examples.filter(example => {
      return deletedBookingExampleId !== example.id;
    });
    props.handleChange(createEventTarget('booking_examples', newBookingExamples));
  }

  function onSort(sortedImages) {
    const formattedExamples = sortedImages.map(image => {
      return image.value;
    });
    props.handleChange({
      target: {
        name: 'booking_examples',
        value: formattedExamples,
      },
    });
  }

  function onDrop(acceptedFiles) {
    const result = acceptedFiles.map(localFile => {
      const localImageUrl = URL.createObjectURL(localFile);
      return {
        title: '',
        url: '',
        localImageUrl,
        localFile,
      };
    });
    addBookingExample(result);
  }

  const sortableBookingExamples = props.values.booking_examples.map(example => {
    return {
      content: (
        <Mutation mutation={DELETE_BOOKINGEXAMPLE} onCompleted={handleDeleteBookingExample}>
          {(deleteBookingexample, { loading, error, data }) => (
            <DealImg
              src={example.image ? `${example.image.url}` : `${baseUrl}/notfound`}
              onImageClicked={() => toggleUpdateBookingExampleOpen(example)}
              isBookingExample={true}
              title={example.title}
              url={example.url}
              handleOnClickDelete={() => deleteBookingexample({ variables: { id: example.id } })}
            />
          )}
        </Mutation>
      ),
      value: {
        id: example.id,
        title: example.title,
        url: example.url,
        image: example.image,
      },
    };
  });

  async function uploadBookingExamples(callback) {
    const token = localStorage.getItem('token');
    // const files = getLocalFiles(bookingExamples);

    const alts = bookingExamples.map(file => file.title);
    const duplicateExists = checkIfDuplicateExists(alts);
    if(duplicateExists){
      setAltError('Titels moeten uniek zijn');
      return null
    }

    if(altError){
      setAltError(null)
    }

    setIsUploading(true);
    const promises = createPromises(bookingExamples, token);
    const results = await Promise.all(promises);

    const createdBookingExamples = getLocalFiles(bookingExamples).map((file, index) => {
      return {
        title: bookingExamples[index].title,
        url: bookingExamples[index].url,
        image: results[index].data[0].id,
      };
    });

    callback({
      variables: {
        bookingExamples: createdBookingExamples,
      },
    });
  }

  function handleError() {
    setIsUploading(false);
    setDisplayError(true);
  }

  function handleUpdateCompletion(event) {
    handleUpdateSuccess();
    props.handleBookingExamplesChange(event.createBookingexamples.bookingexample);
  }

  function handleUpdateSuccess() {
    setIsUploading(false);
    setDisplaySuccessMessage(true);
    setTimeout(() => {
      setDisplaySuccessMessage(false);
    }, 3000);
    setBookingExamples([]);
  }

  function addBookingExample(newBookingExamples) {
    setBookingExamples([...bookingExamples, ...newBookingExamples]);
  }

  function onChange(event, index) {
    const newBookingExamples = bookingExamples.map((bExample, bIndex) => {
      if (bIndex === index) {
        return { ...bExample, [event.target.name]: event.target.value };
      }
      return bExample;
    });
    setBookingExamples(newBookingExamples);
  }

  function removeBookingExample(index) {
    const newBookingExamples = bookingExamples.filter((example, examplesIndex) => {
      return index !== examplesIndex;
    });
    setBookingExamples(newBookingExamples);
  }

  function toggleIsOpen() {
    setIsOpen(!isOpen);
  }

  function toggleModal() {
    setIsModalVisible(!isModalVisible);
  }

  function handleSaveBookingExample(bookingExample) {
    const newBookingExamples = props.values.booking_examples.map(example => {
      if(example.id === bookingExample.id){
        return {...example, ...bookingExample};
      }
      return example
    });
    props.handleChange(createEventTarget('booking_examples', newBookingExamples));
    setSelectedBookingExample(null);
    // setSelectedBookingExample(null);
    // const newBookingExamples = props.values.booking_examples.map(example => {
    //   if(bookingExample.id === example.id) return bookingExample;
    //   return example
    // });
    // props.onBookingExampleUpdate(newBookingExamples);
  }

  return (
    <Mutation
      mutation={CREATE_BOOKINGEXAMPLES}
      onCompleted={handleUpdateCompletion}
      onError={handleError}
    >
      {(saveBookingExamples, { loading, error }) => (
        <>
          <Label>Boekingsvoorbeelden</Label>
          <Row className={isOpen ? 'mb-3' : 'mb-0'}>
            <Col lg={3} className="d-flex align-items-center">
              <Button
                outline
                color="secondary"
                className="d-flex align-items-center"
                type="button"
                disabled={loading || error}
                onClick={toggleModal}
              >
                <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                Toevoegen
              </Button>
            </Col>
            <Col lg={{ size: 3, offset: 6 }} className="d-flex align-items-center">
              <span style={{ cursor: 'pointer' }} className="ml-auto" onClick={toggleIsOpen}>
                {isOpen && <FontAwesomeIcon icon={faAngleUp} className="mr-2" />}
                {!isOpen && <FontAwesomeIcon icon={faAngleDown} className="mr-2" />}
                {isOpen ? 'Verbergen' : 'Tonen'}
              </span>
            </Col>
          </Row>
          <Collapse isOpen={isOpen}>
            <DragSortableList
              items={sortableBookingExamples}
              onSort={onSort}
              type="grid"
              className="d-flex"
            />
            {props.bookingExamples.length < 1 && <p>Er zijn geen boekingsvoorbeelden gevonden..</p>}
          </Collapse>

          <UpdateBookingExample
            currentBookingExample={selectedBookingExample}
            toggleUpdateBookingExampleOpen={toggleUpdateBookingExampleOpen}
            onSave={handleSaveBookingExample}
          />

          <Modal size="lg" isOpen={isModalVisible} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>Afbeelding uploaden</ModalHeader>
            <ModalBody>
              {displaySuccessMessage && (
                <Alert color="success">Boekingsvoorbeeld(en) zijn succesvol toegevoegd!</Alert>
              )}
              {displayError && (
                <Alert color="danger">Oeps er is iets mis gegaan, probeer het later nog eens</Alert>
              )}
              {isUploading && (
                <div>
                  <Spinner color="primary" /> <p>laden...</p>
                </div>
              )}
              <Dropzone onDrop={acceptedFiles => onDrop(acceptedFiles)}>
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div
                      style={{ padding: '2rem 3rem', border: 'dashed 4px black' }}
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      <p>Sleep een afbeelding hier naar toe, of klik op mij!</p>
                    </div>
                  </section>
                )}
              </Dropzone>
              {altError && <Alert color="danger">{altError}</Alert>}
              <div>
                {bookingExamples.length > 0 ? (
                  bookingExamples.map((example, index) => (
                    <BookingExample
                      loading={loading}
                      error={error}
                      removeBookingExample={removeBookingExample}
                      key={index}
                      bookingExample={example}
                      onChange={onChange}
                      index={index}
                    />
                  ))
                ) : (
                  <p>Selecteer eerst een afbeelding</p>
                )}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                disabled={loading || error || bookingExamples.length < 1}
                color="success"
                onClick={() => uploadBookingExamples(saveBookingExamples)}
              >
                {(loading || error) && <Spinner color="white" />}Opslaan
              </Button>
            </ModalFooter>
          </Modal>
        </>
      )}
    </Mutation>
  );
}, areEqual );

function areEqual(prevProps, nextProps) {
  return JSON.stringify(prevProps.values.booking_examples) === JSON.stringify(nextProps.values.booking_examples);
}

export default BookingExamples;
