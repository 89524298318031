import React, { useState } from 'react';
import { NavLink, Route } from 'react-router-dom';
import { Row, Col, ListGroup, ListGroupItem } from 'reactstrap';
import DailyDeals from './DailyDeals'
import NewsLetter from './NewsLetter'

const Marketing = ({ match }) => {

  return (
    <Row>
      <Col
        lg={2}
        className="px-0"
        style={{
          borderRight: '1px solid rgba(0,0,0,0.24)',
          backgroundColor: 'white',
          minHeight: '100%',
        }}
      >
        <Row>
          <Col lg={12} className="pt-5">
            <div className="px-5">
              <h6 className="mb-4">Marketing</h6>
            </div>
            <ListGroup className="own-list-group mb-0">
              <NavLink to="/marketing/nieuwsbrief" activeClassName="active-list-group-item">
                <ListGroupItem>
                  <span>Nieuwsbrief</span>
                </ListGroupItem>
              </NavLink>
            </ListGroup>
            <hr />
          </Col>
          <hr />
        </Row>
        <Row>
          <Col lg={12} className="pt-5">
            <ListGroup className="own-list-group mb-0">
              <NavLink to="/marketing/deal-van-de-dag" activeClassName="active-list-group-item">
                <ListGroupItem>
                  <span>Deal van de dag</span>
                </ListGroupItem>
              </NavLink>
            </ListGroup>
            <hr />
          </Col>
          <hr />
        </Row>
      </Col>


      <Col style={{ backgroundColor: '#FBFBFD', minHeight: '100vh' }} className="p-5">
        <Row className="d-flex justify-content-center">
          <Col >
            <Route
              exact
              path={`${match.url}/deal-van-de-dag`}
              render={DailyDeals}
            />
            <Route
              exact
              path={`${match.url}/nieuwsbrief`}
              render={NewsLetter}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Marketing;
