import React, { useState } from 'react';
import { NavLink as RouterLink, withRouter, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
  Button,
} from 'reactstrap';
import { Query } from 'react-apollo';
import { GET_CURRENT_USER, GET_WEBSITES } from './apollo.js';

import AuthContext from 'contexts/authContext';
import WfcLogo from 'assets/wfc-logo.png';
import NLVlag from 'assets/nl-vlag.png';
import BEVlag from 'assets/be-vlag.png';
import userProfile from 'assets/user-profile.png';

const Navigationbar = ({ history }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [newButtonVisible, setNewButtonVisisible] = useState(true);
  const [country, setCountry] = useState(localStorage.getItem('country') === 'BE' ? 'BE' : 'NL')

  async function toggle() {
    await setIsOpen(!isOpen);
    if (!newButtonVisible) {
      setTimeout(() => {
        setNewButtonVisisible(true);
      }, 400);
    } else {
      setNewButtonVisisible(false);
    }
  }

  function handleSetCountry(whatCountry, id) {
    setCountry(whatCountry)
    localStorage.setItem('country', whatCountry)
    localStorage.setItem('country_id', id)

    if (history.location.pathname === '/deals') {

    }
    if (localStorage.getItem('country_id') === id) {
      history.push(`/deals`);
      window.location.reload();
    }
  }

  return (
    <AuthContext.Consumer>
      {context => (
        <Navbar
          color="white"
          light
          expand="md"
          style={{ borderBottom: '1px solid rgba(0,0,0,0.24)' }}
          className="py-2"
        >
          <Container>
            <Link to={`/`}
            >
              <NavbarBrand

                style={{ borderRight: '1px solid rgba(0,0,0,0.24)', paddingRight: '20px' }}
              >
                <img
                  src={WfcLogo}
                  alt="wfc logo"
                  className="img-fluid"
                  style={{ width: '190px', height: 'auto' }}
                />
              </NavbarBrand>
            </Link>
            {!newButtonVisible && <NavbarToggler onClick={toggle} />}
            <Collapse isOpen={isOpen} navbar>
              {context.token && (
                <>
                  <Nav navbar className="ml-1">
                    <NavItem>
                      <RouterLink to="/deals" className="nav-link">
                        Deals
                      </RouterLink>
                    </NavItem>
                    <NavItem>
                      <RouterLink to="/content" className="nav-link">
                        Content
                      </RouterLink>
                    </NavItem>
                    <NavItem>
                    <RouterLink to="/marketing" className="nav-link">
                      Marketing
                    </RouterLink>
                  </NavItem>
                  </Nav>
                </>
              )}
            </Collapse>
            {history.location.pathname.includes('destinations') && newButtonVisible && (
              <Link to={`/content/destinations/new`} className="d-flex mr-2">
                <Button color="success" className="d-flex align-items-center" type="button">
                  <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                  Bestemming
                </Button>
              </Link>
            )}
            {history.location.pathname.includes('accommodations') && newButtonVisible && (
              <Link to="/content/accommodations/new" className="d-flex mr-2">
                <Button color="success" className="d-flex align-items-center" type="button">
                  <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                  Accommodatie
                </Button>
              </Link>
            )}
            {history.location.pathname.includes('deals') && newButtonVisible && (
              <Link to="/deals/new" className="d-flex mr-2">
                <Button color="success" className="d-flex align-items-center" type="button">
                  <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                  Deal
                </Button>
              </Link>
            )}
            {context.token && (
              <Nav navbar className="ml-auto">
                <UncontrolledDropdown nav inNavbar className="d-flex align-items-center">
                  <DropdownToggle nav caret className="nav-link">
                    <img src={country === 'NL' ? NLVlag : BEVlag} className="img-responsive img-rounded"
                      style={{ height: '38px', borderRadius: '4px', width: '54px' }}></img>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <Query query={GET_WEBSITES}>
                      {({ loading, error, data }) => {
                        if (error) return <span>error</span>
                        const lsCountry = localStorage.getItem('country') ? localStorage.getItem('country') : 'NL'
                        if (data && data.websites) {
                          let id = data.websites.find(website => website.country_code === lsCountry) ? data.websites.find(website => website.country_code === lsCountry).id : ''
                          localStorage.setItem('country_id', id)
                          return (
                            <DropdownItem onClick={() => handleSetCountry(country === 'NL' ? 'BE' : 'NL', id)} className="d-flex justify-content-center">
                              <img src={country === 'NL' ? BEVlag : NLVlag} className="img-responsive img-rounded"
                                style={{ height: '38px', borderRadius: '4px', width: '54px' }}></img>
                            </DropdownItem>
                          );
                        }
                        return (<></>);
                      }}
                    </Query>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret className="nav-link">
                    <Query query={GET_CURRENT_USER}>
                      {({ loading, error, data }) => {
                        return (
                          <>
                            {data && data.me && (
                              <img
                                alt="user profile"
                                src={data.me.avatar ? data.me.avatar.url : userProfile}
                                className="img-responsive img-rounded"
                                style={{ height: '50px', borderRadius: '50%' }}
                              />
                            )}
                          </>
                        );
                      }}
                    </Query>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem onClick={() => context.logout()}>Uitloggen</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>

              </Nav>
            )}
            {newButtonVisible && <NavbarToggler onClick={toggle} />}
          </Container>
        </Navbar>
      )}
    </AuthContext.Consumer>
  );
};

export default withRouter(Navigationbar);
