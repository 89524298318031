import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks';
import client from 'apollo/client';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
  faMinusCircle,
  faLink,
  faMinus,
  faPlusCircle,
  faEdit,
  faCopy,
    faUserEdit
} from '@fortawesome/free-solid-svg-icons';

library.add(fab, faMinusCircle, faLink, faMinus, faPlusCircle, faEdit, faCopy, faUserEdit);

ReactDOM.render(
  <ApolloProvider client={client}>
    <ApolloHooksProvider client={client}>
      <App />
    </ApolloHooksProvider>
  </ApolloProvider>,
  document.getElementById('root'),
);
