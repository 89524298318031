import axios from 'axios';
const baseUrl = process.env.REACT_APP_BASE_URL;

export function createEventTarget(name, value) {
  return {
    target: {
      name,
      value,
    },
  };
}

export function formatBoardTypes(boardTypes) {
  return boardTypes.map(board_type => ({ label: board_type.name, value: board_type.id }));
}

export function formatProviders(providers) {
  return providers.map(provider => ({ label: provider.name, value: provider.id }));
}

export const getDealsAsync = async (inputValue, types, field) => {
  const API_URL = `${baseUrl}/graphql`;
  const token = localStorage.getItem('token');
  const countryId = localStorage.getItem('country_id');

  async function loadData() {
    const data = await axios.post(
      API_URL,
      {
        query: `
            query{
              ${types}(where: {
                website: "${countryId}"
                ${field}: "${inputValue}"
              }) {
                id
                title
                selected_media
                media
                affiliate_url
                slug
              }
          }
        `,
        variables: {},
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${token}`,
        },
      },
    );

    const formattedData = data.data.data[types].map(type => {
      const filteredSelectedMedia = [];

      if (type.selected_media && type.selected_media.length) {
        type.selected_media &&
          type.selected_media.length &&
          type.selected_media.forEach(selectedMedia => {
            if (type.media && type.media.length) {
              type.media.forEach(media => {
                if (media?.id === selectedMedia?.id) filteredSelectedMedia.push(media);
              });
            }
          });
      }

      return {
        value: type.id,
        label: type.title,
        selectedMedia: filteredSelectedMedia,
        affiliate_url: type.affiliate_url,
        slug: type.slug,
      };
    });

    return formattedData;
  }

  if (!inputValue) return null;
  return new Promise(resolve => {
    const data = loadData();
    resolve(data);
  });
};
