import React from 'react';
import PropTypes from 'prop-types';

import { Text } from './LengthValidatorCSS';

const LengthValidator = ({ countThisValue, maxValue, length = false }) => {
  return (
    <>
      {length !== false && (
        <Text valid={length <= maxValue}>
          {length}/{maxValue}
        </Text>
      )}
      { countThisValue && (
        <Text valid={countThisValue.length <= maxValue}>
          {countThisValue.length}/{maxValue}
        </Text>
      )}
    </>
  );
};

LengthValidator.propTypes = {
  countThisValue: PropTypes.string,
  maxValue: PropTypes.number,
  length: PropTypes.number,
};

export default LengthValidator;
