export function formatForUpdate(values) {
  const country = localStorage.getItem('country');
  return {
    id: values.id,
    show_on_homepage: values.show_on_homepage ? values.show_on_homepage : false,
    show_on_be: country === 'BE' || values.show_on_be,
    meta_title: values.meta_title.trim(),
    meta_description: values.meta_description.trim(),
  };
}
