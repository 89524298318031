import styled from 'styled-components';

export const Img = styled.img`
  background-image: url(${props => props.imgsrc});
  position: relative;
  float: left;
  width: 100%;
  height: 200px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px;
  outline: ${props => (props.selected ? '4px solid #007bff' : '0px solid #f5f5f5')};
  opacity: ${props => (props.alreadySelected ? '0.3' : '1')};
  cursor: ${props => (props.alreadySelected ? 'default' : 'pointer')};
`;
