import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Wrapper } from './ManuallyPriceCSS';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const formatPriceForEdit = price => {
  return {
    ...price,
    board_type: price?.board_type?.id
      ? { label: price.board_type.name, value: price.board_type.id }
      : price?.board_type,
  };
};

const ManuallyPrice = ({ price, handleDeletePrice, onClickEdit, isEditable = false }) => {
  useEffect(() => {}, [price]);
  return (
    <Wrapper style={{ color: '#3E3F42', display: 'flex', alignItems: 'center' }} className="mb-2">
      <>
        <FontAwesomeIcon
          style={{
            fontSize: '18px',
            marginRight: '8px',
            marginLeft: '-11px',
            cursor: 'pointer',
            color: '#9EA0A5',
          }}
          icon="minus-circle"
          onClick={() => handleDeletePrice(price.id)}
        />
      </>
      {isEditable && (
        <>
          <FontAwesomeIcon
            onClick={() => onClickEdit(formatPriceForEdit(price))}
            style={{ fontSize: '18px', marginRight: '8px', marginLeft: '-11px', color: '#9EA0A5' }}
            icon="user-edit"
          />
        </>
      )}
      <p className="mb-0" style={{ marginLeft: !isEditable ? '8px' : '0px' }}>
        Startdatum: {price.date_from ? moment(price.date_from).format('DD-MM-YYYY') : '*'}
      </p>
      <p className="mb-0">{price.persons ? price.persons : '0'} personen</p>
      <p className="mb-0">Uitgelicht: {price.highlight ? <b>Ja</b> : 'Nee'}</p>
      <p className="mb-0">{price.day_count ? price.day_count : '0'} dagen</p>
      <p className="mb-0">VL: {price.departureairport ? price.departureairport.name : '*'}</p>
      <p className="mb-0">VT: {price.board_type.name ? price.board_type.name : '*'}</p>
      <p className="mb-0">Prijs: {price.price ? price.price : '*'}</p>
      <p className="mb-0">
        Einddatum: {price.date_to ? moment(price.date_to).format('DD-MM-YYYY') : '*'}
      </p>
      {price && price.deeplink && (
        <p className="mb-0">
          <a target="_blank" href={price.deeplink}>
            deeplink
          </a>
        </p>
      )}
      {!isEditable && (
        <p className="mb-0">type: {price.manual_price ? <b>Handmatig</b> : <b>Qenner</b>}</p>
      )}
    </Wrapper>
  );
};

ManuallyPrice.propTypes = {
  isEditable: PropTypes.bool,
  onClickEdit: PropTypes.func.isRequired,
  price: PropTypes.object.isRequired,
  handleDeletePrice: PropTypes.func.isRequired,
};

export default ManuallyPrice;
