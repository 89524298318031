import React, { useEffect } from 'react';
import { CREATE_DEAL, GET_WEBSITES } from 'queries/deals';
import { Mutation, Query } from 'react-apollo';
import moment from 'moment';
import {
  Col,
  Row,
  Spinner
} from 'reactstrap';

let currentWebsiteId = '';

const DealNew = ({ history }) => {

  function redirect(data) {
    history.push(`/deals/${data.createDealConcept.dealConcept.id}`);
  }

  return (
    <Query query={GET_WEBSITES}>
      {({ loading, error, data }) => {
        if (error) return <h1>Er is een probleem opgetreden</h1>
        const lsCountry = localStorage.getItem('country') ? localStorage.getItem('country') : 'NL'
        if (loading) {
          return (
            <Row>
              <Col className="d-flex justify-content-center mt-3" lg={{ size: 6, offset: 3 }}>
                <Spinner type="grow" color="primary" />
              </Col>
            </Row>
          );
        }
        if (data && data.websites) {
          currentWebsiteId = data.websites.find(website => website.country_code === lsCountry).id
          return (
            <>
              {data && data.websites && (
                <Mutation mutation={CREATE_DEAL} onCompleted={redirect}>
                  {(createDeal, { loading, error }) => (
                    <CreateDeal createDeal={createDeal} loading={loading} error={error} />
                  )}
                </Mutation>
              )}
            </>
          );
        }
      }}
    </Query>
  );
};

const CreateDeal = ({ createDeal, loading, error }) => {
  let today = moment();
  let threeWeeks = moment().add(2, 'weeks');

  useEffect(() => {
    createDeal({ variables: { publish_date: today.toISOString(), expire_date: threeWeeks.toISOString(), website: currentWebsiteId } });
  }, [createDeal]);

  if (loading) {
    return (
      <Row>
        <Col className="d-flex justify-content-center mt-3" lg={{ size: 6, offset: 3 }}>
          <Spinner type="grow" color="primary" />
        </Col>
      </Row>
    );
  }
  if (error) return <p>error..</p>;
  return <p>...</p>;
};

export default DealNew;
