import gql from 'graphql-tag';

export const FILTER_DESTINATIONS = gql`
  query destinations($where: JSON, $limit: Int, $start: Int) {
    destinations(limit: $limit, start: $start, where: $where) {
      id
      name
      description
      type
      media {
        url
        thumb
      }
    }
    destinationsConnection(limit: -1, where: $where) {
      aggregate {
        count
      }
    }
  }
`;
