import React, { useState, memo } from 'react';
import { Row, Col } from 'react-grid-system';
import { Field, Form, Formik } from 'formik';
import AsyncSelect from 'react-select/async';
import { Mutation } from 'react-apollo';
import { UPDATE_DEAL } from 'queries/deals';
import Select from 'react-select';
import { createEventTarget } from 'utils/helpers';

import DetailIcon from 'assets/icons/detail-icon.svg';
import debounce from 'debounce-promise';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { formatForUpdate, formatDepatureAirports, getAsyncAirports, getAsync } from './functions';
import { formatBoardTypes } from 'utils/helpers';
import { formatProviders } from 'utils/helpers';
import PropTypes from 'prop-types';
import validation from './validation';
import automatic_validation from './automatic_validation';

import { withRouter } from 'react-router-dom';
import { FormFeedback, FormGroup, Button, Input, Label } from 'reactstrap';
import Card from 'components/Card';
import BookingExamples from 'containers/Deal/BookingExamples';
import { InputField } from './FormComponents';

const DealDetails = memo(
  ({ boardTypes, dealtypes, airports, initialData, isValid, validValues, allProviders }) => {
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    function toggleAccommodationModal() {
      var win = window.open(`/content/accommodations/new`, '_blank');
      win.focus();
    }

    function toggleDestinationModal() {
      // later kunnen we dit wellicht in de modal doen

      var win = window.open(`/content/destinations/new`, '_blank');
      win.focus();
    }

    const loadDestinations = inputValue => getAsync(inputValue, 'destinations', 'name_contains');

    const debouncedDestinations = debounce(loadDestinations, 350, {
      leading: true,
    });

    const loadAccommodations = inputValue =>
      getAsync(inputValue, 'accommodations', 'name_contains');

    const debouncedAccommodations = debounce(loadAccommodations, 350, {
      leading: true,
    });

    const loadAirports = inputValue => getAsyncAirports(inputValue);

    const debouncedAirports = debounce(loadAirports, 350, {
      leading: true,
    });

    function handleBookingsExamplesChange(values, bookingExamples, handleChange) {
      const newBookingExamples = [...values.booking_examples, ...bookingExamples];
      handleChange(createEventTarget('booking_examples', newBookingExamples));
    }

    function handleBookingExampleUpdate(bookingExamples, handleChange) {
      handleChange(createEventTarget('booking_examples', bookingExamples));
    }

    function handleDealTypeChange(value, handleChange) {
      const newValue = value ? value : [];
      handleChange(createEventTarget('dealtypes', newValue));
    }

    function handleDepartureAirportChange(value, handleChange) {
      const newValue = value ? value : null;
      handleChange(createEventTarget('departure_airport', newValue));
    }

    function handleAirportChange(value, handleChange) {
      const newValue = value ? value : null;
      handleChange(createEventTarget('arrival_airport', newValue));
    }

    function handleDestinationChange(value, handleChange) {
      if (value) {
        handleChange(createEventTarget('destination', value));
        validate();
      } else {
        handleChange(createEventTarget('destination', null));
        validate();
      }
    }

    function handleAccommodationChange(value, handleChange) {
      if (value) {
        handleChange(createEventTarget('accommodation', value));
        handleChange(createEventTarget('selected_media', []));
      }
    }

    function handleBoardTypeChange(value, handleChange) {
      handleChange(createEventTarget('board_type', value));
    }

    function handleProviderChange(value, handleChange) {
      const newValue = value ? value : [];
      handleChange(createEventTarget('providers', newValue));
    }

    function handleCompletion() {
      setSuccessMessage('Details is succesvol opgeslagen');
      setTimeout(() => setSuccessMessage(null), 3000);
    }

    function handleError() {
      setErrorMessage('Er is iets mis gegaan bij het updaten');
    }

    const validate = values => {
      try {
        if (!initialData.automatic) {
          validation.validateSync(values, { abortEarly: false });
        } else {
          automatic_validation.validateSync(values, { abortEarly: false });
        }
        isValid(true);
        validValues(formatForUpdate(values));
        return true;
      } catch (error) {
        isValid(false);
        return false;
      }
    };

    return (
      <Mutation mutation={UPDATE_DEAL} onCompleted={handleCompletion} onError={handleError}>
        {updateDeal => (
          <Formik
            initialValues={initialData}
            onSubmit={(values, { setValues }) => {
              updateDeal({ variables: formatForUpdate(values) });
            }}
            validationSchema={!initialData.automatic ? validation : automatic_validation}
            validate={errors => validate(errors)}
          >
            {props => {
              const { values, errors, handleChange, handleBlur, handleSubmit, touched } = props;
              return (
                <Card
                  disabled={Object.keys(errors).length > 0}
                  hideSaveButton={Object.keys(errors).length > 0}
                  title="Details"
                  subTitle={'Pakketreis: vlucht transfer & accommodatie'}
                  thumbnailSrc={DetailIcon}
                  errorMessage={errorMessage}
                  successMessage={successMessage}
                  uniqueId="details"
                  onSave={() => handleSubmit()}
                >
                  <Form onSubmit={handleSubmit}>
                    {!initialData.automatic && (
                      <>
                        <Row>
                          <Col lg={3}>
                            <InputField
                              value={values.price}
                              error={errors.price}
                              touched={touched.price}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              name="price"
                              type="number"
                              label="Prijs"
                              placeholder="Vul een prijs in"
                            />
                          </Col>
                          <Col lg={3}>
                            <InputField
                              value={values.days}
                              error={errors.days}
                              touched={touched.days}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              name="days"
                              type="text"
                              label="Dagen"
                              placeholder="Vul aantal dagen in"
                            />
                          </Col>
                          <Col lg={3}>
                            <InputField
                              value={values.nights}
                              error={errors.nights}
                              touched={touched.nights}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              name="nights"
                              type="number"
                              label="Nachten"
                              placeholder="Vul aantal nachten in"
                            />
                          </Col>
                          <Col lg={3}>
                            <InputField
                              value={values.persons}
                              error={errors.persons}
                              touched={touched.persons}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              name="persons"
                              type="number"
                              label="Personen"
                              placeholder="Vul aantal personen in"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={3}>
                            <FormGroup>
                              <Label for="board_type" size="sm">
                                Verblijfstype
                              </Label>
                              <Select
                                value={values.board_type}
                                onBlur={handleBlur}
                                isClearable={true}
                                backspaceRemoves={true}
                                deleteRemoves={true}
                                placeholder="selecteer..."
                                options={formatBoardTypes(boardTypes)}
                                onChange={value => handleBoardTypeChange(value, handleChange)}
                                name="boardType"
                                id="boardType"
                              />
                              {errors.board_types && (
                                <p
                                  style={{ marginTop: '.25rem', fontSize: '80%', color: '#dc3545' }}
                                >
                                  {errors.board_type}
                                </p>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg={3}>
                            <FormGroup>
                              <Label for="date" size="sm">
                                Datum
                              </Label>
                              <Input
                                tag={Field}
                                type="date"
                                id="date"
                                name="date"
                                invalid={Boolean(errors.date && touched.date)}
                                autoComplete="off"
                                placeholder="Selecteer een datum"
                              />
                              {errors.date && touched.date && (
                                <FormFeedback>{errors.date}</FormFeedback>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg={3}>
                            <InputField
                              value={values.alternative_date}
                              error={errors.alternative_date}
                              touched={touched.alternative_date}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              name="alternative_date"
                              type="text"
                              label="Alternatieve datum"
                              placeholder="Bijvoorbeeld zomervakantie"
                            />
                          </Col>
                          <Col lg={3}>
                            <FormGroup>
                              <Label for="aanbieder" size="sm">
                                Aanbieder
                              </Label>

                              <Select
                                value={values.providers}
                                onBlur={handleBlur}
                                isClearable={true}
                                backspaceRemoves={true}
                                deleteRemoves={true}
                                placeholder="selecteer..."
                                options={formatProviders(allProviders)}
                                onChange={value => handleProviderChange(value, handleChange)}
                                name="aanbieder"
                                id="aanbieder"
                              />
                              {errors.board_types && (
                                <p
                                  style={{ marginTop: '.25rem', fontSize: '80%', color: '#dc3545' }}
                                >
                                  {errors.board_type}
                                </p>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={4}>
                            <FormGroup>
                              <Label for="departure_ariport" size="sm">
                                Vertrek luchthaven
                              </Label>
                              <Select
                                value={values.departure_airport}
                                isClearable={true}
                                backspaceRemoves={true}
                                deleteRemoves={true}
                                onBlur={handleBlur}
                                onChange={value =>
                                  handleDepartureAirportChange(value, handleChange)
                                }
                                options={formatDepatureAirports(airports)}
                                name="departure_airport"
                                isSearchable={false}
                                placeholder="Selecteer een vertrek luchthaven"
                                noOptionsMessage={() => null}
                              />
                              {errors.departure_airport && (
                                <p
                                  style={{ marginTop: '.25rem', fontSize: '80%', color: '#dc3545' }}
                                >
                                  {errors.departure_airport}
                                </p>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                      </>
                    )}
                    <Row>
                      <Col lg={4}>
                        <FormGroup>
                          <Label for="arrival_airport" size="sm">
                            Aankomst luchthaven
                              </Label>
                          <AsyncSelect
                            cacheOptions
                            name="arrival_airport"
                            isClearable={true}
                            backspaceRemoves={true}
                            deleteRemoves={true}
                            loadOptions={debouncedAirports}
                            onChange={value => handleAirportChange(value, handleChange)}
                            onSelectResetsInput={false}
                            defaultOptions
                            value={values.arrival_airport}
                            noOptionsMessage={() => null}
                          />
                          {errors.arrival_airport && (
                            <p
                              style={{ marginTop: '.25rem', fontSize: '80%', color: '#dc3545' }}
                            >
                              {errors.arrival_airport}
                            </p>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={9}>
                        <InputField
                          value={values.notes}
                          error={errors.notes}
                          touched={touched.notes}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          name="notes"
                          type="text"
                          label="Opmerking"
                          placeholder="Vul opmerking in"
                        />
                      </Col>
                    </Row>
                    <hr />

                    {!initialData.automatic && (
                      <>
                        <Row>
                          <Col>
                            <BookingExamples
                              onBookingExampleUpdate={handleBookingExampleUpdate}
                              values={values}
                              handleChange={handleChange}
                              handleBookingExamplesChange={bookingExamples =>
                                handleBookingsExamplesChange(values, bookingExamples, handleChange)
                              }
                              dealId={values.id}
                              bookingExamples={values.booking_examples}
                            />
                          </Col>
                        </Row>
                        <hr />
                      </>
                    )}

                    <FormGroup>
                      <Label size="sm">Type Reis</Label>
                      <Select
                        closeMenuOnSelect={false}
                        defaultValue
                        isMulti
                        options={dealtypes}
                        onChange={value => handleDealTypeChange(value, handleChange)}
                        value={values.dealtypes}
                        noOptionsMessage={() => null}
                      />
                      {errors.dealtypes && (
                        <p style={{ marginTop: '.25rem', fontSize: '80%', color: '#dc3545' }}>
                          Selecteer minimaal 1 dealtype
                        </p>
                      )}
                    </FormGroup>

                    {!initialData.automatic && (
                      <>
                        <Label size="sm">Inclusief</Label>
                        {[
                          [
                            { label: 'Vlucht', name: 'including_flight' },
                            { label: 'Accommodatie', name: 'including_accommodation' },
                          ],
                          [
                            { label: 'Transfer', name: 'including_transfer' },
                            { label: 'Huurauto', name: 'including_rentalcar' },
                          ],
                          [{ label: 'Treinreis', name: 'including_train_travel' }],
                        ].map(checkboxes => (
                          <Row>
                            {checkboxes.map(checkbox => (
                              <Col xs={4} sm={2} lg={2}>
                                <FormGroup check>
                                  <Label check>
                                    <Input
                                      tag={Field}
                                      type="checkbox"
                                      name={checkbox.name}
                                      checked={values[checkbox.name]}
                                      invalid={Boolean(errors[checkbox.name])}
                                    />{' '}
                                    {checkbox.label}
                                  </Label>
                                </FormGroup>
                              </Col>
                            ))}
                          </Row>
                        ))}
                        <br />
                      </>
                    )}
                    <Row>
                      <Col xs={6}>
                        <FormGroup>
                          <Label for="destination" size="sm">
                            Bestemming
                          </Label>
                          <AsyncSelect
                            name="destination"
                            loadOptions={debouncedDestinations}
                            onChange={value => handleDestinationChange(value, handleChange)}
                            defaultOptions
                            value={values.destination}
                            noOptionsMessage={() => null}
                            isClearable={() => handleDestinationChange(null, handleChange)}
                          />
                          {errors.destination && (
                            <p style={{ marginTop: '.25rem', fontSize: '80%', color: '#dc3545' }}>
                              {errors.destination}
                            </p>
                          )}
                        </FormGroup>
                      </Col>
                      <Col xs={6} md={2}>
                        <Button
                          outline
                          color="secondary"
                          className="d-flex align-items-center"
                          type="button"
                          onClick={toggleDestinationModal}
                          style={{ marginTop: '38px' }}
                        >
                          <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                          Toevoegen
                        </Button>
                      </Col>
                        <Col md={4} style={{display: 'flex', alignItems: 'center'}} >
                            {values.destination && (
                                <a style={{marginTop: '15px'}} href={`/content/destinations/${values.destination.value}`} target="_blank">Bekijk bestemming</a>
                            )}
                        </Col>
                    </Row>

                    <Row>
                      <Col xs={6}>
                        <FormGroup>
                          <Label for="accommodation" size="sm">
                            Accommodatie
                          </Label>
                          <AsyncSelect
                            name="accommodation"
                            loadOptions={debouncedAccommodations}
                            onChange={value => handleAccommodationChange(value, handleChange)}
                            defaultOptions
                            value={values.accommodation}
                            noOptionsMessage={() => null}
                          />
                          {errors.accommodation && (
                            <p style={{ marginTop: '.25rem', fontSize: '80%', color: '#dc3545' }}>
                              {errors.accommodation}
                            </p>
                          )}
                        </FormGroup>
                      </Col>
                      <Col xs={6} md={2}>
                        <Button
                          outline
                          color="secondary"
                          className="d-flex align-items-center"
                          type="button"
                          onClick={toggleAccommodationModal}
                          style={{ marginTop: '38px' }}
                        >
                          <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                          Toevoegen
                        </Button>
                        {/*<Modal isOpen={accommodationModalOpen} toggle={toggleAccommodationModal}>*/}
                        {/*  <ModalHeader toggle={toggleAccommodationModal}>*/}
                        {/*    Accommodatie toevoegen*/}
                        {/*  </ModalHeader>*/}
                        {/*  <ModalBody>*/}
                        {/*    <Route render={(props) => <AccommodationUpdate {...props} usedInModal={true} />}/>*/}
                        {/*  </ModalBody>*/}
                        {/*  <ModalFooter>*/}
                        {/*    <Button color="primary" onClick={toggleAccommodationModal}>*/}
                        {/*      Opslaan*/}
                        {/*    </Button>{' '}*/}
                        {/*    <Button color="secondary" onClick={toggleAccommodationModal}>*/}
                        {/*      Annuleren*/}
                        {/*    </Button>*/}
                        {/*  </ModalFooter>*/}
                        {/*</Modal>*/}
                      </Col>
                        <Col md={4} style={{display: 'flex', alignItems: 'center'}}>
                            {values.accommodation && (
                                <a style={{marginTop: '15px'}} href={`/content/accommodations/${values.accommodation.value}`} target="_blank">Bekijk accommodatie</a>
                            )}
                        </Col>
                    </Row>
                  </Form>
                </Card>
              );
            }}
          </Formik>
        )}
      </Mutation>
    );
  },
  areEqual,
);

function areEqual(prevProps, nextProps) {
  return JSON.stringify(prevProps.initialData) === JSON.stringify(nextProps.initialData);
}

DealDetails.propTypes = {
  boardTypes: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]).isRequired,
  dealtypes: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]).isRequired,
  airports: PropTypes.array.isRequired,
  initialData: PropTypes.object.isRequired,
};

export default withRouter(DealDetails);
