import React, { useState } from 'react';
import { Query } from 'react-apollo';
import { FILTER_ACCOMMODATIONS } from './apollo';
import { Row, Col } from 'reactstrap';
import Table from 'components/Table';
import Search from 'components/Search';

const defaultSearchQuery = {
  searchText: '',
  automatic: null,
  //pagination
  start: 0,
  limit: 50,
};

function formatSearchQuery(searchQuery) {
  return {
    where: {
      name_contains: searchQuery.searchText,
    },
  };
}

const Accommodations = () => {
  const [searchQuery, setSearchQuery] = useState(defaultSearchQuery);
  const [pageNumber, setPageNumber] = useState(1);
  let accommodationCount = 0;

  function handleNewSearchTextValue(value) {
    let newSearchQuery = { ...searchQuery };
    newSearchQuery.searchText = value;
    newSearchQuery.start = 0;
    setPageNumber(1);
    setSearchQuery(newSearchQuery);
  }

  function hanlePaginationChange(type) {
    let newSearchQuery = { ...searchQuery };
    if (type === 'next') {
      setPageNumber(pageNumber + 1);
      newSearchQuery.start = newSearchQuery.start + newSearchQuery.limit;
    } else {
      setPageNumber(pageNumber - 1);
      newSearchQuery.start = newSearchQuery.start - newSearchQuery.limit;
    }
    setSearchQuery(newSearchQuery);
  }

  return (
    <Row className="d-flex justify-content-center">
      <Col lg={8} className="mb-5">
        <Search
          handleNewSearchTextValue={handleNewSearchTextValue}
          isUsedInDeals={false}
          placeholder="Zoeken op accommodatienaam"
        />
      </Col>

      <Col lg={12}>
        <Query
          fetchPolicy="no-cache"
          query={FILTER_ACCOMMODATIONS}
          variables={formatSearchQuery(searchQuery)}
        >
          {({ loading, error, data }) => {
            if (error) return `Error! ${error.message}`;
            if (data) {
              if (data.accommodationsConnection) {
                accommodationCount = data.accommodationsConnection.aggregate.count;
              }
              return (
                  <Table
                      title="Accommodations"
                      loading={loading}
                      data={data.accommodations}
                      url="/content/accommodations"
                      paginationOnClickPrevious={() => hanlePaginationChange('previous')}
                      paginationOnClickNext={() => hanlePaginationChange('next')}
                      start={searchQuery.start}
                      limit={searchQuery.limit}
                      pageNumber={pageNumber}
                      total={accommodationCount}
                      disablePrevious={Boolean(searchQuery.start === 0)}
                      disableNext={Boolean(
                          searchQuery.start > accommodationCount - searchQuery.limit ||
                          searchQuery.limit >= accommodationCount,
                      )}
                      pagination={true}
                  />
              );
            }
            return <p>laden...</p>
          }}
        </Query>
      </Col>
    </Row>
  );
};

export default Accommodations;
