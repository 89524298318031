import React, { useState } from 'react';
import { NavLink, Route } from 'react-router-dom';
import Destinations from 'pages/Content/Destinations/DestinationsOverview';
import { Row, Col, ListGroup, ListGroupItem } from 'reactstrap';
import Accommodations from './Accommodations/AccommodationsOverview';
import DestinationUpdate from './Destinations/DestinationsUpdate';
import AccommodationUpdate from './Accommodations/AccommodationsUpdate';
import DestinationsFilters from './Destinations/DestinationsOverview/DestinationsFilters';

const Content = ({ match }) => {
  const [trigger, setTrigger] = useState(false)
  function randomGen() {
    return Math.random(0, 1)
  }
  const [filterQuery, setFilterQuery] = useState({
    where: {
      search_contains: '',
      type_in: ['region', 'country', 'city', 'continent']
    }
  });

  function handleFilterQueryChange(query) {
    let newQuery = {...filterQuery}
    newQuery.where = query;
    setFilterQuery(newQuery);
    setTrigger(!trigger);
  }



  return (
    <Row>
      <Col
        lg={2}
        className="px-0"
        style={{
          borderRight: '1px solid rgba(0,0,0,0.24)',
          backgroundColor: 'white',
          minHeight: '100%',
        }}
      >
        <Row>
          <Col lg={12} className="pt-5">
            <div className="px-5">
              <h4 className="mb-4">Filter content</h4>
              <h6>TYPE CONTENT</h6>
            </div>
            <ListGroup className="own-list-group mb-0">
              <NavLink to="/content/destinations" activeClassName="active-list-group-item">
                <ListGroupItem>
                  <span>Bestemmingen</span>
                </ListGroupItem>
              </NavLink>
              <NavLink to="/content/accommodations" activeClassName="active-list-group-item">
                <ListGroupItem className="">
                  <span>Accommodaties</span>
                </ListGroupItem>
              </NavLink>
              <NavLink to="" activeClassName="active-list-group-item">
                <ListGroupItem className="">
                  <span>Dealoverzichten</span>
                </ListGroupItem>
              </NavLink>
            </ListGroup>
            <hr />
          </Col>
          <Route
            path={`${match.url}/destinations`}
            render={props => (
              <DestinationsFilters
                {...props}
                filterQuery={filterQuery}
                handleFilterQueryChange={handleFilterQueryChange}
              />
            )}
          />
          <hr />
        </Row>
      </Col>
      <Col style={{ backgroundColor: '#FBFBFD', minHeight: '100vh' }} className="p-5">
        <Row className="d-flex justify-content-center">
          <Col lg={8}>
            {/*<Row className="d-flex justify-content-center mb-5">*/}
            {/*  <Col lg={10}>*/}
            {/*    <Search handleNewSearchTextValue={handleNewSearchTextValue} match={match} />*/}
            {/*  </Col>*/}
            {/*</Row>*/}
            <Route
              exact
              path={`${match.url}/destinations`}
              render={props => <Destinations {...props} filterQuery={filterQuery} trigger={trigger}></Destinations>}
            />
            <Route
              exact
              path={`${match.url}/accommodations`}
              render={props => (
                <Accommodations {...props} filterQuery={filterQuery}></Accommodations>
              )}
            />
            <Route exact path={`${match.url}/destinations/:id`} component={DestinationUpdate} key={randomGen()} />
            <Route exact path={`${match.url}/accommodations/:id`} component={AccommodationUpdate} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Content;
