import React from 'react';
import { Img, Wrapper, Title, TitleWrapper, Icon } from './DealImgCSS';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DealImg = ({
  src,
  onImageClicked,
  handleOnClickDelete,
  title = null,
  url = null,
  isBookingExample = false,
}) => {
  function shortTitle(title) {
    const maxLength = 20;
    if (!title) return 'Er is geen titel ingesteld';
    const fullTitle = Array.from(title);
    const shortTitle = fullTitle.splice(0, maxLength).join('');
    return `${shortTitle} ${title.length < maxLength ? '' : '...'}`;
  }

  return (
    <Wrapper>
      <Img imgsrc={src} onClick={onImageClicked} />
      <Icon position="left" onClick={handleOnClickDelete} type="button">
        <FontAwesomeIcon icon="minus" />
      </Icon>
      {url && (
        <Icon as="a" href={url} target="_blank" position="right" type="link">
          <FontAwesomeIcon icon="link" />
        </Icon>
      )}
      {isBookingExample && (
        <TitleWrapper>
          <Title>
            <span className="text-center">{shortTitle(title)}</span>
          </Title>
        </TitleWrapper>
      )}
    </Wrapper>
  );
};

export default DealImg;
