import gql from 'graphql-tag';

export const FILTER_ACCOMMODATIONS = gql`
  query accommodations($where: JSON, $limit: Int, $start: Int) {
    accommodations(limit: $limit, start: $start, where: $where) {
      id
      name
      description
      media {
        url
        thumb
      }
    }
    accommodationsConnection(where: $where, limit: -1) {
      aggregate {
        count
      }
    }
  }
`;
