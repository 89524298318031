export function formatPriceForUpdate(dealId, values) {

  const expire_date = values.expire_date ? new Date(values.expire_date).toISOString() : null;
  const date_from = values.date_from ? new Date(values.date_from).toISOString() : null;
  const date_to = values.date_to ? new Date(values.date_to).toISOString() : null;
  const variables = {
    variables: {
      id: values.id ? values.id : null,
      expire_date,
      date_from,
      date_to,
      departureairport: values.departureairport ? values.departureairport.value : null,
      night_count: values.night_count ? Number(values.night_count) : null,
      day_count: values.day_count ? Number(values.day_count) : null,
      persons: values.persons ? Number(values.persons) : null,
      provider: values.provider ? values.provider.value : null,
      price: values.price ? Number(values.price) : null,
      deals: values.deals,
      manual_price: true,
      highlight: values.highlight,
      concept: values.concept ? values.concept.id : dealId,
      deeplink: values.deeplink ? values.deeplink : null,
      board_type: values?.board_type
    },
  };

  if (values.board_type) {
    variables.variables['board_type'] = values.board_type.value;
  }

  if (values.transport_type) {
    variables.variables['transport_type'] = values.transport_type.value;
  }

  return variables;
}

export const transportTypesEnums = [
  { label: 'Vliegtuig', value: 'flight' },
  { label: 'Bus', value: 'bus' },
  { label: 'Eigen vervoer', value: 'owntransport' },
  { label: 'Trein', value: 'train' },
];

export const boardTypesEnums = [
  { label: 'Logies', value: '5d08e1bfff6c4f13f6db1e68' },
  { label: 'Half pension', value: '5d08e1d1ff6c4f13f6db1e6a' },
  { label: 'Logies en Onbijt', value: '5d08e1c7ff6c4f13f6db1e69' },
  { label: 'Vol pension', value: '5d08e1d4ff6c4f13f6db1e6b' },
  { label: 'All inclusive', value: '5d08e1dfff6c4f13f6db1e6c' },
  { label: 'Ultra inclusive', value: '5d08e1e4ff6c4f13f6db1e6d' },
];

export function formatInitalProviders(providers) {
  return providers.map(provider => ({ label: provider.name, name: provider.name, value: provider.id }));
}
