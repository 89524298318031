import gql from "graphql-tag";

export const UPDATE_RSS_COLUMNS = gql`
    mutation rssColumns($id: ID!, $column_deals: [editComponentRssColumnDealInput]) {
        updateRsscolumn(input: {where: { id: $id }, data: { column_deals: $column_deals}}) {
            rsscolumn{
                id
                type
                website{
                    domain
                }
                column_deals {
                    id
                    deal {
                        id
                    }
                    title
                    description
                    url
                    direct_link
                    alt
                    image{
                        id
                        url
                    }
                }
            }
        }
    }
`;
