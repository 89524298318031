import React, { useState } from 'react';
import Card from 'components/Card';
import ImagesIcon from 'assets/icons/images-icon.svg';
import { Button, Col, Row } from 'reactstrap';
import { Mutation } from 'react-apollo';
import { UPDATE_ACCOMMODATION } from 'queries/deals';
import DealImg from 'components/DealImg';
import { createEventTarget } from 'utils/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import UploadModal from '../../../../components/UploadModal';
import EditImageModal from './EditImageModal';
import { formatForUpdate } from './helpers.js';

const AccommodationImages = ({ handleChange, values, errors, history }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [toBeEdditedImageIndex, setToBeEdditedImageIndex] = useState(0);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  function toggleModal() {
    setIsModalVisible(!isModalVisible);
  }

  async function toggleUpdateModal(index = 0) {
    await setToBeEdditedImageIndex(index);
    setIsUpdateModalVisible(!isUpdateModalVisible);
  }

  function handleOnClickDelete(index) {
    let newMedia = [...values.media];
    newMedia.splice(index, 1);
    handleChange(createEventTarget('media', newMedia));
  }

  function handleCompletion() {
    setSuccessMessage("Accommodatiefoto's zijn succesvol opgeslagen");
    setTimeout(() => setSuccessMessage(null), 3000);
  }

  function handleError() {
    setErrorMessage('Oeps, er ging iets mis');
  }

  return (
    <Mutation mutation={UPDATE_ACCOMMODATION} onCompleted={handleCompletion} onError={handleError}>
      {updateDeal => (
        <Card
          title="Fotos"
          subTitle={`${values.media ? values.media.length : '0'} fotos`}
          thumbnailSrc={ImagesIcon}
          onSave={() => updateDeal(formatForUpdate(values))}
          errors={errors}
          errorMessage={errorMessage}
          successMessage={successMessage}
          uniqueId="fotos"
          hideSaveButton={Boolean(!values.id)}
          disabled={false}
        >
          <Row className="d-flex justify-content-end mb-3">
            <Col lg={4} className="d-flex justify-content-end">
              <Button
                outline
                color="secondary"
                className="d-flex align-items-center"
                type="button"
                onClick={toggleModal}
              >
                <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                Accommodatiefoto’s uploaden
              </Button>
            </Col>
          </Row>
          <Row>
            {values.media ? (
              values.media.map((image, index) => (
                <Col key={index} lg={3} className="mb-4">
                  <DealImg
                    src={image.thumb ? image.thumb : ''}
                    handleOnClickDelete={() => handleOnClickDelete(index)}
                    url={image.url ? image.url : null}
                  />
                </Col>
              ))
            ) : (
              <p>Upload eerst een afbeelding</p>
            )}
          </Row>
          <UploadModal
            toggleModal={toggleModal}
            isModalVisible={isModalVisible}
            values={values}
            handleChange={handleChange}
            type="accommodation"
          />
          <EditImageModal
            toggleModal={toggleUpdateModal}
            isModalVisible={isUpdateModalVisible}
            values={values}
            handleChange={handleChange}
            imageIndex={toBeEdditedImageIndex}
          />
        </Card>
      )}
    </Mutation>
  );
};

export default AccommodationImages;
