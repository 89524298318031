import React, { useState, useEffect } from 'react';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Popover,
  PopoverBody,
  Row,
} from 'reactstrap';
import Select from 'react-select';
import { createEventTarget } from 'utils/helpers';
import { Field } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import useFormValidation from 'hooks/useFormValidation';
import validateManuallyPrice from 'validations/manuallyPriceValidation';
import moment from 'moment';
import { formatDepatureAirports } from 'containers/Deal/DealDetails/functions';
import { CREATE_PRICE, UPDATE_PRICE } from 'queries/deals';
import { GET_PRICES } from '../../Deal/DealPrices/apollo';
import { Mutation } from 'react-apollo';
import { formatPriceForUpdate, formatInitalProviders, boardTypesEnums } from './helpers';
import axios from 'axios';

const initialPrice = {
  id: null,
  board_type: '',
  date_from: '',
  date_to: '',
  departureairport: '',
  night_count: '',
  day_count: '',
  transport_type: '',
  persons: '',
  provider: '',
  price: '',
  expire_date: '',
  deeplink: null,
  manual_price: true,
  highlight: false,
};

const ManuallyPricesModal = ({
  dealId,
  price,
  providers,
  airports,
  onClose,
  slug = '',
  addManuallyPrice,
  publishedDealId,
}) => {
  const [qennerUrl, setQennerUrl] = useState('');
  const [bitlyUrl, setBitlyUrl] = useState('');
  const [copySuccess, setCopySuccess] = useState(false);
  const [copyBitlySuccess, setBitlyCopySuccess] = useState(false);

  useEffect(() => {
    if (price) {
      setValues({ ...price, board_type: price.board_type });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [price]);

  function copyUrl(e) {
    const textArea = document.createElement('textarea');

    document.body.appendChild(textArea);
    textArea.value = qennerUrl;
    textArea.select();

    try {
      setCopySuccess(true);
      document.execCommand('copy');
      document.body.removeChild(textArea);
      setTimeout(() => {
        setCopySuccess(false);
      }, 2000);
    } catch (err) {}
    e.target.focus();
  }

  function copyBitlyUrl(e) {
    const textArea = document.createElement('textarea');

    document.body.appendChild(textArea);
    textArea.value = bitlyUrl;
    textArea.select();

    try {
      setBitlyCopySuccess(true);
      document.execCommand('copy');
      document.body.removeChild(textArea);
      setTimeout(() => {
        setBitlyCopySuccess(false);
      }, 2000);
    } catch (err) {}
    e.target.focus();
  }

  function handleDepartureAirportChange(value) {
    const newValue = value ? value : null;
    handleChange(createEventTarget('departureairport', newValue));
  }

  const { setValues, handleChange, values } = useFormValidation(
    initialPrice,
    validateManuallyPrice,
  );

  function onSubmit(createPrice) {
    createPrice(formatPriceForUpdate(dealId, values));
  }

  function onUpdatePrice(updatePrice) {
    updatePrice(formatPriceForUpdate(dealId, values));
    onClose(true);
  }

  function handleBoardTypeChange(event) {
    const newEvent = {
      target: {
        name: 'board_type',
        value: event,
      },
    };
    handleChange(newEvent);
  }

  function handleProviderChange(event) {
    const newEvent = {
      target: {
        name: 'provider',
        value: event,
      },
    };
    handleChange(newEvent);
  }

  function cancelSubmit() {
    setQennerUrl('');
    onClose(false);
  }

  function generateUrl() {
    const unformattedParams = {
      departureairport: values.departureairport ? values.departureairport.iata : '',
      date_from: values.date_from ? moment(values.date_from).format('YYYY-MM-DD') : '',
      days_count: values.day_count,
      persons: values.persons,
      board_type: values.board_type ? values.board_type.value : '',
    };

    const entries = Object.entries(unformattedParams);
    const params = entries.filter(item => item[1]);

    const generatedParams = params.map(item => {
      return `${item[0]}=${item[1]}`;
    });

    const finishedUrl = generatedParams.join('&');
    setQennerUrl(`https://weflycheap.nl/${slug}/?${finishedUrl}`);
  }

  function handleAddPriceCompletion(event) {
    // addManuallyPrice(event.createPrice.price);
    onClose(true);
  }

  async function generateBitlyUrl() {
    try {
      const generatedUrl = await generateBitlyLink(qennerUrl);
      setBitlyUrl(generatedUrl);
    } catch (e) {
      alert('er gaat iets mis bij bitly');
    }
  }

  async function generateBitlyLink(longUrl) {
    const promises = generatePromiseForBitly(longUrl);
    const results = await Promise.resolve(promises);
    return results.data.link;
  }

  function generatePromiseForBitly(longUrl) {
    let formData = {};
    formData.long_url = longUrl;
    return axios({
      url: 'https://api-ssl.bitly.com/v4/bitlinks',
      method: 'POST',
      headers: {
        authorization: 'Bearer 6c682be4489633f00e1a9ecb7c6d28ed6b48e707',
        'Content-Type': 'application/json',
      },
      data: formData,
    });
  }

  return (
    <Mutation
      mutation={CREATE_PRICE}
      onCompleted={handleAddPriceCompletion}
      refetchQueries={() => {
        return [
          {
            query: GET_PRICES,
            variables: { dealId: publishedDealId, manual_price: true },
          },
          // {
          //   query: GET_PRICES,
          //   variables: { dealId: values.deals[0], highlight: true },
          // }
        ];
      }}
    >
      {(createPrice, { loading, error, data }) => (
        <Modal isOpen={Boolean(price)} toggle={onClose} size="xl">
          <ModalHeader toggle={onClose}>Handmatige prijs invoeren</ModalHeader>
          <ModalBody>
            <Row>
              <Col md={6} lg={4}>
                <FormGroup>
                  <Label for="discount" size="sm">
                    Verblijfstype
                  </Label>
                  <Select
                    options={boardTypesEnums}
                    onChange={handleBoardTypeChange}
                    value={values.board_type}
                    name="board_type"
                    id="board_type"
                  />
                </FormGroup>
              </Col>
              <Col md={6} lg={4}>
                <FormGroup>
                  <Label for="date" size="sm">
                    Vertrek Datum
                  </Label>
                  <Input
                    type="date"
                    id="date_from"
                    name="date_from"
                    autoComplete="off"
                    placeholder="Selecteer een datum"
                    value={values.date_from}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>

              <Col md={6} lg={4}>
                <FormGroup>
                  <Label for="date" size="sm">
                    Datum van terugkeer
                  </Label>
                  <Input
                    type="date"
                    id="date_to"
                    name="date_to"
                    autoComplete="off"
                    placeholder="Selecteer een datum"
                    value={values.date_to}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>

              <Col md={6} lg={4}>
                <FormGroup>
                  <Label for="discount" size="sm">
                    Luchthaven
                  </Label>
                  <Select
                    value={values.departureairport}
                    onChange={handleDepartureAirportChange}
                    options={formatDepatureAirports(airports)}
                    name="departureairport"
                    isSearchable={false}
                    placeholder="Selecteer een vertrek luchthaven"
                    noOptionsMessage={() => null}
                  />
                </FormGroup>
              </Col>

              <Col md={6} lg={4}>
                <FormGroup>
                  <Label for="date" size="sm">
                    Dagen
                  </Label>
                  <Input
                    type="number"
                    id="day_count"
                    name="day_count"
                    autoComplete="off"
                    placeholder="Vul aantal dagen in"
                    onChange={handleChange}
                    value={values.day_count}
                  />
                </FormGroup>
              </Col>

              <Col md={6} lg={4}>
                <FormGroup>
                  <Label for="date" size="sm">
                    Nachten
                  </Label>
                  <Input
                    type="number"
                    id="night_count"
                    name="night_count"
                    autoComplete="off"
                    placeholder="Vul aantal nachten in"
                    onChange={handleChange}
                    value={values.night_count}
                  />
                </FormGroup>
              </Col>

              <Col md={6} lg={4}>
                <FormGroup>
                  <Label for="date" size="sm">
                    Personen
                  </Label>
                  <Input
                    type="number"
                    id="persons"
                    name="persons"
                    autoComplete="off"
                    placeholder="Vul aantal personen in"
                    onChange={handleChange}
                    value={values.persons}
                  />
                </FormGroup>
              </Col>

              <Col md={6} lg={4}>
                <FormGroup>
                  <Label for="discount" size="sm">
                    Partner
                  </Label>
                  <Select
                    id="provider"
                    name="provider"
                    options={formatInitalProviders(providers)}
                    value={values.provider}
                    onChange={handleProviderChange}
                  />
                </FormGroup>
              </Col>

              <Col md={6} lg={4}>
                <FormGroup>
                  <Label for="date" size="sm">
                    Prijs
                  </Label>
                  <Input
                    type="number"
                    id="price"
                    name="price"
                    autoComplete="off"
                    placeholder="Vul een bedrag in"
                    onChange={handleChange}
                    value={values.price}
                  />
                </FormGroup>
              </Col>

              <Col md={6} lg={4}>
                <FormGroup>
                  <Label for="discount_expire" size="sm">
                    Einddatum (t/m)
                  </Label>
                  <Input
                    type="date"
                    id="expire_date"
                    name="expire_date"
                    onChange={handleChange}
                    value={values.expire_date}
                  />
                </FormGroup>
              </Col>

              <Col md={6} lg={4}>
                <FormGroup>
                  <Label for="date" size="sm">
                    Deeplink
                  </Label>
                  <Input
                    type="text"
                    id="deeplink"
                    name="deeplink"
                    autoComplete="off"
                    placeholder="Vul deeplink in"
                    onChange={handleChange}
                    value={values.deeplink}
                  />
                </FormGroup>
              </Col>
            </Row>

            <hr />
            <Row>
              <Col md={2}>
                <Button style={{ marginBottom: '10px' }} color="success"
                        onClick={generateUrl}
                        disabled
                >
                  Genereer URL
                </Button>
              </Col>
              <Col md={4} lg={4}>
                <FormGroup>
                  <Input
                    tag={Field}
                    type="text"
                    id="discount_expire"
                    name="discount_expire"
                    value={qennerUrl}
                  />
                </FormGroup>
              </Col>
              <Col md={4} lg={3}>
                <FormGroup>
                  <Popover placement="right" isOpen={copySuccess} target="copyUrl">
                    <PopoverBody>Gekopieerd!</PopoverBody>
                  </Popover>
                  <Button id="copyUrl" outline color="secondary" onClick={copyUrl}>
                    <FontAwesomeIcon icon="plus-circle" /> Kopieren
                  </Button>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={2}>
                <Button color="success" onClick={generateBitlyUrl} disabled={!qennerUrl}>
                  Bitly url genereren
                </Button>
              </Col>
              <Col md={4} lg={4}>
                <FormGroup>
                  <Input disabled={true} type="text" value={bitlyUrl} />
                </FormGroup>
              </Col>
              <Col md={4} lg={3}>
                <FormGroup>
                  <Popover placement="right" isOpen={copyBitlySuccess} target="copyBitlyUrl">
                    <PopoverBody>Gekopieerd!</PopoverBody>
                  </Popover>
                  <Button id="copyBitlyUrl" outline color="secondary" onClick={copyBitlyUrl}>
                    <FontAwesomeIcon icon="plus-circle" /> Kopieren
                  </Button>
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            {!values.id && (
              <Button color="primary" onClick={() => onSubmit(createPrice)}>
                Toevoegen
              </Button>
            )}


            {values.id && (
              <Mutation
                mutation={UPDATE_PRICE}
                onCompleted={handleAddPriceCompletion}
                refetchQueries={() => {
                  return [
                    {
                      query: GET_PRICES,
                      variables: { dealId: publishedDealId, manual_price: true },
                    },
                    // {
                    //     query: GET_PRICES,
                    //     variables: {dealId: values.deals[0], highlight: true},
                    // }
                  ];
                }}
              >
                {(updatePrice, { loading, error, data }) => (
                  <Button disabled color="primary" onClick={() => onUpdatePrice(updatePrice)}>
                    Wijzigingen opslaan
                  </Button>
                )}
              </Mutation>
            )}

            <Button color="secondary" onClick={cancelSubmit}>
              Annuleren
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </Mutation>
  );
};

ManuallyPricesModal.propTypes = {
  price: PropTypes.object,
  providers: PropTypes.array.isRequired,
  airports: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  addManuallyPrice: PropTypes.func.isRequired,
  slug: PropTypes.string,
};

export default ManuallyPricesModal;
