import gql from 'graphql-tag';

export const GET_DEAL = gql`
  query getDeal($id: ID!) {
    deal(id: $id) {
      id
      automatic
      title
      intro
      price
      days
      nights
      date
      persons
      slug
      show_on_homepage
      show_on_be
      automatic
      arrival_airport {
        id
        name
      }
      departure_airport {
        id
        name
      }
      dealtypes(sort: "name:asc") {
        id
        name
      }
      booking_examples {
        id
        title
        url
        image {
          id
          url
        }
      }
      board_type {
        id
        name
      }
      dealproviders {
        id
        provider {
          id
        }
      }
      notes
      including_flight
      including_transfer
      including_rentalcar
      including_accommodation
      including_train_travel
      accommodation {
        id
        name
      }
      selected_media
      destination {
        id
        name
        parent {
          name
        }
      }
      published_deal {
        id
      }
      affiliate_url
      publish_date
      expire_date
      alternative_date
      formula_price_4_lg
      formula_price_4_hp
      formula_price_4_ai
      formula_price_8_lg
      formula_price_8_hp
      formula_price_8_ai
      formula_price_15_lg
      formula_price_15_hp
      formula_price_15_ai
      discount
      discount_expire
      edited_by {
        email
        username
      }
      createdAt
      updatedAt
      hide_partners
    }
    providers {
      id
      name
    }
    dealtypes(sort: "name:asc") {
      id
      name
    }
    boardtypes {
      id
      name
    }
    airports(where: { departure_airport: true }, sort: "name:asc") {
      id
      name
      iata
    }
    prices {
      id
      date_from
      date_to
      persons
      night_count
      day_count
      departureairport {
        id
        name
        iata
      }
      arrival_airport {
        id
        name
        iata
      }
      board_type
      price
      provider {
        id
        name
      }
      manual_price
      expire_date
      transport_type
      highlight
    }
  }
`;

export const GET_DEALS = gql`
  query {
    deals(where: { is_published_deal_ne: true }) {
      id
      title
      intro
      price
      days
      date
      persons
      providers {
        id
      }
      departure_airport {
        id
      }
      arrival_airport {
        id
      }
      board_type {
        id
      }
      booking_examples {
        id
        title
        url
        image {
          id
          url
        }
      }
      notes
      including_flight
      including_transfer
      including_rentalcar
      including_accommodation
      including_train_travel
    }
  }
`;

export const UPDATE_DEAL = gql`
  mutation UpdateDeal(
    $id: ID!
    $title: String
    $subtitle: String
    $meta_title: String
    $meta_description: String
    $intro: String
    $price: Float
    $days: Int
    $nights: Int
    $date: DateTime
    $persons: Int
    $board_type: ID
    $departure_airport: ID
    $arrival_airport: ID
    $notes: String
    $dealtypes: [ID]
    $including_accommodation: Boolean
    $including_flight: Boolean
    $including_rentalcar: Boolean
    $including_transfer: Boolean
    $including_train_travel: Boolean
    $accommodation: ID
    $destination: ID
    $selected_media: JSON
    $publish_date: DateTime
    $expire_date: DateTime
    $alternative_date: String
    $booking_examples: [ID]
    $formula_price_4_lg: Float
    $formula_price_4_hp: Float
    $formula_price_4_ai: Float
    $formula_price_8_lg: Float
    $formula_price_8_hp: Float
    $formula_price_8_ai: Float
    $formula_price_15_lg: Float
    $formula_price_15_hp: Float
    $formula_price_15_ai: Float
    $discount: Int
    $discount_expire: DateTime
    $slug: String
    $show_on_homepage: Boolean
    $show_on_be: Boolean
    $automatic: Boolean
    $affiliate_url: String
    $providers: [ID]
    $additional_html: String
  ) {
    updateDealConcept(
      input: {
        where: { id: $id }
        data: {
          title: $title
          subtitle: $subtitle
          meta_title: $meta_title
          meta_description: $meta_description
          intro: $intro
          price: $price
          days: $days
          nights: $nights
          date: $date
          persons: $persons
          board_type: $board_type
          departure_airport: $departure_airport
          arrival_airport: $arrival_airport
          notes: $notes
          dealtypes: $dealtypes
          including_accommodation: $including_accommodation
          including_flight: $including_flight
          including_rentalcar: $including_rentalcar
          including_transfer: $including_transfer
          including_train_travel: $including_train_travel
          accommodation: $accommodation
          destination: $destination
          selected_media: $selected_media
          publish_date: $publish_date
          expire_date: $expire_date
          alternative_date: $alternative_date
          booking_examples: $booking_examples
          formula_price_4_lg: $formula_price_4_lg
          formula_price_4_hp: $formula_price_4_hp
          formula_price_4_ai: $formula_price_4_ai
          formula_price_8_lg: $formula_price_8_lg
          formula_price_8_hp: $formula_price_8_hp
          formula_price_8_ai: $formula_price_8_ai
          formula_price_15_lg: $formula_price_15_lg
          formula_price_15_hp: $formula_price_15_hp
          formula_price_15_ai: $formula_price_15_ai
          discount: $discount
          discount_expire: $discount_expire
          slug: $slug
          show_on_homepage: $show_on_homepage
          show_on_be: $show_on_be
          automatic: $automatic
          affiliate_url: $affiliate_url
          is_saved: true
          providers: $providers
          additional_html: $additional_html
        }
      }
    ) {
      dealConcept {
        id
        title
        subtitle
        providers {
          id
        }
        published_deal {
          id
        }
        alternative_date
      }
    }
  }
`;

export const UPDATE_DEAL_STATUS = gql`
  mutation UpdateDeal($id: ID!, $status: ENUM_DEAL_STATUS!) {
    updateDealConcept(input: { where: { id: $id }, data: { status: $status } }) {
      dealConcept {
        id
      }
    }
  }
`;

export const UPDATE_DEALURLS = gql`
  mutation UpdateDeal($dealId: ID!, $slug: String) {
    updateDealConcept(input: { where: { id: $dealId }, data: { slug: $slug } }) {
      dealConcept {
        id
      }
    }
  }
`;

export const PUBLISH_DEAL = gql`
  mutation publishDeal($id: ID!) {
    publishDeal(input: { where: { id: $id } }) {
      dealConcept {
        id
        published
      }
    }
  }
`;

export const CREATE_DEAL = gql`
  mutation CreateDeal($publish_date: DateTime, $expire_date: DateTime, $website: ID!) {
    createDealConcept(
      input: {
        data: {
          automatic: false
          publish_date: $publish_date
          expire_date: $expire_date
          website: $website
        }
      }
    ) {
      dealConcept {
        id
        title
      }
    }
  }
`;

export const DELETE_BOOKINGEXAMPLE = gql`
  mutation DeleteBookingexample($id: ID!) {
    deleteBookingexample(input: { where: { id: $id } }) {
      bookingexample {
        id
      }
    }
  }
`;

export const CREATE_BOOKINGEXAMPLE = gql`
  mutation CreateBookingExample($title: String!, $url: String!, $image: Upload, $deals: [ID]!) {
    createBookingexample(
      input: {
        data: { title: $title, url: $url, image: $image, deals: $deals, type: "booking_example" }
      }
    ) {
      bookingexample {
        id
      }
    }
  }
`;

export const UPLOAD_FILE = gql`
  mutation upload($file: String) {
    UploadFile(input: { file: $file }) {
      id
    }
  }
`;

export const UPDATE_DEALPROVIDERS = gql`
  mutation UpdateOrCreateDealprovider($dealproviders: [UpdateOrCreateDealprovider]) {
    updateOrCreateDealprovider(input: { data: $dealproviders }) {
      dealproviders {
        id
        provider {
          id
        }
      }
    }
  }
`;

export const CREATE_DEALPROVIDER = gql`
  mutation CreateDealProvider(
    $provider: ID
    $deeplink: String
    $active: Boolean
    $original_link: String
    $not_affiliate: Boolean
  ) {
    createDealprovider(
      input: {
        data: {
          provider: $provider
          deeplink: $deeplink
          active: $active
          original_link: $original_link
          not_affiliate: $not_affiliate
        }
      }
    ) {
      dealprovider {
        id
      }
    }
  }
`;

export const GET_ACCOMMODATION_AND_DESTINATION_IMAGES = gql`
  query accommodation($accommodationID: ID!, $destinationID: ID!) {
    accommodation(id: $accommodationID) {
      media {
        id
        name
        thumb
        type
      }
    }
    destination(id: $destinationID) {
      media {
        id
        name
        thumb
        type
      }
    }
  }
`;

export const CREATE_BOOKINGEXAMPLES = gql`
  mutation CreateBookingexample($bookingExamples: [BookingexampleInput]) {
    createBookingexamples(input: { data: $bookingExamples }) {
      bookingexample {
        id
        title
        url
        image {
          id
          url
        }
      }
    }
  }
`;

export const UPDATE_BOOKING_EXAMPLE = gql`
  mutation UpdateBookingexample($id: ID!, $title: String, $url: String) {
    updateBookingexample(input: { where: { id: $id }, data: { title: $title, url: $url } }) {
      bookingexample {
        id
        title
        url
        image {
          id
          url
        }
      }
    }
  }
`;

export const GET_DESTINATIONS = gql`
  query destinations {
    destinations {
      id
      name
      type
      media {
        url
        thumb
        name
      }
      parent {
        id
        name
      }
    }
  }
`;

export const FILTER_DESTINATIONS = gql`
  query destinations($where: JSON, $limit: Int, $start: Int) {
    destinations(limit: $limit, start: $start, where: $where) {
      id
      name
      description
      type
      media {
        url
        thumb
      }
    }
    destinationsConnection(limit: -1, where: $where) {
      aggregate {
        count
      }
    }
  }
`;

// export const FILTER_ACCOMMODATIONS = gql`
//     query accommodations($searchText: String, $limit: Int, $start: Int) {
//         accommodations(
//             limit: $limit
//             start: $start
//             where: { name_contains: $searchText }) {
//             id
//             name
//             description
//             media {
//                 url
//                 thumb
//             }
//         }
//         accommodationsConnection(
//             where: {
//                 name_contains: $searchText
//             }
//         ) {
//             aggregate {
//                 count
//             }
//         }
//     }
// `;

export const GET_COUNTRIES = gql`
  query destinations($where: JSON) {
    destinations(where: $where) {
      id
      name
      description
      type
      media {
        url
      }
    }
  }
`;

export const GET_DESTINATION_CHILDREN = gql`
  query destinations($where: JSON) {
    destinations(where: $where) {
      id
      name
      description
      type
      media {
        url
      }
    }
  }
`;

export const FILTER_ACCOMMODATIONS = gql`
  query accommodations($searchText: String, $limit: Int, $start: Int) {
    accommodations(limit: $limit, start: $start, where: { name_contains: $searchText }) {
      id
      name
      description
      media {
        url
        thumb
      }
    }
    accommodationsConnection(where: { name_contains: $searchText }) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_DESTINATION = gql`
  query destination($id: ID!) {
    destination(id: $id) {
      id
      name
      description
      latitude
      longitude
      parent {
        id
        name
      }
      media {
        id
        url
        thumb
      }
    }
  }
`;

export const GET_ACCOMMODATION = gql`
  query accommodation($id: ID!) {
    accommodation(id: $id) {
      id
      name
      description
      rating
      stars
      facilities
      usps
      media {
        id
        url
        thumb
        name
      }
    }
  }
`;

export const CREATE_ACCOMMODATION = gql`
  mutation createAccommodation(
    $name: String!
    $description: String!
    $rating: Float
    $stars: Int
    $media: [ID]
    $usps: JSON
  ) {
    createAccommodation(
      input: {
        data: {
          name: $name
          description: $description
          stars: $stars
          rating: $rating
          media: $media
          usps: $usps
        }
      }
    ) {
      accommodation {
        id
      }
    }
  }
`;

export const UPDATE_ACCOMMODATION = gql`
  mutation updateAccommodation(
    $id: ID!
    $name: String
    $description: String
    $media: [ID]
    $stars: Int
    $rating: Float
    $usps: JSON
    $facilities: JSON
  ) {
    updateAccommodation(
      input: {
        where: { id: $id }
        data: {
          name: $name
          description: $description
          media: $media
          stars: $stars
          rating: $rating
          usps: $usps
          facilities: $facilities
        }
      }
    ) {
      accommodation {
        id
        name
        rating
        stars
        description
        usps
      }
    }
  }
`;

export const CREATE_DESTINATION = gql`
  mutation createDestination($name: String!, $description: String!, $type: ENUM_DESTINATION_TYPE!) {
    createDestination(input: { data: { name: $name, description: $description, type: $type } }) {
      destination {
        id
      }
    }
  }
`;

export const UPDATE_DESTINATION = gql`
  mutation updateDestination(
    $id: ID!
    $name: String
    $description: String
    $media: [ID]
    $parent: ID
    $latitude: Float
    $longitude: Float
  ) {
    updateDestination(
      input: {
        where: { id: $id }
        data: {
          name: $name
          description: $description
          media: $media
          parent: $parent
          latitude: $latitude
          longitude: $longitude
        }
      }
    ) {
      destination {
        id
        name
        description
      }
    }
  }
`;

export const CREATE_PRICE = gql`
  mutation CreatePrice(
    $highlight: Boolean
    $date_from: DateTime
    $date_to: DateTime
    $persons: Int
    $night_count: Int
    $day_count: Int
    $transport_type: ENUM_PRICE_TRANSPORT_TYPE
    $departureairport: ID
    $arrival_airport: ID
    $board_type: ID
    $price: Float
    $provider: ID
    $manual_price: Boolean
    $expire_date: DateTime
    $concept: ID
    $deeplink: String
  ) {
    createPrice(
      input: {
        data: {
          highlight: $highlight
          date_from: $date_from
          date_to: $date_to
          persons: $persons
          night_count: $night_count
          day_count: $day_count
          departureairport: $departureairport
          arrival_airport: $arrival_airport
          board_type: $board_type
          price: $price
          provider: $provider
          manual_price: $manual_price
          expire_date: $expire_date
          transport_type: $transport_type
          concept: $concept
          deeplink: $deeplink
        }
      }
    ) {
      price {
        id
        date_from
        date_to
        persons
        night_count
        day_count
        departureairport {
          id
          name
          iata
        }
        arrival_airport {
          id
          name
          iata
        }
        board_type {
          id
          name
        }
        price
        provider {
          id
          name
        }
        manual_price
        expire_date
        transport_type
        highlight
      }
    }
  }
`;

export const GET_PRICES = gql`
  query Prices($where: JSON) {
    prices(where: $where) {
      id
      date_from
      date_to
      persons
      night_count
      day_count
      departureairport {
        id
        name
        iata
      }
      arrival_airport {
        id
        name
        iata
      }
      board_type
      price
      provider {
        id
        name
      }
      manual_price
      expire_date
      transport_type
      highlight
    }
  }
`;

export const FILTER_PRICES = gql`
  query FilterPrices($where: JSON, $start: Int, $limit: Int) {
    prices(where: $where, start: $start, limit: $limit) {
      id
      date_from
      date_to
      persons
      night_count
      day_count
      departureairport {
        id
        name
        iata
      }
      arrival_airport {
        id
        name
        iata
      }
      board_type
      price
      provider {
        id
        name
      }
      manual_price
      expire_date
      transport_type
      highlight
    }
    pricesConnection(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const DELETE_PRICE = gql`
  mutation deletePrice($id: ID!, $dealId: ID!) {
    deletePrice(input: { where: { id: $id, dealId: $dealId } }) {
      price {
        id
      }
    }
  }
`;

export const UPDATE_PRICE = gql`
  mutation updatePrice(
    $id: ID!
    $date_from: DateTime
    $date_to: DateTime
    $persons: Int
    $night_count: Int
    $day_count: Int
    $departureairport: ID
    $arrival_airport: ID
    $board_type: ID
    $transport_type: ENUM_PRICE_TRANSPORT_TYPE
    $price: Float
    $provider: ID
    $manual_price: Boolean
    $expire_date: DateTime
    $highlight: Boolean
  ) {
    updatePrice(
      input: {
        where: { id: $id }
        data: {
          date_from: $date_from
          date_to: $date_to
          persons: $persons
          night_count: $night_count
          day_count: $day_count
          departureairport: $departureairport
          arrival_airport: $arrival_airport
          board_type: $board_type
          transport_type: $transport_type
          price: $price
          provider: $provider
          manual_price: $manual_price
          expire_date: $expire_date
          highlight: $highlight
        }
      }
    ) {
      price {
        id
        date_from
        persons
        night_count
        day_count
        board_type {
          id
          name
        }
        transport_type
        price
        departureairport {
          id
          iata
        }
        arrival_airport {
          id
          iata
        }
      }
    }
  }
`;

export const GET_DEALPROVIDERS_OF_DEAL = gql`
  query GetDealProvidersOfDeal($id: ID!) {
    dealConcept(id: $id) {
      id
      dealproviders {
        id
        provider {
          id
          name
        }
      }
    }
  }
`;

export const GET_DEALTYPES = gql`
  query dealtypes {
    dealtypes(sort: "name:asc") {
      id
      name
    }
  }
`;

export const GET_DEALPROVIDERS_WHERE = gql`
  query DealProvidersWhere {
    dealproviders(where: { deals: "5d551c0cace2c045cab48b5c" }) {
      id
      provider {
        id
        name
        deal {
          id
        }
      }
    }
  }
`;

export const DELETE_DEALPROVIDER = gql`
  mutation deleteDealprovider($id: ID!) {
    deleteDealprovider(input: { where: { id: $id } }) {
      dealprovider {
        id
      }
    }
  }
`;

export const UPDATE_DEAlSTATUS = gql`
  mutation UpdateStatus($id: ID!, $published: Boolean, $expire_date: DateTime) {
    updateStatus(
      input: { where: { id: $id }, data: { published: $published, expire_date: $expire_date } }
    ) {
      dealConcept {
        id
        title
        accommodation {
          name
          id
        }
        selected_media
        destination {
          name
          id
        }
        automatic
        publish_date
        price
        is_published_deal
        published
        expire_date
        status
      }
    }
  }
`;

export const GET_WEBSITES = gql`
  query Websites {
    websites {
      id
      country_code
    }
  }
`;
