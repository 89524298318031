import React, { useState, useRef, useEffect } from 'react';
import { DELETE_DEAL, GET_DEAL, UPDATE_DEAL, PUBLISH_DEAL } from './apollo';
import { FILTER_DEALS } from 'pages/Deals/Overview/apollo';
import { defaultSearchQuery, prepareSearchQueryForFilter } from 'pages/Deals/Overview';
import moment from 'moment';
import { Prompt } from 'react-router';
import {
  Col,
  Row,
  Button,
  Alert,
  Spinner,
  Label,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  CustomInput,
} from 'reactstrap';
import { Mutation, Query } from 'react-apollo';
import DateTimePicker from 'react-datetime-picker';
import {
  formatInitialIntro,
  formatInitialDetails,
  formatInitialUrls,
  formatInitialImages,
  formatInitialPrices,
  formatInitialOthers,
  formatForUpdateDate,
} from './helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Formik, Form } from 'formik';

import DealIntroduction from 'containers/Deal/DealIntroduction';
import DealUrls from 'containers/Deal/DealUrls';
import DealDetails from 'containers/Deal/DealDetails';
import DealImages from 'containers/Deal/DealImages';
import DealPrices from 'containers/Deal/DealPrices';
import DealOthers from 'containers/Deal/DealOthers';
import NLVlag from 'assets/nl-vlag.png';
import BEVlag from 'assets/be-vlag.png';

const Deals = ({ match, history }) => {
  const [displayUpdateSuccess, setDisplayUpdateSuccess] = useState(false);
  const [displayUpdateFailed, setDisplayUpdateFailed] = useState(false);
  const [succesMessage, setSuccesMessage] = useState('');
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [validFields, setValidFields] = useState([]);
  const [conceptHasBeenSaved, setConceptHasBeenSaved] = useState(false);


  let dealValues = useRef({});
  // var handler = function(ev) {
  //   ev.preventDefault();
  //   return ev.returnValue = 'Are you sure you want to close?';
  // }

  function validationChange(isValid, index) {
    let newValidations = [...validFields];
    newValidations[index] = isValid;
    setValidFields(newValidations);
  }

  const dealChange = values => {
    const newDeal = { ...dealValues.current, ...values };
    dealValues.current = newDeal;
    // window.addEventListener("beforeunload", handler)
  };

  function handleUpdateCompletion(text) {
    // window.removeEventListener("beforeunload", handler)
    setSuccesMessage(text);
    setDisplayUpdateSuccess(true);
    setValidFields([]);
    setTimeout(() => setDisplayUpdateSuccess(false), 3000);
  }

  function handleError(text) {
    setSuccesMessage(text);
    setDisplayUpdateFailed(true);
    setTimeout(() => setDisplayUpdateFailed(false), 3000);
  }

  function handleDeleteCompletion(event) {
    history.push('/deals');
  }

  function toggleDeleteModal() {
    setDeleteModalVisible(!deleteModalVisible);
  }

  function onChangeDate(type, date) {
    if (type === 'publish_date') {
      dealChange({ publish_date: formatForUpdateDate(date) });
    } else {
      dealChange({ expire_date: formatForUpdateDate(date) });
    }

    setConceptHasBeenSaved(false)

    const newValidFields = [...validFields];
    newValidFields[3] = true;
    setValidFields(newValidFields);
  }

  async function handleConceptSubmit(callback) {
    await callback();
    setConceptHasBeenSaved(true);
  }

  return (
    <Query query={GET_DEAL} variables={{ id: match.params.id }}>
      {({ loading, error, data }) => {
        if (loading)
          return (
            <Row>
              <Col className="d-flex justify-content-center mt-3" lg={{ size: 6, offset: 3 }}>
                <Spinner type="grow" color="primary" />
              </Col>
            </Row>
          );

        if (error) return <alert color="danger">Er ging iets mis</alert>;

        const { airports, dealtypes, providers, boardtypes } = data;
        let formattedDealTypes = []
        if (dealtypes) {
          formattedDealTypes = dealtypes.map(dealType => {
            return { value: dealType.id, label: dealType.name };
          });
        }

        if (!data.dealConcept) return <p>Deze deal bestaat niet (meer).</p>;
        if (data.dealConcept) {

          dealValues.current = { ...dealValues.current, id: data.dealConcept.id };

          // dealChange({
          //   publish_date: formatInitialDate(data.dealConcept.publish_date),
          //   expire_date: formatInitialDate(data.dealConcept.expire_date)
          // });
          return (
            <Container className="mt-5">
              {displayUpdateSuccess && <Alert color="success">{succesMessage}</Alert>}
              {displayUpdateFailed && <Alert color="danger">{succesMessage}</Alert>}
              <Prompt
                when={validFields.filter(field => field === true).length > 0}
                message="LET OP! je hebt onopgeslagen wijzigingen. Deze gaan verloren als je de pagina verlaat."
              />
              <Row>
                <Col md={12} lg={9}>
                  <DealIntroduction
                    values={formatInitialIntro(data.dealConcept)}
                    initialData={formatInitialIntro(data.dealConcept)}
                    isValid={value => validationChange(value, 0)}
                    validValues={dealChange}
                  />
                  <DealDetails
                    initialData={formatInitialDetails(data.dealConcept)}
                    boardTypes={boardtypes}
                    allProviders={providers}
                    dealtypes={formattedDealTypes}
                    airports={airports}
                    isValid={value => validationChange(value, 1)}
                    validValues={dealChange}
                  />
                  <DealUrls
                    initialData={formatInitialUrls(data.dealConcept)}
                    isAutomaticDeal={data.dealConcept.automatic}
                    dealId={data.dealConcept.id}
                    slug={data.dealConcept.slug}
                    allProviders={providers}
                    airports={airports}
                    boardTypes={boardtypes}
                    validValues={dealChange}
                    isValid={value => validationChange(value, 4)}
                  />
                  <DealImages
                    initialData={formatInitialImages(data.dealConcept)}
                    validValues={dealChange}
                    accommodationId={
                      dealValues.current.accommodation ? dealValues.current.accommodation : null
                    }
                    destinationId={
                      dealValues.current.destination ? dealValues.current.destination : null
                    }
                  />
                  {data.dealConcept.automatic && (
                    <DealPrices
                      boardTypes={boardtypes}
                      initialData={formatInitialPrices(data.dealConcept)}
                      providers={providers}
                      airports={airports}
                      validValues={dealChange}
                      isValid={value => validationChange(value, 2)}
                    />
                  )}
                  <DealOthers
                    initialData={formatInitialOthers(data.dealConcept)}
                    validValues={dealChange}
                    isValid={value => validationChange(value, 3)}
                  />
                </Col>
                <Col md={12} lg={3}>
                  <div style={{ position: 'sticky', top: 20 }}>
                    <Row>

                      <Formik
                        // enableReinitialize={true}
                        initialValues={{
                          id: data.dealConcept.id,
                          publish_date: data.dealConcept.publish_date
                            ? new Date(data.dealConcept.publish_date)
                            : null,
                          expire_date: data.dealConcept.expire_date
                            ? new Date(data.dealConcept.expire_date)
                            : null,
                        }}
                        onSubmit={() => {
                          // updateDeal({ variables: dealValues.current});
                        }}
                      >
                        {props => {
                          const { values, handleChange } = props;
                          return (
                            <Form>
                              <Col xs={12}>
                                <FormGroup>
                                  <Label>Publicatie datum</Label>
                                  <br />
                                  <DateTimePicker
                                    format="dd-MM-yy HH:mm"
                                    value={values.publish_date}
                                    onChange={value => {
                                      onChangeDate('publish_date', value);
                                      handleChange({
                                        target: { name: 'publish_date', value: value },
                                      });
                                    }}
                                    disableClock={true}
                                    calendarIcon={
                                      <FontAwesomeIcon
                                        className="mr-auto"
                                        icon={faCalendar}
                                      // onClick={() => refetch()}
                                      />
                                    }
                                    clearIcon={
                                      <FontAwesomeIcon
                                        className="mr-auto"
                                        icon={faTimes}
                                      // onClick={() => refetch()}
                                      />
                                    }
                                  />
                                </FormGroup>
                                <FormGroup>
                                  <Label>Verloop datum</Label>
                                  <br />
                                  <DateTimePicker
                                    format="dd-MM-yy HH:mm"
                                    value={values.expire_date}
                                    onChange={value => {
                                      onChangeDate('expire_date', value);
                                      handleChange({
                                        target: { name: 'expire_date', value: value },
                                      });
                                    }}
                                    disableClock={true}
                                    calendarIcon={
                                      <FontAwesomeIcon
                                        className="mr-auto"
                                        icon={faCalendar}
                                      // onClick={() => refetch()}
                                      />
                                    }
                                    clearIcon={
                                      <FontAwesomeIcon
                                        className="mr-auto"
                                        icon={faTimes}
                                      // onClick={() => refetch()}
                                      />
                                    }
                                  />
                                </FormGroup>
                              </Col>
                            </Form>
                          );
                        }}
                      </Formik>
                      <hr />
                      <Col xs={12}>
                        <Mutation
                          mutation={UPDATE_DEAL}
                          onCompleted={() =>
                            handleUpdateCompletion('De deal is succesvol geupdated')
                          }
                          onError={() => handleError('Er is iets mis gegaan')}
                        >
                          {updateDeal => (
                            <Formik
                              // enableReinitialize={true}
                              initialValues={{
                                id: data.dealConcept.id,
                                publish_date: data.dealConcept.publish_date,
                                expire_date: data.dealConcept.expire_date,
                              }}
                              onSubmit={() => {
                                updateDeal({ variables: dealValues.current });
                              }}
                            >
                              {props => {
                                const { handleSubmit } = props;
                                return (
                                  <Form>
                                    <Button
                                      style={{ width: '100%' }}
                                      disabled={
                                        validFields.filter(field => field === true).length <
                                        validFields.filter(
                                          field => typeof field !== 'undefined' || field != null,
                                        ).length || validFields.length === 0
                                      }
                                      onClick={() => handleConceptSubmit(handleSubmit)}
                                      color="primary"
                                    >
                                      {loading && <Spinner size="sm" color="white" />}
                                      Concept opslaan
                                    </Button>
                                  </Form>
                                );
                              }}
                            </Formik>
                          )}
                        </Mutation>

                        <hr />
                      </Col>
                      <Col xs={12} className="mb-2">
                        <Mutation
                          mutation={PUBLISH_DEAL}
                          onCompleted={() => handleUpdateCompletion('Gepubliceerd')}
                          onError={() => handleError('Er is iets mis gegaan')}>
                          {(publishDeal, { loading, error }) => (
                            <>
                              {error &&
                                error.networkError.message ===
                                'Response not successful: Received status code 423' && (
                                  <Alert color="danger">
                                    Niet te snel! Zet maar ff koffie ;-)
                                  </Alert>
                                )}
                              <Button
                                style={{ width: '100%' }}
                                disabled={loading || error > 0 || !conceptHasBeenSaved}
                                type="button"
                                color="success"
                                onClick={() => {
                                  const expire_date = new Date();
                                  expire_date.setDate(expire_date.getDate() + 14);
                                  const id = match.params.id;
                                  publishDeal({ variables: { id: id } });
                                  setConceptHasBeenSaved(false)
                                }}
                              >
                                {loading && <Spinner size="sm" color="white" />}
                                Publiceren
                              </Button>
                            </>
                          )}
                        </Mutation>
                      </Col>

                      <Col xs={12} className="text-center">
                        <Mutation mutation={DELETE_DEAL} onCompleted={handleDeleteCompletion} onError={(message) => console.log('MESSAGE', message)}>
                          {(deleteDeal, { loading, error }) => (
                            <>
                              {error && (
                                <Alert color="danger">
                                  Oeps, er ging iets mis tijdens het verwijderen
                                </Alert>
                              )}
                              <Button
                                style={{ width: '100%' }}
                                disabled={loading || error}
                                type="button"
                                color="danger"
                                onClick={() => toggleDeleteModal()}
                              >
                                {loading && <Spinner size="sm" color="white" />}
                                Verwijderen
                              </Button>

                              <Modal
                                size="sm"
                                isOpen={deleteModalVisible}
                                toggle={toggleDeleteModal}
                              >
                                <ModalHeader toggle={toggleDeleteModal}>
                                  Weet je het zeker?
                                </ModalHeader>
                                <ModalBody>
                                  <Row>
                                    <Col lg={6}>
                                      <Button
                                        style={{ width: '100%' }}
                                        type="button"
                                        color="danger"
                                        onClick={() => deleteDeal({ variables: match.params })}
                                      >
                                        Ja
                                      </Button>
                                    </Col>

                                    <Col lg={6}>
                                      <Button
                                        style={{ width: '100%' }}
                                        type="button"
                                        color="primary"
                                        onClick={toggleDeleteModal}
                                      >
                                        Annuleren
                                      </Button>
                                    </Col>
                                  </Row>
                                </ModalBody>
                              </Modal>
                              <hr />
                              <Row className="mt-3">
                                {data.deal && data.dealConcept.website && data.dealConcept.website.country_code && (
                                  <Col lg={2} className="text-center">
                                    <img src={data.dealConcept.website.country_code === 'NL' ? NLVlag : BEVlag} className="img-responsive img-rounded" style={{ height: '18px', borderRadius: '4px', width: '24px' }}></img>
                                  </Col>
                                )}
                                <Col lg={5} className="pl-3">
                                  <FormGroup className="d-flex mb-0">
                                    <Label
                                      for="ticketDeal"
                                      className="mr-2 mb-0"
                                      style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                      }}
                                    >
                                      Ticket deal
                                    </Label>
                                    <div>
                                      <CustomInput
                                        type="switch"
                                        id="ticketDeal"
                                        name="ticketDeal"
                                      />
                                    </div>
                                  </FormGroup>
                                </Col>
                                <Col lg={5} className="pr-3">
                                  <FormGroup className="d-flex mb-0">
                                    <Label
                                      for="automaticDeal"
                                      className="mr-2 mb-0"
                                      style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                      }}
                                    >
                                      Automatisch
                                    </Label>
                                    <div>
                                      <Mutation
                                        mutation={UPDATE_DEAL}
                                        refetchQueries={() => {
                                          return [
                                            {
                                              query: GET_DEAL,
                                              variables: { id: data.dealConcept.id },
                                            },
                                          ];
                                        }}
                                      >
                                        {(updateDeal, { loading, error }) => (
                                          <CustomInput
                                            type="switch"
                                            id="automaticDeal"
                                            name="automaticDeal"
                                            // disabled={error || loading}
                                            disabled={true}
                                            defaultChecked={data.dealConcept.automatic}
                                            onChange={e => {
                                              updateDeal({
                                                variables: {
                                                  ...dealValues.current,
                                                  id: data.dealConcept.id,
                                                  automatic: e.currentTarget.checked,
                                                },
                                              });
                                            }}
                                          />
                                        )}
                                      </Mutation>
                                    </div>
                                  </FormGroup>
                                </Col>
                              </Row>
                              <hr />
                              <Row className="mt-3">
                                <Col lg={6} className="pl-5">
                                  <p className="mb-0">
                                    Aangemaakt op:{' '}
                                  </p>
                                  {data.dealConcept.createdAt && (
                                    <p className="mb-0">
                                      <small>
                                        {moment(data.dealConcept.createdAt).format('DD-MM-YYYY HH:mm:ss')}
                                      </small>
                                    </p>
                                  )}
                                </Col>
                                <Col lg={6} className="pl-5">
                                  <p className="mb-0">
                                    Laatst bewerkt door:{' '}
                                    <strong>
                                      {data.dealConcept.edited_by
                                        ? data.dealConcept.edited_by.username
                                        : 'Niemand'}
                                    </strong>
                                  </p>
                                  {data.dealConcept.updatedAt && (
                                    <p className="mb-0">
                                      <small>
                                        {moment(data.dealConcept.updatedAt).format('DD-MM-YYYY HH:mm:ss')}
                                      </small>
                                    </p>
                                  )}
                                </Col>
                              </Row>
                            </>
                          )}
                        </Mutation>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          );
        }
      }}
    </Query>
  );
};

export default Deals;
