import axios from 'axios';
import * as Yup from 'yup';
import moment from 'moment';
const baseUrl = process.env.REACT_APP_BASE_URL;

export const formatInitialDailyDeal = ({
  id,
  deal,
  title,
  push_title,
  intro,
  push_text,
  image_url,
  direct_link,
  direct_link_partner,
  push_date,
    price
}) => {
  const date = push_date ? moment(push_date).format('YYYY-MM-DD') : '';
  return {
    id,
    deal: deal ? { label: deal.title, value: deal.id } : null,
    title: title ? title : '',
    push_title: push_title ? push_title : '',
    intro: intro ? intro : '',
    push_text: push_text ? push_text : '',
    image_url: image_url ? image_url : '',
    automatic: deal ? deal.automatic : false,
    direct_link,
    direct_link_partner,
    push_date: date,
    price
  };
};

export const getResettedValues = values => {
  return {
    id: values.id,
    deal: null,
    title: '',
    push_title: '',
    intro: '',
    push_text: '',
    image_url: '',
    direct_link: false,
    direct_link_partner: '',
    url: '',
    price: null
  };
};

export const formatForUpdateDailyDeal = ({
  id,
  deal,
  title,
  push_title,
  intro,
  push_text,
  image_url,
  direct_link,
  direct_link_partner,
  url,
    push_date,
    price
}) => {
  const date = push_date ? new Date(push_date).toISOString() : null;
  return {
    variables: {
      id,
      deal: deal ? deal.value : null,
      title: title ? title : null,
      push_title: push_title ? push_title : null,
      intro: intro ? intro : null,
      push_text: push_text ? push_text : null,
      image_url: image_url ? image_url : null,
      direct_link: direct_link ? true : false,
      direct_link_partner: direct_link_partner ? direct_link_partner : null,
      push_date: date,
      price: price ? price : null
    },
  };
};

export const getAsync = (inputValue, types, field) => {
  const API_URL = `${baseUrl}/graphql`;
  const token = localStorage.getItem('token');
  const countryId = localStorage.getItem('country_id');

  async function loadData() {
    const data = await axios.post(
      API_URL,
      {
        query: `
            query{
              ${types}(limit: 40, where: {
                website: "${countryId}"
                ${field}: "${inputValue}"
              }) {
                id
                title
                selected_media
              }
          }
        `,
        variables: {},
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${token}`,
        },
      },
    );
    return data.data.data[types].map(type => {
      return { value: type.id, label: type.title, selectedMedia: type.selected_media };
    });
  }

  if (!inputValue) return null;
  return new Promise(resolve => {
    const data = loadData();
    resolve(data);
  });
};

const errorText = {
  required: 'Verplicht Veld',
};

export const validation = Yup.object().shape({
  deal: Yup.object()
    .nullable()
    .required(errorText.required),
});
