import React, { useRef, useState, memo } from 'react';
import Card from 'components/Card';
import { Field, Form, Formik } from 'formik';
import PricesIcon from 'assets/icons/prices-icon.svg';
import { createEventTarget } from "utils/helpers";
import {
    Col,
    Row,
    FormGroup,
    Label,
    Input,
    Card as BootstrapCard,
    CardHeader,
    CardBody,
    Button, Alert,
} from 'reactstrap';
import { Mutation, Query } from 'react-apollo';
import { DELETE_PRICE, UPDATE_DEAL } from 'queries/deals';
import { GET_PRICES } from './apollo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ManuallyPriceModal from 'containers/Deal/ManuallyPricesModal';
import HighlightedPriceModal from 'containers/Deal/HighlightedPriceModal';
import PropTypes from 'prop-types';
import {
    formatForUpdate,
    countNumberOfFilledInInputs,
    boardTypesEnums,
    transportTypesEnums,
} from './helpers';
import ManuallyPrice from 'components/ManuallyPrice';
import moment from 'moment';
import validation from './validation';
import DetailIcon from "assets/icons/detail-icon.svg";

const getManualPrices = prices => prices.filter(price => price.manual_price);

function formatPrice(price) {
    return {
        id: price.id ? price.id : null,
        board_type: price.board_type ? price.board_type : '',
        date_from: price.date_from ? moment(price.date_from).format('YYYY-MM-DD') : '',
        date_to: price.date_to ? moment(price.date_to).format('YYYY-MM-DD') : '',
        departureairport: price.departureairport
            ? {
                label: price.departureairport.name,
                value: price.departureairport.id,
                iata: price.departureairport.iata,
            }
            : '',
        night_count: price.night_count ? price.night_count : '',
        day_count: price.day_count ? price.day_count : '',
        transport_type: price.transport_type
            ? transportTypesEnums.find(tT => tT.value === price.transport_type)
            : '',
        persons: price.persons ? price.persons : '',
        provider: price.provider ? { label: price.provider.name, value: price.provider.id } : '',
        price: price.price ? price.price : '',
        expire_date: price.expire_date ? moment(price.expire_date).format('YYYY-MM-DD') : '',
        manual_price: price.manual_price,
        deals: price?.deals?.map(deal => deal.id),
        highlight: price.highlight,
        deeplink: price?.deeplink
    };
}

function createInitialPrice(dealId) {
    return {
        id: null,
        board_type: '',
        date_from: '',
        departureairport: '',
        night_count: '',
        day_count: '',
        transport_type: '',
        persons: '',
        provider: '',
        price: '',
        expire_date: '',
        manual_price: false,
        deals: [dealId],
        highlight: false,
        deeplink: ''
    };
}

const DealPrices = memo(
    ({ initialData, providers, airports, isValid, validValues, boardTypes }) => {
        const [manuallyPrices, setManuallyPrices] = useState([]);
        const [selectedPrice, setSelectedPrice] = useState(null);
        const discount_input = useRef(null);
        const [successMessage, setSuccessMessage] = useState(null);
        const [errorMessage, setErrorMessage] = useState(null);
        const [pricesError, setPricesError] = useState(null);
        let refetchPrices = null;

        // function addManuallyPrice(manuallyPrice) {
        //   const newManuallyPrices = [...manuallyPrices, manuallyPrice];
        //   setManuallyPrices(newManuallyPrices);
        // }

        function handeUpdateCompletion() {
            setSuccessMessage('Prijzen zijn succesvol geupdatet');
            setTimeout(() => {
                setSuccessMessage(null);
            }, 3000);
        }

        function handleError() {
            setErrorMessage('Er gaat iets fout! Controleer de velden.');
            setTimeout(() => {
                setErrorMessage(null);
            }, 3000);
        }

        const validate = values => {
            try {
                validation.validateSync(values, { abortEarly: false });
                isValid(true);
                validValues(formatForUpdate(values));
                return true;
            } catch (error) {
                isValid(false);
                return false;
            }
        };

        function onClose(hasAddedOne) {
            setSelectedPrice(null)
            if (hasAddedOne && refetchPrices) {
                refetchPrices()
            }
        }

        function deleteManualPrice(priceId, callback, values) {
            const newPrices = values.prices.filter(price => price.id !== priceId);
            callback(createEventTarget('prices', newPrices));
        }

        function addManuallyPrice(price, callback, values) {
            const newPrices = [...values.prices, price]
            callback(createEventTarget('prices', newPrices));
        }

        function onCompletionDeletePrice(event, handleChange, values) {
            // deleteManualPrice(event.deletePrice.price.id, handleChange, values)
        }

        function onPricesError(e) {
            console.log('eee', e)
            setPricesError('Er ging iets mis tijdens het verwijderen van de prijs')
        }

        return (
            <Mutation mutation={UPDATE_DEAL} onCompleted={handeUpdateCompletion} onError={handleError}>
                {updateDeal => (
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialData}
                        onSubmit={(values, { setValues }) => {
                            updateDeal({ variables: formatForUpdate(values) });
                        }}
                        validate={errors => validate(errors)}
                    >
                        {props => {
                            const { values, errors, handleSubmit, handleChange } = props;
                            return (
                                <Card
                                    disabled={Object.keys(errors).length > 0}
                                    title="Prijzen"
                                    subTitle={`Er zijn ${countNumberOfFilledInInputs(values)} regels aangemaakt`}
                                    thumbnailSrc={PricesIcon}
                                    errorMessage={errorMessage}
                                    successMessage={successMessage}
                                    open={typeof successMessage !== 'undefined' && successMessage ? true : false}
                                    uniqueId="prijzen"
                                    onSave={() => handleSubmit()}
                                >
                                    <Form onSubmit={handleSubmit}>
                                        <Row>
                                            <Col lg={10}>
                                                <BootstrapCard>
                                                    <CardHeader>
                                                        <Row>
                                                            <Col>4 DAGEN</Col>
                                                            <Col>8 DAGEN</Col>
                                                            <Col>15 DAGEN</Col>
                                                            <Col>VERZORGING</Col>
                                                        </Row>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <Row className="d-flex align-items-center mb-4">
                                                            <Col>
                                                                <Input
                                                                    tag={Field}
                                                                    type="number"
                                                                    id="formula_price_4_lg"
                                                                    name="formula_price_4_lg"
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <Input
                                                                    tag={Field}
                                                                    type="number"
                                                                    id="formula_price_8_lg"
                                                                    name="formula_price_8_lg"
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <Input
                                                                    tag={Field}
                                                                    type="number"
                                                                    id="formula_price_15_lg"
                                                                    name="formula_price_15_lg"
                                                                />
                                                            </Col>
                                                            <Col
                                                                style={{
                                                                    whiteSpace: 'nowrap',
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                }}
                                                            >
                                                                Logies
                                                            </Col>
                                                        </Row>
                                                        <Row className="d-flex align-items-center mb-4">
                                                            <Col>
                                                                <Input
                                                                    tag={Field}
                                                                    type="number"
                                                                    id="formula_price_4_hp"
                                                                    name="formula_price_4_hp"
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <Input
                                                                    tag={Field}
                                                                    type="number"
                                                                    id="formula_price_8_hp"
                                                                    name="formula_price_8_hp"
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <Input
                                                                    tag={Field}
                                                                    type="number"
                                                                    id="formula_price_15_hp"
                                                                    name="formula_price_15_hp"
                                                                />
                                                            </Col>
                                                            <Col
                                                                style={{
                                                                    whiteSpace: 'nowrap',
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                }}
                                                            >
                                                                Halfpension
                                                            </Col>
                                                        </Row>
                                                        <Row className="d-flex align-items-center">
                                                            <Col>
                                                                <Input
                                                                    tag={Field}
                                                                    type="number"
                                                                    id="formula_price_4_ai"
                                                                    name="formula_price_4_ai"
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <Input
                                                                    tag={Field}
                                                                    type="number"
                                                                    id="formula_price_8_ai"
                                                                    name="formula_price_8_ai"
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <Input
                                                                    tag={Field}
                                                                    type="number"
                                                                    id="formula_price_15_ai"
                                                                    name="formula_price_15_ai"
                                                                />
                                                            </Col>
                                                            <Col
                                                                style={{
                                                                    whiteSpace: 'nowrap',
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                }}
                                                            >
                                                                All inclusive
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </BootstrapCard>
                                            </Col>
                                        </Row>
                                        <Row className="mt-4">
                                            <Col lg={3}>
                                                <FormGroup>
                                                    <Label for="discount" size="sm">
                                                        Korting (euro p.p.)
                                                    </Label>
                                                    <Input
                                                        tag={Field}
                                                        type="number"
                                                        id="discount"
                                                        name="discount"
                                                        ref={discount_input}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg={3}>
                                                <FormGroup>
                                                    <Label for="discount_expire" size="sm">
                                                        Einddatum (t/m)
                                                    </Label>
                                                    <Input
                                                        tag={Field}
                                                        type="date"
                                                        id="discount_expire"
                                                        name="discount_expire"
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col lg={2.5}>
                                                <FormGroup>
                                                    <Label for="discount_expire" size="sm">
                                                        Handmatige prijzen
                                                    </Label>
                                                    <br />
                                                    <Button
                                                        name="handmatig-toevoegen"
                                                        outline
                                                        color="secondary"
                                                        onClick={() => setSelectedPrice(createInitialPrice(values.id))}
                                                    >
                                                        <FontAwesomeIcon icon="plus-circle" className="mr-2" />
                                                        Toevoegen
                                                    </Button>
                                                </FormGroup>
                                            </Col>

                                            <ManuallyPriceModal
                                                slug={initialData.slug}
                                                price={selectedPrice}
                                                providers={providers}
                                                airports={airports}
                                                onClose={onClose}
                                                addManuallyPrice={(price) => addManuallyPrice(price, handleChange, values)}
                                                add
                                                dealId={initialData.id}
                                                publishedDealId={values?.published_deal?.id}
                                            />
                                        </Row>

                                        <Mutation mutation={DELETE_PRICE} onError={onPricesError}
                                                  refetchQueries={() => {
                                                      return [
                                                          {
                                                              query: GET_PRICES,
                                                              variables: { dealId: values?.published_deal?.id, manual_price: true },
                                                          }
                                                      ];
                                                  }}
                                            onCompleted={(event) => onCompletionDeletePrice(event, handleChange, values)}
                                            >
                                            {deletePrice => (
                                                <Row className="mt-4">
                                                    <Col lg={12}>
                                                        <p>Handmatige prijzen</p>
                                                    </Col>
                                                    <Query fetchPolicy="cache-and-network" query={GET_PRICES} variables={{
                                                        dealId: values?.published_deal?.id,
                                                        manual_price: true
                                                    }}>
                                                        {({ loading, error, data }) => {
                                                            if (loading) return <p>laden ...</p>;
                                                            if (error) return <p>er ging iets mis</p>;
                                                            if (data && data.getPrices.prices.length === 0) return <Col
                                                                xs={12}><p>Geen uitgelichte prijs gevonden</p></Col>;
                                                            if (data && data.getPrices.prices) {
                                                                return data.getPrices.prices.map(price => (
                                                                    <ManuallyPrice
                                                                        key={price.id}
                                                                        onClickEdit={price => setSelectedPrice(formatPrice(price))}
                                                                        price={price}
                                                                        handleDeletePrice={(dealId) => deletePrice({ variables: { id: dealId, dealId: values.published_deal.id } })}
                                                                        isEditable={true}
                                                                    />
                                                                ));
                                                            }
                                                        }}
                                                    </Query>
                                                    {pricesError && <Alert color="danger">{pricesError}</Alert>}
                                                    {/*{ (values.prices && values.prices.length > 0) ? getManualPrices(values.prices).map(price => (*/}
                                                    {/*    <ManuallyPrice*/}
                                                    {/*      key={price.id}*/}
                                                    {/*      onClickEdit={price => setSelectedPrice(formatPrice(price))}*/}
                                                    {/*      price={price}*/}
                                                    {/*      // handleDeletePrice={(priceId) => deleteManualPrice(priceId, handleChange, values) }*/}
                                                    {/*      handleDeletePrice={(priceId) => deletePrice({variables: {id: priceId}}) }*/}
                                                    {/*      isEditable={true}*/}
                                                    {/*    />*/}
                                                    {/*  )) :*/}
                                                    {/*  (*/}
                                                    {/*    <p>Deze deal heeft nog geen</p>*/}
                                                    {/*  )*/}
                                                    {/*}*/}

                                                </Row>
                                            )}
                                        </Mutation>

                                        <hr />

                                        {/* <Row>
                      <Col xs={12}>
                        <p>Uitgelichte prijs</p>
                      </Col>

                      <Query query={GET_PRICES} variables={{ dealId: values.id, highlight: true }}>
                        {({ loading, error, data }) => {
                          if (loading) return <p>laden ...</p>;
                          if (error) return <p>er ging iets mis</p>;
                          if(data && data.prices.length < 1) return <Col xs={12}><p>Geen uitgelichte prijs gevonden</p></Col>;
                          if (data && data.prices) {
                            return data.prices.map(price => (
                              <ManuallyPrice
                                key={price.id}
                                onClickEdit={price => setSelectedPrice(formatPrice(price))}
                                price={price}
                              />
                            ));
                          }
                        }}
                      </Query>
                    </Row> */}
                                    </Form>
                                </Card>
                            );
                        }}
                    </Formik>
                )}
            </Mutation>
        );
    },
    areEqual,
);

function areEqual(prevProps, nextProps) {
    return JSON.stringify(prevProps.initialData) === JSON.stringify(nextProps.initialData);
}

DealPrices.propTypes = {
    initialData: PropTypes.object.isRequired,
    providers: PropTypes.array.isRequired,
    airports: PropTypes.array.isRequired,
    boardTypes: PropTypes.array.isRequired,
};

export default DealPrices;
