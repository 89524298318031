export function formatForUpdate(values) {
  return {
    variables: {
      id: values.id !== '' ? values.id : null,
      name: values.name,
      description: values.description,
      parent: values.parent ? values.parent.value : null,
      type: 'city',
      latitude: values.latitude,
      longitude: values.longitude 
    },
  };
}
