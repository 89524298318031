import { ApolloClient } from 'apollo-client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import auth from "utils/auth";
import {concat} from "apollo-link";
const baseUrl = process.env.REACT_APP_BASE_URL;

const isInvalidToken = (errors) => {
  const invalidToken = errors.filter(error => error.message === 'Invalid token.' )
  return invalidToken.length;
}

const uploadLink = createUploadLink({
  uri: `${baseUrl}/graphql`,
});

const logoutLink = onError((error) => {
  // const errors = error.response.errors;

  if(error.response && error.response.errors && isInvalidToken(error.response.errors)){
    localStorage.setItem('isTokenExpired', "true")
  }
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the contexts so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

// Disable cache so it reflects the updates
// If you're just displaying data and not mutating them you can remove it
// to increase performances
const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'cache',
    errorPolicy: 'all',
  },
};

const client = new ApolloClient({
  link: logoutLink.concat(concat(authLink, uploadLink)),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});

export default client;
