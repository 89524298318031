import React, { useState, memo } from 'react';
import IntroIcon from 'assets/icons/intro-icon.svg';
import { Mutation } from 'react-apollo';
import { UPDATE_DEAL } from 'queries/deals';
import RichTextEditor from 'components/RichTextEditor';
import { formatForUpdate } from './helpers';
import PropTypes from 'prop-types';

// Components
import Card from 'components/Card';
import { Form, Formik } from 'formik';
import { Col, Row } from 'reactstrap';
import validation from './validations';

const DealIntroduction = memo(({ initialData, isValid, validValues }) => {
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  function handeUpdateCompletion() {
    setSuccessMessage('Introductie is succesvol geupdatet');
    setTimeout(() => {
      setSuccessMessage(null);
    }, 3000);
  }

  function handleError() {
    setErrorMessage('Er ging iets niet helemaal lekker!');
  }

  const validate = values => {
    try {
      validation.validateSync(values, { abortEarly: false });
      isValid(true);
      validValues(formatForUpdate(values));
      return true;
    } catch (error) {
      isValid(false);
      return false;
    }
  };

  return (
    <Mutation mutation={UPDATE_DEAL} onCompleted={handeUpdateCompletion} onError={handleError}>
      {updateDeal => (
        <Formik
          enableReinitialize={false}
          initialValues={initialData}
          onSubmit={(values, { setValues }) => {
            updateDeal({ variables: formatForUpdate(values) });
          }}
          validationSchema={validation}
          validate={errors => validate(errors)}
        >
          {props => {
            const { values, errors, handleChange, handleBlur, handleSubmit, touched, setError } = props;
            return (
              <Card
                disabled={Object.keys(errors).length > 0}
                hideSaveButton={Object.keys(errors).length > 0}
                title="Introductie"
                subTitle={`${values.destinationParent} | ${
                  values.destination ? values.destination : 'nog geen bestemming'
                }`}
                thumbnailSrc={IntroIcon}
                errorMessage={errorMessage}
                successMessage={successMessage}
                uniqueId="introductie"
                onSave={() => handleSubmit()}
              >
                <Form onSubmit={handleSubmit}>
                  <Row form>
                    <Col>
                      <RichTextEditor
                        title
                        tags
                        errors={errors}
                        values={values}
                        handleChange={handleChange}
                        handleLinks={(links) => links.map(link => {
                          if (link.match(/^(https?:\/\/)?(www)?.?skyscanner.nl/gm)) {
                            return 'https://skyscanner.pxf.io/c/2999388/1027991/13416?associateid=AFF_TRA_19354_00001&u=' + link;
                          }

                          return link;
                        })}
                        // handleBlur={handleBlur} //THIS NEEDS TO BE FIXED
                        touched={touched}
                        setError={setError}
                      />
                    </Col>
                  </Row>
                </Form>
              </Card>
            );
          }}
        </Formik>
      )}
    </Mutation>
  );
}, areEqual);

function areEqual(prevProps, nextProps) {
  return JSON.stringify(prevProps.initialData) === JSON.stringify(nextProps.initialData);
}

DealIntroduction.propTypes = {
  initialData: PropTypes.object.isRequired,
};

export default DealIntroduction;
