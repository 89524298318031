import React, { useState, useEffect } from 'react';
import { Query } from 'react-apollo';
import { FILTER_DESTINATIONS } from './apollo';
import { Row, Col } from 'reactstrap';
import Table from 'components/Table';
import Search from 'components/Search';

const defaultSearchQuery = {
  where: {
    name_contains: '',
    type_in: ['region', 'country', 'city', 'continent'],
  },
  start: 0,
  limit: 50,
};

const Destinations = ({ filterQuery, trigger }) => {
  const [searchQuery, setSearchQuery] = useState(defaultSearchQuery);
  const [pageNumber, setPageNumber] = useState(1);
  let destinationCount = 0;

  let refetchf = null;

  useEffect(() => {
    refetchf();
  }, [trigger])

  useEffect(() => {
    let newWhere = { ...searchQuery.where};
    newWhere.type_in = filterQuery.where.type_in;
    setSearchQuery({ ...searchQuery, where: newWhere});
  }, [filterQuery]);

  function handleNewSearchTextValue(value) {
    const newSearchQuery = { ...searchQuery, where: {...searchQuery.where, name_contains: value}, start: 0 };
    if(pageNumber !== 1) setPageNumber(1);
    setSearchQuery(newSearchQuery);
  }

  function hanlePaginationChange(type) {
    let newSearchQuery = { ...searchQuery };
    if (type === 'next') {
      setPageNumber(pageNumber + 1);
      newSearchQuery.start = newSearchQuery.start + newSearchQuery.limit;
    } else {
      setPageNumber(pageNumber - 1);
      newSearchQuery.start = newSearchQuery.start - newSearchQuery.limit;
    }
    setSearchQuery(newSearchQuery);
  }



  return (
    <Row className="d-flex justify-content-center">
      <Col lg={8} className="mb-5">
        <Search
          handleNewSearchTextValue={handleNewSearchTextValue}
          isUsedInDeals={false}
          placeholder="Zoeken op bestemmingsnaam"
        />
      </Col>

      <Col lg={12}>
        <Query fetchPolicy="no-cache" query={FILTER_DESTINATIONS} variables={searchQuery}>
          {({ loading, error, data, refetch }) => {
            refetchf = refetch
            if (error) return `Error! ${error.message}`;
            if (data) {
              if (data.destinationsConnection) {
                destinationCount = data.destinationsConnection.aggregate.count;
              }

              if (data && data.destinations) {
                return (
                  <Table
                    title="Destinations"
                    data={data.destinations}
                    loading={loading}
                    url="/content/destinations"
                    paginationOnClickPrevious={() => hanlePaginationChange('previous')}
                    paginationOnClickNext={() => hanlePaginationChange('next')}
                    start={searchQuery.start}
                    limit={searchQuery.limit}
                    pageNumber={pageNumber}
                    total={destinationCount}
                    disablePrevious={Boolean(searchQuery.start === 0)}
                    disableNext={Boolean(
                      searchQuery.start > destinationCount - searchQuery.limit ||
                        searchQuery.limit >= destinationCount,
                    )}
                    pagination={true}
                  />
                );
              }
            }
            return <p></p>;
          }}
        </Query>
      </Col>
    </Row>
  );
};

export default Destinations;
