import gql from "graphql-tag";

export const GET_RSS_COLUMNS = gql`
    query rssColumns($where: JSON!) {
        rsscolumns(where: $where) {
            id
            type
            website{
                domain
            }
            column_deals {
                id
                deal {
                    title
                    id
                    affiliate_url
                    slug
                }
                alt
                selected_media
                title
                description
                url
                direct_link
                image{
                    id
                    url
                    thumb
                }
                button_text
            }
        }
    }
`;
