import styled from 'styled-components';
import { setFont, setRem, color } from 'styles/styles';

const borderRadius = '4px';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  border-radius: ${borderRadius};
  display: flex;
  flex-direction: column;
  border: 1px solid ${color.borderGrey};
`;

export const Icon = styled.button`
  border: none;
  position: absolute;
  padding: ${setRem(20)} ${setRem(20)};
  ${props => `border-top-${props.position}-radius`}: ${borderRadius};
  ${props => props.position}: 0;
  top: 0;
  /* background-color: ${color.primary}; */
  color: #B7B7B7 !important;
  border: 1px solid ${color.borderGrey};
  background-color: white;
`;

export const Title = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px 10px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${setRem(20)} 0;
`;

export const Url = styled.div`
  font-size: ${setRem(14)};
`;

export const Img = styled.img`
  background-image: url(${props => props.imgsrc});
  //position: relative;
  float: left;
  width: 100%;
  height: 200px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: ${borderRadius};
  border-top-right-radius: ${borderRadius};
`;

export const Button = styled.button`
  position: absolute;
  padding: ${setRem(40)} ${setRem(40)}
  left: 0px;
  top: 0px;
  ${setFont.main};
  font-size: 20px;
  color: ${color.mainGrey};
  text-align: center;
  letter-spacing: 1.5px;
  text-transform: uppercase;

  background: #ffffff;
  border: 2px solid #f5f5f5;
  border-radius: 4px 0px 4px 0px;
`;
