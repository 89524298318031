import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: row wrap;

  & > p:after {
    margin-left: 6px;
    content: '|';
  }

  & > p:last-of-type:after {
    margin-left: 6px;
    content: '';
  }

  & > p {
    margin-left: 6px;
  }
`;
