import moment from "moment";

export const createSearchWhereQuery = (params, searchQuery ) => {
  return {
    ...searchQuery,
    where: { ...searchQuery.where, ...params },
  };
};

export const getConceptParams = () => createParams(true, null, null, null);

export const getAllParams = () => createParams(null, null, null, null);

export const getPublishedParams = () => {
  const today = getTodayDate();
  return createParams(null, true, today, null);
}

export const getExpiredParams = () => {
  const today = getTodayDate();
  return createParams(null, true, null, today);
};

export const getTodayDate = () => {
  const todayWithoutHours = moment().format('YYYY-MM-DD');
  return `${todayWithoutHours}T00:00:01`;
};

export const createParams = (published_ne = null, published = null, expire_date_gte = null, expire_date_lt = null) => {
  let filters = [];
  if(published_ne !== null) filters.published_ne = published_ne;
  if(published !== null) filters.published = published;
  if(expire_date_gte !== null) filters.expire_date_gte = expire_date_gte;
  if(expire_date_lt !== null) filters.expire_date_lt = expire_date_lt;
  return filters;
};
