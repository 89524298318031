export function formatForUpdate(values) {
  return {
    id: values.id,
    title: values.title.trim(),
    subtitle: values.subtitle.trim(),
    intro: replaceAll(values.intro.trim(), '<a', '<a target="_blank"'),
    additional_html: values.additional_html
  };
}

export const initialIntro = {
  title: '',
  intro: '',
};

function replaceAll(str, find, replace) {
  return str.replace(new RegExp(find, 'g'), replace);
}
