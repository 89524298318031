import styled from 'styled-components';

export const StyledImage = styled.img`
  border-radius: 8px;
  width: 100%;
  max-height: ${props => props.big ? '250px' : '150px'};
  
  &:hover{
    cursor: pointer;
  }
`;



