import React from 'react';
// import { Col, ListGroup, ListGroupItem, FormGroup, Label, Input, Spinner } from 'reactstrap';
import { Col, FormGroup, Label, Input} from 'reactstrap';
// import { Query } from 'react-apollo';
// import { GET_COUNTRIES, GET_DESTINATION_CHILDREN } from 'queries/deals';
// import ExpandableListGroupItem from 'components/ExpandableListGroupItem';
import { withRouter } from 'react-router-dom';

const DestinationsFilters = ({ filterQuery, handleFilterQueryChange, history }) => {
  // const [numberOfCountriesShowing, setNumberOfCountriesShowing] = useState(6);
  // const [idOfOpenListGroup, setIdOfOpenListGroup] = useState(null);
  // const [idOfOpenSecondListGroup, setIdOfOpenSecondListGroup] = useState(null);

  function handleCheckboxChange(event) {
    let value = event.target.value;
    let newSearchQuery = { ...filterQuery.where };
    if (newSearchQuery.type_in.length === 4) {
      newSearchQuery.type_in = [];
    }
    if (event.target.checked) {
      if (!newSearchQuery.type_in) {
        newSearchQuery.type_in = [];
      }
      newSearchQuery.type_in.push(value);
    } else {
      const index = newSearchQuery.type_in.indexOf(value);
      if (index > -1) {
        newSearchQuery.type_in.splice(index, 1);
      }
    }
    if (newSearchQuery.type_in) {
      if (newSearchQuery.type_in.length === 0) {
        newSearchQuery.type_in = ['region', 'country', 'city', 'continent'];
      }
    }
    handleFilterQueryChange(newSearchQuery);
  }


  // function onClickDestination(id, level = 1) {
  //   switch (level) {
  //     case 1:
  //       if (id === idOfOpenListGroup) {
  //         history.push(`/content/destinations/${id}`);
  //       }
  //       setIdOfOpenListGroup(id);
  //       setIdOfOpenSecondListGroup(null);
  //       break;
  //     case 2:
  //       if (id === idOfOpenSecondListGroup) {
  //         history.push(`/content/destinations/${id}`);
  //       }
  //       setIdOfOpenSecondListGroup(id);
  //       break;
  //
  //     case 3:
  //       history.push(`/content/destinations/${id}`);
  //       break;
  //     default:
  //       break;
  //   }
  //   let newSearchQuery = { ...filterQuery };
  //   newSearchQuery.parent_id = id !== '' ? id : null;
  //   handleFilterQueryChange(newSearchQuery);
  // }

  // function handleOnClickShowMore() {
  //   setNumberOfCountriesShowing(numberOfCountriesShowing + numberOfCountriesShowing);
  // }

  return (
    <>
      {/* <Col className="pt-4"> */}
        {/*<div className="px-5">*/}
        {/*  <h4 className="mb-4">Filter bestemmingen</h4>*/}
        {/*  <h6>BESTEMMINGEN</h6>*/}
        {/*</div>*/}
        {/*<ListGroup className="expandable-list-group mb-0">*/}
        {/*  <Query query={GET_COUNTRIES} variables={{ where: { type_eq: 'country' } }}>*/}
        {/*    {({ loading, error, data }) => {*/}
        {/*      if (error) return `Error! ${error.message}`;*/}
        {/*      return (*/}
        {/*        data &&*/}
        {/*        !loading &&*/}
        {/*        data.destinations.map((destination1, index) => (*/}
        {/*          <React.Fragment key={index}>*/}
        {/*            {index <= numberOfCountriesShowing && (*/}
        {/*              <ExpandableListGroupItem*/}
        {/*                id={destination1.id}*/}
        {/*                text={destination1.name}*/}
        {/*                key={index}*/}
        {/*                isFirstListGroup={true}*/}
        {/*                open={Boolean(destination1.id === idOfOpenListGroup)}*/}
        {/*                handleOnClick={() => onClickDestination(destination1.id)}*/}
        {/*                isLoading={loading}*/}
        {/*              >*/}
        {/*                <Query query={GET_DESTINATION_CHILDREN} variables={{ where: {parent: destination1.id} }}>*/}
        {/*                  {({ loading, error, data }) => {*/}
        {/*                    if (error) return `Error! ${error.message}`;*/}
        {/*                    if (loading)*/}
        {/*                      return (*/}
        {/*                        <Spinner*/}
        {/*                          size="sm"*/}
        {/*                          color="primary"*/}
        {/*                          className="ml-2"*/}
        {/*                          style={{*/}
        {/*                            top: '17px',*/}
        {/*                            position: 'absolute',*/}
        {/*                            right: '20px',*/}
        {/*                            bottom: '0',*/}
        {/*                          }}*/}
        {/*                        />*/}
        {/*                      );*/}
        {/*                    return (*/}
        {/*                      data &&*/}
        {/*                      !loading &&*/}
        {/*                      data.destinations.map((destination2, index) => (*/}
        {/*                        <ExpandableListGroupItem*/}
        {/*                          id={destination2.id}*/}
        {/*                          text={destination2.name}*/}
        {/*                          key={index}*/}
        {/*                          handleOnClick={() => onClickDestination(destination2.id, 2)}*/}
        {/*                          open={Boolean(destination2.id === idOfOpenSecondListGroup)}*/}
        {/*                          // onClose={() => onOpen(destination1.id)}*/}
        {/*                          isLoading={loading}*/}
        {/*                        >*/}
        {/*                          <Query query={GET_DESTINATION_CHILDREN} variables={{ where: {parent: destination2.id} }}>*/}
        {/*                            {({ loading, error, data }) => {*/}
        {/*                              if (error) return `Error! ${error.message}`;*/}
        {/*                              if (loading)*/}
        {/*                                return (*/}
        {/*                                  <Spinner*/}
        {/*                                    size="sm"*/}
        {/*                                    color="primary"*/}
        {/*                                    className="ml-2"*/}
        {/*                                    style={{*/}
        {/*                                      top: '17px',*/}
        {/*                                      position: 'absolute',*/}
        {/*                                      right: '20px',*/}
        {/*                                      bottom: '0',*/}
        {/*                                    }}*/}
        {/*                                  />*/}
        {/*                                );*/}
        {/*                              return (*/}
        {/*                                data &&*/}
        {/*                                data.destinations.map((destination3, index) => (*/}
        {/*                                  <ListGroup*/}
        {/*                                    className="expandable-list-group last-expandable-list-group mb-0"*/}
        {/*                                    key={index}*/}
        {/*                                  >*/}
        {/*                                    <ListGroupItem*/}
        {/*                                      onClick={() => onClickDestination(destination3.id, 3)}*/}
        {/*                                    >*/}
        {/*                                      <span>{destination3.name}</span>*/}
        {/*                                    </ListGroupItem>*/}
        {/*                                  </ListGroup>*/}
        {/*                                ))*/}
        {/*                              );*/}
        {/*                            }}*/}
        {/*                          </Query>*/}
        {/*                        </ExpandableListGroupItem>*/}
        {/*                      ))*/}
        {/*                    );*/}
        {/*                  }}*/}
        {/*                </Query>*/}
        {/*              </ExpandableListGroupItem>*/}
        {/*            )}*/}

        {/*            {index === numberOfCountriesShowing && (*/}
        {/*              <div className="px-5">*/}
        {/*                <u onClick={handleOnClickShowMore}>Toon meer</u>*/}
        {/*              </div>*/}
        {/*            )}*/}
        {/*          </React.Fragment>*/}
        {/*        ))*/}
        {/*      );*/}
        {/*    }}*/}
        {/*  </Query>*/}
        {/*</ListGroup>*/}
        {/*<hr />*/}
      {/* </Col> */}
      <Col className="px-5 pt-3">
        <div>
          <h6 className="mb-3">FILTER SOORT BESTEMMINGEN</h6>
        </div>
        <FormGroup check>
          <Label check>
            <Input type="checkbox" value="continent" onChange={e => handleCheckboxChange(e)} />{' '}
            Continenten
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input type="checkbox" value="country" onChange={e => handleCheckboxChange(e)} /> Landen
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input type="checkbox" value="region" onChange={e => handleCheckboxChange(e)} /> Regio's
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input type="checkbox" value="city" onChange={e => handleCheckboxChange(e)} /> Steden
          </Label>
        </FormGroup>
      </Col>
    </>
  );
};

export default withRouter(DestinationsFilters);
