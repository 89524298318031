import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Col, Spinner, FormGroup, Label, Input, Alert } from 'reactstrap';
import {StyledImage} from "./UploadImagesCSS";

import Dropzone from 'react-dropzone';
import DealImg from 'components/DealImg';
import axios from 'axios';
import { createEventTarget } from 'utils/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImport } from '@fortawesome/free-solid-svg-icons';
const baseUrl = process.env.REACT_APP_BASE_URL;

function checkIfDuplicateExists(w) {
  return new Set(w).size !== w.length;
}

const extractImagesFromResponse = (uploadedImages) => {
    return uploadedImages.map(image => image.data[0])
};

const UploadImages = ({ handleSelect, type }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  function onDrop(acceptedFiles) {
    const result = acceptedFiles.map(localFile => {
      const localImageUrl = URL.createObjectURL(localFile);
      return {
        name: localFile.name
          .split('.')
          .slice(0, -1)
          .join('.')
          .replace(/_/g, ' ')
          .replace(/-/g, ' '),
        localImageUrl,
        localFile,
      };
    });
    handleImageUpload(result);
  }

  function createPromises(files, token) {
    return files.map(file => {
      let formData = new FormData();
      formData.append('files', file);
      formData.append(
        'alt',
        file.name
          .split('.')
          .slice(0, -1)
          .join('.')
          .replace(/_/g, ' ')
          .replace(/-/g, ' '),
      );
      formData.append('type', type ? type : 'other');
      return axios({
        url: `${baseUrl}/upload`,
        method: 'POST',
        headers: {
          authorization: `Bearer ${token}`,
        },
        data: formData,
      });
    });
  }

  async function handleImageUpload(toBeUploadedImages) {
    const token = localStorage.getItem('token');
    const files = toBeUploadedImages.map(image => image.localFile);

    const promises = createPromises(files, token);
    setIsUploading(true);
    const results = await Promise.all(promises);

    const formattedImages = extractImagesFromResponse(results);

    setUploadedFiles([...uploadedFiles, ...formattedImages]);

    setIsUploading(false);
  }


  return (
    <>
      <Row>
        <Col>
          <h6>Geuploade foto's ({uploadedFiles.length})</h6>

        </Col>
      </Row>
      <Row>
        {(uploadedFiles && uploadedFiles.length > 0) && uploadedFiles.map(image => (
            <Col md={3}>
              <StyledImage onClick={() => handleSelect(image)} src={image.thumb} />
            </Col>
        ))}
      </Row>
      <br/>
      <Row>
        <Col>
          <Dropzone onDrop={acceptedFiles => onDrop(acceptedFiles)}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div
                  style={{
                    padding: '2rem 3rem',
                    border: 'dashed 2px rgba(0, 0, 0, 0.24)',
                    textAlign: 'center',
                  }}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <FontAwesomeIcon
                    icon={faFileImport}
                    size="3x"
                    className="mx-auto mb-3"
                    style={{ color: '#6c757d' }}
                  />
                  <p className="mb-0">Sleep een afbeelding hier naar toe, of klik op mij!</p>
                </div>
              </section>
            )}
          </Dropzone>
        </Col>
      </Row>
      <br />
      {isUploading && <Spinner color="primary" />}
    </>
  );
};

UploadImages.propTypes = {
  type: PropTypes.oneOf(['accommodation', 'destination', 'booking_example']),
  handleSelect: PropTypes.func,
};

export default UploadImages;
