import * as yup from 'yup';

const REQUIRED_FIELD = 'Verplicht veld';

export const validation = yup.object().shape({
 columnDeals: yup.array()
   .of(
     yup.object().shape({
       title: yup.string().required(REQUIRED_FIELD).max(60),
       description: yup.string().required(REQUIRED_FIELD).max(200),
       button_text: yup.string().required(REQUIRED_FIELD),
       direct_link: yup.string().required(REQUIRED_FIELD),
       image: yup.object().required(REQUIRED_FIELD),
     })
   )
});


export const TITLES = {
  top: 'Topdeal (boven)',
  right: 'Rechterkolom',
  bottom: 'Topdeal (onderaan)',
  left: 'Linkerkolom'
};

export const initialColumnDeal = {
  deal: null,
  description: '',
  direct_link: false,
  image: null,
  title: '',
  url: '',
  selectedMedia: [],
  button_text: 'Bekijk deal',
  initialVisible: true,
  alt: '',
};

export const formatInitialColumnDeal = ({id, deal, description, direct_link, image, title, url, button_text, selected_media, alt}) => {
  return {
    id,
    deal: deal ? { label: deal.title, value: deal.id, affiliate_url: deal.affiliate_url, slug: deal.slug } : null,
    description: description ? description : '',
    direct_link: direct_link,
    image: image ? image : null,
    title: title ? title : '',
    url: url ? url : '',
    // selectedMedia: ((deal && deal.selected_media && deal.selected_media.length > 0 ) || (deal && deal.selected_media && Object.entries(deal.selected_media).length > 0)) ? deal.selected_media : [],
    selectedMedia: selected_media && selected_media.length > 0 ? selected_media : [],
    button_text: button_text ? button_text : '',
    alt: alt ? alt : '',
  };
};

export const formatForUpdateColumnDeal = ({id, deal, description, direct_link, image, title, url, button_text, alt}) => {
  return {
    id,
    deal: deal ? deal.value: null,
    description: description ? description.trim() : null,
    direct_link: direct_link,
    image: image ? image.id : null,
    title: title ? title.trim() : null,
    url: url ? url.trim() : null,
    button_text: button_text.trim() ? button_text : null,
    alt: alt ? alt.trim() : null,
  };
};

export const formatInitialValues = (toFormatColumnDeals) => {
  const formattedColumnDeals = toFormatColumnDeals.map(columnDeal => formatInitialColumnDeal(columnDeal));
  return {
    columnDeals: formattedColumnDeals
  }
};

export const formatForUpdate = (rssColumnId, columnDeals ) => {
  const formattedColumnDeals = columnDeals.map(deal => formatForUpdateColumnDeal(deal));
  return {
    variables: {
      id: rssColumnId,
      column_deals: formattedColumnDeals
    }
  }
};
