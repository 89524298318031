import gql from 'graphql-tag';

export const DELETE_DEAL = gql`
  mutation deleteDeal($id: ID!) {
    deleteDealConcept(input: { where: { id: $id } }) {
      dealConcept {
        id
        title
      }
    }
  }
`;

export const GET_DEAL = gql`
  query deal($id: ID!) {
    dealConcept(id: $id) {
      id
      automatic
      title
      subtitle
      meta_title
      meta_description
      intro
      price
      days
      nights
      date
      persons
      slug
      hashed_slug
      show_on_homepage
      show_on_be
      automatic
      additional_html
      website{
        id
        country_code
      }
      providers {
        id
        name
      }
      arrival_airport {
        id
        name
      }
      departure_airport {
        id
        name
      }
      dealtypes(sort: "name:asc") {
        id
        name
      }
      booking_examples {
        id
        title
        url
        image {
          id
          url
        }
      }
      board_type {
        id
        name
      }
      dealproviders {
        id
        link
        provider {
          id
        }
      }
      providers {
        id
        name
      }
      notes
      including_flight
      including_transfer
      including_rentalcar
      including_accommodation
      including_train_travel
      accommodation {
        id
        name
      }
      selected_media
      media
      destination {
        id
        name
        parent {
          name
        }
      }
      affiliate_url
      publish_date
      expire_date
      alternative_date
      formula_price_4_lg
      formula_price_4_hp
      formula_price_4_ai
      formula_price_8_lg
      formula_price_8_hp
      formula_price_8_ai
      formula_price_15_lg
      formula_price_15_hp
      formula_price_15_ai
      discount
      discount_expire
      edited_by {
        email
        username
      }
      published_deal{
        id
      }
      createdAt
      updatedAt
      hide_partners
      published_deal{
        wordpress_id
      },
        prices {
            id
            date_from
            date_to
            persons
            night_count
            day_count
            departureairport {
                id
                name
                iata
            }
            arrival_airport {
                id
                name
                iata
            }
            board_type {
              id
              name
            }
            price
            provider {
                id
                name
            }
            manual_price
            expire_date
            transport_type
            highlight
        }

    }
    providers {
      id
      name
    }
    dealtypes(sort: "name:asc") {
      id
      name
    }
    boardtypes {
      id
      name
    }
    airports(where: { departure_airport: true }, sort: "name:asc") {
      id
      name
      iata
    }
  }
`;

export const UPDATE_DEAL = gql`
  mutation UpdateDeal(
    $id: ID!
    $title: String
    $subtitle: String
    $meta_title: String
    $meta_description: String
    $intro: String
    $price: Float
    $days: Int
    $nights: Int
    $date: DateTime
    $persons: Int
    $board_type: ID
    $departure_airport: ID
    $arrival_airport: ID
    $notes: String
    $dealtypes: [ID]
    $including_accommodation: Boolean
    $including_flight: Boolean
    $including_rentalcar: Boolean
    $including_transfer: Boolean
    $including_train_travel: Boolean
    $accommodation: ID
    $destination: ID
    $selected_media: JSON
    $publish_date: DateTime
    $expire_date: DateTime
    $alternative_date: String
    $booking_examples: [ID]
    $formula_price_4_lg: Float
    $formula_price_4_hp: Float
    $formula_price_4_ai: Float
    $formula_price_8_lg: Float
    $formula_price_8_hp: Float
    $formula_price_8_ai: Float
    $formula_price_15_lg: Float
    $formula_price_15_hp: Float
    $formula_price_15_ai: Float
    $discount: Int
    $discount_expire: DateTime
    $slug: String
    $show_on_homepage: Boolean
    $show_on_be: Boolean
    $hide_partners: Boolean
    $automatic: Boolean
    $affiliate_url: String
    $providers: [ID]
    $additional_html: String
  ) {
    updateDealConcept(
      input: {
        where: { id: $id }
        data: {
          title: $title
          subtitle: $subtitle
          meta_title: $meta_title
          meta_description: $meta_description
          intro: $intro
          price: $price
          days: $days
          nights: $nights
          date: $date
          persons: $persons
          board_type: $board_type
          departure_airport: $departure_airport
          arrival_airport: $arrival_airport
          notes: $notes
          dealtypes: $dealtypes
          including_accommodation: $including_accommodation
          including_flight: $including_flight
          including_rentalcar: $including_rentalcar
          including_transfer: $including_transfer
          including_train_travel: $including_train_travel
          accommodation: $accommodation
          destination: $destination
          selected_media: $selected_media
          publish_date: $publish_date
          expire_date: $expire_date
          alternative_date: $alternative_date
          booking_examples: $booking_examples
          formula_price_4_lg: $formula_price_4_lg
          formula_price_4_hp: $formula_price_4_hp
          formula_price_4_ai: $formula_price_4_ai
          formula_price_8_lg: $formula_price_8_lg
          formula_price_8_hp: $formula_price_8_hp
          formula_price_8_ai: $formula_price_8_ai
          formula_price_15_lg: $formula_price_15_lg
          formula_price_15_hp: $formula_price_15_hp
          formula_price_15_ai: $formula_price_15_ai
          discount: $discount
          discount_expire: $discount_expire
          slug: $slug
          show_on_homepage: $show_on_homepage
          show_on_be: $show_on_be
          hide_partners: $hide_partners
          automatic: $automatic
          affiliate_url: $affiliate_url
          is_saved: true
          providers: $providers
          additional_html: $additional_html
        }
      }
    ) {
      dealConcept {
        id
        title
      }
    }
  }
`;

export const PUBLISH_DEAL = gql`
  mutation publishDeal($id: ID!) {
    publishDeal(input: { where: { id: $id } }) {
      deal {
        id
        published
      }
    }
  }
`;

// export const PUBLISH_DEAL = gql`
//   mutation PublishDeal($id: ID!, $published: Boolean, $expire_date: DateTime) {
//     updateStatus(input: { where: { id: $id }, data: { published: $published, expire_date: $expire_date } }) {
//       deal {
//         id
//         status
//         published
//       }
//     }
//   }
// `;
