import * as Yup from 'yup';

const validation = Yup.object().shape({
  formula_price_4_lg: Yup.string(),
  formula_price_8_lg: Yup.string(),
  formula_price_15_lg: Yup.string(),
  formula_price_4_hp: Yup.string(),
  formula_price_8_hp: Yup.string(),
  formula_price_15_hp: Yup.string(),
  formula_price_4_ai: Yup.string(),
  formula_price_8_ai: Yup.string(),
  formula_price_15_ai: Yup.string(),
});

export default validation;
