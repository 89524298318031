import styled from 'styled-components';

export const StyledImage = styled.img`
  border-radius: 8px;
  width: 100%;
  max-height: ${props => props.big ? '250px' : '150px'};
  
  &:hover{
    cursor: pointer;
  }
`;

export const StyledSelectedImage = styled.div`
box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 300px;
  border-radius: 9px;
  background-image: url(${props => props.thumb});
  background-repeat: no-repeat;
  background-position: center center;
   background-size: cover;
    border: 1px solid #dadada;
}

  .icon{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    bottom: 0;
    border-radius: 0 0 8px 0;
    background-color: white;
    padding: 1.3rem 1.3rem;
    transition: all 200ms linear;
   
    
    &:hover{
cursor: pointer;
background: #dc504a;
color: #ffffff;
transition: all 200ms linear;
border-radius: 8px;
}
  }



`;
