import { createGlobalStyle } from 'styled-components';
import { color, setFont, setRem } from './styles';
const Globals = createGlobalStyle`
@import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i,900,900i&display=swap');

*{
 margin:0;
 padding:0;
 box-sizing:border-box;
}
body{
 font-size:100%;
 color:${color.mainBlack};
 background:${color.mainWhite};
 ${setFont.main};
 
}
h1{
 font-size:3em;line-height:1.2;margin-bottom:0.5em
}
h2{
 font-size:2em;margin-bottom:0.75em
}
h3{
 font-size:1.5em;line-height:1;margin-bottom:1em
}
h4{
 font-size:1.2em;line-height:1.2;margin-bottom:1.25em;font-weight:bold;
}
h5{
 font-size:1em;margin-bottom:1.5em;font-weight:bold;
}
h6{
 font-size:1em;font-weight:bold;
}
p{
 line-height:1.5;margin:0 0 1.5rem 0;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: ${color.mainBlack};
  font-size: ${setRem(14)};
  ${setFont.main}
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: red;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: red;
}

/* TODO: dit staat nu in global styles, maar dit zou eigenlijk in dealIntroduction/index.js moeten staan */
.ck-content{
  padding: 0px 30px !important;
  ul{
    margin-left: 20px !important;
  }
  ol{
    margin-left: 20px !important;
  }
}

`;

export default Globals;
