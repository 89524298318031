import React from 'react';
import { Container, Row, Col, Spinner } from 'reactstrap';
import { GET_RSS_COLUMNS } from './apollo';

import RssColumn from './RssColumn';
import UrlsRss from './UrlsRss';
import CampagneTracking from './CampagneTracking';
import { Query } from 'react-apollo';

const NewsLetter = () => {
  const websiteId = localStorage.getItem('country_id');
  return (
    <Container>
      <Query
        query={GET_RSS_COLUMNS}
        variables={{ where: { website: websiteId } }}
        fetchPolicy="no-cache"
      >
        {({ loading, error, data }) => {
          if (error) return <h1>Er is een probleem opgetreden</h1>;
          if (loading) {
            return (
              <Row>
                <Col className="d-flex justify-content-center mt-3" lg={{ size: 6, offset: 3 }}>
                  <Spinner type="grow" color="primary" />
                </Col>
              </Row>
            );
          }
          if (data && data.rsscolumns) {
            const { rsscolumns } = data;
            if (rsscolumns.length > 0) {
              return (
                <>
                  <CampagneTracking />
                  {rsscolumns.map(rssColumn => (
                    <RssColumn
                      key={rssColumn.id}
                      positionType={rssColumn.type}
                      columnDeals={rssColumn.column_deals}
                      rssColumnId={rssColumn.id}
                    />
                  ))}
                  <UrlsRss rssColumns={rsscolumns} />
                </>
              );
            }
          }
          return <p>Er zijn nog geen 'columns' aangemakt, maak deze eerst aan in Strapi</p>;
        }}
      </Query>
    </Container>
  );
};

export default NewsLetter;
