import gql from 'graphql-tag';

export const GET_CURRENT_USER = gql`
  query me {
    me {
      username
      avatar {
        url
      }
    }
  }
`;

export const GET_WEBSITES = gql`
query Websites{
  websites{
    id
    country_code
  }
}`;
