import React, { useState } from 'react';

//Components
import { Button, Row, Col, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { StyledImage, StyledSelectedImage } from './ImageSelectorCSS';
import UploadImages from 'components/UploadImages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ImageSelector = ({ handleImageChange, selectedMedia, selectedImage }) => {
  const [isModalVisible, setisModalVisible] = useState(false);

  const toggleModal = () => setisModalVisible(!isModalVisible);

  return (
    <>
      {selectedImage && selectedImage.thumb ? (
        <Row>
            <Col>
                <StyledSelectedImage thumb={selectedImage.thumb}>
                    <div onClick={toggleModal} className="icon">
                        <FontAwesomeIcon icon="edit" />
                    </div>
                </StyledSelectedImage>
            </Col>
        </Row>
      ) :
      (
        <Row style={{marginTop: '15px'}}>
          <Col>
            <Button onClick={toggleModal}>Selecteer een afbeelding</Button>
          </Col>
        </Row>
      )}

      <>
        <Modal isOpen={isModalVisible} toggle={toggleModal} size="xl">
          <ModalHeader toggle={toggleModal}>Selecteer een afbeelding</ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <h6>Deal foto's ({selectedMedia.length})</h6>
              </Col>
            </Row>
            <Row>
              {selectedMedia &&
                selectedMedia.length > 0 &&
                selectedMedia.map(media => (
                  <>
                    <Col
                      key={media.id}
                      md={4}
                      lg={3}
                      onClick={() => {
                        handleImageChange(media);
                        toggleModal();
                      }}
                    >
                      <StyledImage src={media.thumb} alt={media.thumb} />
                    </Col>
                  </>
                ))}
            </Row>
            <br />
            <hr />
            <br />
            <Row>
              <Col>
                <UploadImages
                  type="newsletter"
                  handleSelect={image => {
                    handleImageChange(image);
                    toggleModal();
                  }}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="warning" onClick={toggleModal}>
              sluiten
            </Button>{' '}
          </ModalFooter>
        </Modal>
      </>
    </>
  );
};

export default ImageSelector;
