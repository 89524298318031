import React, { useState } from 'react';
import Card from 'components/Card';
import DealImg from 'components/DealImg';
import ImagesModal from 'components/ImagesModal';
import ImagesIcon from 'assets/icons/images-icon.svg';
import { Col, Row, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { Mutation } from 'react-apollo';
import { UPDATE_DEAL } from 'queries/deals';
import DragSortableList from 'react-drag-sortable';
import './style.css';
import { Formik } from 'formik';
import { FormGroup, Label } from 'reactstrap';

const DealImages = ({ initialData, validValues, accommodationId, destinationId }) => {
  const [accommodationImagesModalOpen, setAccommodationImagesModalOpen] = useState(false);
  const [destinationImagesModalOpen, setDestinationImagesModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  let sortableImages = [];

  function makeItemsFromValues(values, handleChange) {
    return values.map((image, index) => {
      return {
        content: (
          <DealImg
            src={`${image.thumb}`}
            handleOnClickDelete={() => handleOnClickDelete(index, values, handleChange)}
            key={index}
          />
        ),
        id: image.id,
        thumb: image.thumb,
      };
    });
  }

  if (initialData && initialData.selected_media) {
    initialData.selected_media.forEach((image, index) => {
      if (image) {
        sortableImages.push({
          content: (
              <>
                <DealImg
                    src={`${image.url}`}
                    handleOnClickDelete={() => handleOnClickDelete(index)}
                    key={index}
                />
              </>
          ),
          id: image.id,
          thumb: image.thumb,
        });
      }
    });
  }

  function onSort(sortedImages, handleChange) {
    handleChange({
      target: {
        name: 'selected_media',
        value: sortedImages,
      },
    });
    validate(sortedImages);
  }

  function handleOnClickDelete(index, values, handleChange) {
    let newMediaSelected = [...values];
    newMediaSelected[index].selected = false;
    newMediaSelected.splice(index, 1);
    const event = {
      target: {
        name: 'selected_media',
        value: newMediaSelected,
      },
    };
    handleChange(event);
    validate(newMediaSelected);
  }

  function toggleAccommodationImagesModal() {
    setAccommodationImagesModalOpen(!accommodationImagesModalOpen);
  }

  function toggleDestinationImagesModal() {
    setDestinationImagesModalOpen(!destinationImagesModalOpen);
  }

  function handleOnClickAddImages(images, handleChange, values) {
    setAccommodationImagesModalOpen(false);
    setDestinationImagesModalOpen(false);

    let newMediaSelected = [...values.selected_media];
    newMediaSelected = newMediaSelected.concat(images);

    const event = {
      target: {
        name: 'selected_media',
        value: newMediaSelected,
      },
    };
    handleChange(event);
    validate(newMediaSelected);
  }

  function formatForUpdate(values) {
    let mediaSelected = [];
    values.selected_media.forEach((media, index) => {
      let newMedia = { id: media.id, order: index };
      mediaSelected.push(newMedia);
    });
    return {
      variables: {
        id: values.id,
        selected_media: mediaSelected,
      },
    };
  }

  function handleCompletion() {
    setSuccessMessage("Dealfoto's zijn succesvol opgeslagen");
    setTimeout(() => setSuccessMessage(null), 3000);
  }

  function handleError() {
    setErrorMessage('Oeps, er ging iets mis');
  }

  function validate(values) {
    let mediaSelected = [];
    values.forEach((media, index) => {
      let newMedia = { id: media.id, order: index };
      mediaSelected.push(newMedia);
    });
    validValues({ selected_media: mediaSelected });
  }

  return (
    <Mutation mutation={UPDATE_DEAL} onCompleted={handleCompletion} onError={handleError}>
      {updateDeal => (
        <Formik
          enableReinitialize={true}
          initialValues={initialData}
          onSubmit={(values, { setValues }) => {
            updateDeal(formatForUpdate(values));
          }}
        >
          {props => {
            const { values, handleChange } = props;
            return (
              <Card
                title="Fotos"
                subTitle={`${values.selected_media ? values.selected_media.length : '0'} fotos`}
                thumbnailSrc={ImagesIcon}
                onSave={() => updateDeal(formatForUpdate(values))}
                successMessage={successMessage}
                errorMessage={errorMessage}
                uniqueId="fotos"
                disabled={false}
              >
                <Row className="mb-4 d-flex align-items-center">
                  <Col className="d-none d-md-block align-items-center" lg={6}>
                    <FormGroup className="mb-0">
                      <Label className="mb-0">Dealfoto's</Label>
                    </FormGroup>
                  </Col>
                  <Col lg={6} className="mt-2 mt-md-0 d-flex justify-content-end">
                    <Button
                      outline
                      size="sm"
                      type="button"
                      className="mr-2 mb-2 mb-md-0"
                      onClick={toggleAccommodationImagesModal}
                    >
                      <FontAwesomeIcon icon={faPlusCircle} className="mr-2" /> Accommodatiefoto’s
                    </Button>
                    <Button
                      outline
                      size="sm"
                      type="button"
                      onClick={toggleDestinationImagesModal}
                      style={{ marginRight: '19px' }}
                    >
                      <FontAwesomeIcon icon={faPlusCircle} className="mr-2" /> Bestemmingsfoto's
                    </Button>
                  </Col>
                </Row>
                <Row className="d-flex">
                  <Col lg="12" className="d-flex">
                    <DragSortableList
                      items={makeItemsFromValues(values.selected_media, handleChange)}
                      onSort={items => onSort(items, handleChange)}
                      type="grid"
                      className="d-flex"
                    />
                  </Col>
                </Row>
                <ImagesModal
                  isOpen={accommodationImagesModalOpen}
                  title="Accommodatiefoto’s Selecteren"
                  toggle={toggleAccommodationImagesModal}
                  handleOnClickAddImages={images =>
                    handleOnClickAddImages(images, handleChange, values)
                  }
                  imgSrc="accommodation"
                  values={values}
                  accommodationId={accommodationId ? accommodationId : null}
                  mediaSelected={values.selected_media}
                />
                <ImagesModal
                  isOpen={destinationImagesModalOpen}
                  title="Bestemmingfoto’s Selecteren"
                  toggle={toggleDestinationImagesModal}
                  handleOnClickAddImages={images =>
                    handleOnClickAddImages(images, handleChange, values)
                  }
                  imgSrc="destination"
                  values={values}
                  destinationId={destinationId ? destinationId : null}
                  mediaSelected={values.selected_media}
                />
              </Card>
            );
          }}
        </Formik>
      )}
    </Mutation>
  );
};

export default DealImages;
