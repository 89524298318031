import gql from "graphql-tag";

export const FILTER_DEALS = gql`
    query deals($where: JSON, $limit: Int, $start: Int, $sort: String) {
        dealConcepts(where: $where, limit: $limit, start: $start, sort: $sort) {
            id
            title
            website{
                id
            }
            accommodation {
                name
                id
            }
            media(limit: 1)
            destination {
                name
                id
            }
            automatic
            publish_date
            price
            is_published_deal
            published
            expire_date
            status
        }
        dealConceptsConnection(where: $where, limit: -1) {
            aggregate {
                count
            }
        }
    }
`;

export const GET_USERS = gql`
    query users {
        users {
            username
            id
        }
    }
`;

export const GET_PROVIDERS = gql`
    query users {
        providers {
            name
            id
        }
    }
`;
