import React, { useState } from 'react';
import Card from 'components/Card';
import { UPDATE_DEAL } from 'queries/deals';
import {Field, Form, Formik} from 'formik';
import { formatForUpdate } from './helpers';
import validation from './validation';
import DetailIcon from 'assets/icons/detail-icon.svg';
import { Col, Row } from 'react-grid-system';
import {FormFeedback, FormGroup, Input, Label} from 'reactstrap';
import { Mutation } from 'react-apollo';
import PropTypes from 'prop-types';
import LengthValidator from "components/LengthValidator";

const DealOthers = ({ initialData, isValid, validValues }) => {
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const country = localStorage.getItem('country');

  const validate = values => {
    try {
      validation.validateSync(values, { abortEarly: false });
      isValid(true);
      validValues(formatForUpdate(values));
      return true;
    } catch (error) {
      isValid(false);
      return false;
    }
  };

  function handleCompletion() {
    setSuccessMessage('Overige velden zijn succesvol opgeslagen');
    setTimeout(() => setSuccessMessage(null), 3000);
  }

  function handleError() {
    setErrorMessage('Er is iets mis gegaan bij het updaten');
  }

  return (
    <Mutation mutation={UPDATE_DEAL} onCompleted={handleCompletion} onError={handleError}>
      {updateDeal => (
        <Formik
          enableReinitialize={true}
          initialValues={initialData}
          onSubmit={(values, { setValues }) => {
            updateDeal({ variables: formatForUpdate(values) });
          }}
          validationSchema={validation}
          validate={errors => validate(errors)}
        >
          {props => {
            const { values, errors, handleChange, handleSubmit } = props;
            return (
              <Card
                disabled={Object.keys(errors).length > 0}
                hideSaveButton={Object.keys(errors).length > 0}
                title="Overige"
                subTitle="Overige velden"
                thumbnailSrc={DetailIcon}
                errorMessage={errorMessage}
                successMessage={successMessage}
                uniqueId="others"
                onSave={() => handleSubmit()}
              >
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col lg={3}>
                      <FormGroup check>
                        <p>Tonen op homepagina</p>
                        <Label check style={{ marginLeft: '20px' }}>
                          <Input
                            type="checkbox"
                            name="show_on_homepage"
                            checked={values.show_on_homepage}
                            onChange={e =>
                              handleChange({
                                target: { name: 'show_on_homepage', value: e.target.checked },
                              })
                            }
                          />{' '}
                          {values.show_on_homepage ? <p>Ja</p> : <p></p> }
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>

                        <Col lg={12}>
                          <FormGroup>
                            <Label for="meta_title" size="sm">
                              Meta titel
                            </Label>
                            <Input
                                type="text"
                                id="meta_title"
                                tag={Field}
                                name="meta_title"
                                onChange={e =>
                                    handleChange({
                                      target: { name: 'meta_title', value: e.target.value },
                                    })}
                                autoComplete="off"
                                placeholder="Vul de meta titel in"
                            />
                            {/*{errors.meta_title && touched.meta_title && <FormFeedback>{errors.meta_title}</FormFeedback>}*/}
                            <LengthValidator countThisValue={values.meta_title} maxValue={130} />
                          </FormGroup>
                        </Col>


                        <Col lg={12}>
                          <FormGroup>
                            <Label for="meta_description" size="sm">
                              Meta omschrijving
                            </Label>
                            <Input
                                type="textarea"
                                id="meta_description"
                                tag={Field}
                                name="meta_description"
                                onChange={e =>
                                  handleChange({
                                  target: { name: 'meta_description', value: e.target.value },
                                })
                                }
                                autoComplete="off"
                                placeholder="Vul de meta omschrijving in"
                            />
                            {/*{errors.meta_description && touched.meta_description && <FormFeedback>{errors.meta_description}</FormFeedback>}*/}
                            <LengthValidator countThisValue={values.meta_description} maxValue={130} />
                          </FormGroup>
                        </Col>

                  </Row>

                  <Row>
                    <Col lg={3}>
                      <FormGroup check>
                        <p>Tonen op weflycheap.be</p>
                        <Label check style={{ marginLeft: '20px' }}>
                          <Input
                              type="checkbox"
                              name="show_on_be"
                              checked={values.show_on_be}
                              onChange={e =>
                                  handleChange({
                                    target: { name: 'show_on_be', value: e.target.checked },
                                  })
                              }
                          />
                          {values.show_on_be ? <p>Ja</p> : <p></p> }
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>

                  <br />
                </Form>
              </Card>
            );
          }}
        </Formik>
      )}
    </Mutation>
  );
};

DealOthers.propTypes = {
  initialData: PropTypes.object.isRequired,
  isValid: PropTypes.func.isRequired,
};

export default DealOthers;
