import React, { Component } from 'react';
import axios from 'axios';
import { Container, Button, Col, Alert, FormGroup, Label, Row, Input } from 'reactstrap';

// contexts
import AuthContext from 'contexts/authContext';

const baseUrl = process.env.REACT_APP_BASE_URL;

// Consts
const apiUrl = `${baseUrl}/auth/local`;

class Auth extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.userNameElement = React.createRef();
    this.passwordElement = React.createRef();
    this.state = {onError: false, notAContentManager: false};
  }

  submitHandler = async event => {
    event.preventDefault();
    const username = this.userNameElement.current.value;
    const password = this.passwordElement.current.value;

    try {
      const response = await axios.post(apiUrl, {
        identifier: username,
        password: password,
      });
      if (response.statusCode === 400 && response.statusCode === 401) {
        this.setState({
          onError: true,
          notAContentManager: false
        });
        throw new Error('Failed');
      }
      if (response.data.user.role.name !== 'Content manager') {
        this.setState({
          onError: false,
          notAContentManager: true
        });
        throw new Error('contentmanager');
      }
      if (response.data.jwt) {
        this.context.login(response.data.jwt, response.data.user);
      }
    } catch (error) {
      
    }
  };

  render() {
    return (
      <Container>
        <Row className="justify-content-center">
          <Col
            lg={3}
            className="mt-5 px-3 py-4"
            style={{
              backgroundColor: 'white',
              borderRadius: '4px',
              boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
            }}
          >
            <form onSubmit={this.submitHandler}>
            {this.state.onError && <Alert color="danger" classNam="mt-3">Gebruikersnaam of wachtwoord is onjuist</Alert>}
            {this.state.notAContentManager && <Alert color="danger" classNam="mt-3">Deze gebruiker is geen content manager</Alert>}
              <FormGroup>
                <Label for="title" size="sm">
                  Gebruikersnaam
                </Label>
                <Input
                  type="text"
                  id="username"
                  autoComplete="off"
                  innerRef={this.userNameElement}
                  placeholder="Vul je gebruikersnaam in..."
                />
              </FormGroup>
              <FormGroup>
                <Label for="title" size="sm">
                  Wachtwoord
                </Label>
                <Input
                  type="password"
                  id="password"
                  innerRef={this.passwordElement}
                  autoComplete="off"
                  placeholder="Vul je wachtwoord in..."
                />
              </FormGroup>
              <Button type="submit" className="mt-3 mb-4" color="success" style={{ width: '100%' }}>
                Inloggen
              </Button>
            </form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Auth;
