import gql from 'graphql-tag';

export const GET_DAILY_DEALS = gql`
  query DailyDeals {
    dailydeals {
      day
      deal {
        id
        title
        selected_media
        automatic
      }
      id
      title
      push_title
      intro
      push_text
      image_url
      direct_link
      direct_link_partner
      url
      push_date
      price
    }
  }
`;
