export function formatForUpdate(values) {
  return {
    variables: {
      id: values.id !== '' ? values.id : null,
      name: values.name,
      description: values.description,
      stars: values.stars,
      rating: values.rating,
      facilities: values.facilities ? JSON.stringify(values.facilities) : null,
      usps: values.usps ? JSON.stringify(values.usps) : null,
    },
  };
}
