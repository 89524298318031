import axios from 'axios';
const baseUrl = process.env.REACT_APP_BASE_URL;

const getBookingExamplesIds = bookingExamples => bookingExamples.map(example => example.id);

export function formatForUpdate(values) {
  const date = values.date ? new Date(values.date).toISOString() : null;
  const dealtypes = values.dealtypes ? values.dealtypes.map(dealtype => dealtype.value) : null;

  let valuess = {
    id: values.id,
    price: values.price ? Number(values.price) : null,
    days: values.days ? Number(values.days) : null,
    nights: values.nights ? Number(values.nights) : null,
    date,
    persons: values.persons ? values.persons : null,
    departure_airport: values.departure_airport ? values.departure_airport.value : null,
    board_type: values.board_type ? values.board_type.value : null,
    arrival_airport: values.arrival_airport ? values.arrival_airport.value : null,
    notes: values.notes ? values.notes.trim(): null,
    dealtypes,
    providers: values.providers ? [values.providers.value] : null,
    including_accommodation: values.including_accommodation,
    including_flight: values.including_flight,
    including_rentalcar: values.including_rentalcar,
    including_transfer: values.including_transfer,
    including_train_travel: values.including_train_travel,
    accommodation: values.accommodation ? values.accommodation.value : null,
    destination: values.destination ? values.destination.value : null,
    alternative_date: values.alternative_date ? values.alternative_date.trim() : null,
    booking_examples: values.booking_examples && values.booking_examples.length > 0 ? getBookingExamplesIds(values.booking_examples) : null,
  };

  return valuess
}

export function getAsync(inputValue, types, field) {
  const API_URL = `${baseUrl}/graphql`;
  const token = localStorage.getItem('token');

  async function loadData() {
    const data = await axios.post(
      API_URL,
      {
        query: `
          query{
            ${types}(where: {
              ${field}: "${inputValue}"
            }) {
              id
              name
            }
        }
      `,
        variables: {},
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${token}`,
        },
      },
    );
    return data.data.data[types].map(type => {
      return { value: type.id, label: type.name };
    });
  }

  if (!inputValue) return null;
  return new Promise(resolve => {
    const data = loadData();
    resolve(data);
  });
}

export function getAsyncAirports(inputValue) {
  const API_URL = `${baseUrl}/graphql`;
  const token = localStorage.getItem('token');

  async function loadData() {
    const data = await axios.post(
      API_URL,
      {
        query: `
      query{
        airports(where: {
            _q: "${inputValue}"
        }) {
            id
            name
        }
        }
      `,
        variables: {},
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${token}`,
        },
      },
    );
    return data.data.data.airports.map(airport => {
      return { value: airport.id, label: airport.name };
    });
  }

  if (!inputValue) return null;

  return new Promise(resolve => {
    const data = loadData();
    resolve(data);
  });
}

export function formatDepatureAirports(airports) {
  return airports ? airports.map(airport => ({ label: airport.name, value: airport.id, iata: airport.iata })) : [];
}

export function formatBoardTypes(boardTypes) {
  return boardTypes.map(board_type => ({ label: board_type.name, value: board_type.id }));
}
