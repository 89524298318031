import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import AuthContext from 'contexts/authContext';

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <AuthContext.Consumer>
      {context => {
        return (
          <Route
            {...rest}
            render={props => (context.token ? <Component {...props} /> : <Redirect to="/auth" />)}
          />
        );
      }}
    </AuthContext.Consumer>
  );
};

export default PrivateRoute;
