import * as Yup from 'yup';

const errorText = {
  required: 'Verplicht Veld',
};

const validation = Yup.object().shape({
  // arrival_airport: Yup.string().required(errorText.required),
  nights: Yup.number()
      .nullable()
      .integer('Hele getallen graag'),
  days: Yup.number()
    .nullable()
    .integer('Hele getallen graag'),
  // dealtypes: Yup.array()
  //     .min(2, 'Selecteer minstens een van de velden'),
  // departure_airport: Yup.string().required(errorText.required),
  persons: Yup.number()
    .nullable()
    .integer('Hele getallen graag'),
  price: Yup.number()
    .nullable()
    .min(1, 'Getal moet 1 of groter zijn')
    .required(errorText.required),
  // date: Yup.string()
  //   .required(errorText.required)
  //   .min(1),
  dealtypes: Yup.array().min(1),
  destination: Yup.object()
    .nullable(),
});

export default validation;
