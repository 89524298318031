import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Popover, PopoverBody, Collapse, Alert } from 'reactstrap';
import {
  StyledCard,
  Header,
  Title,
  SubTitle,
  Thumbnail,
  TitleWrapper,
  CollapseWrapper,
} from './CardCSS';

const Card = ({
  successMessage = null,
  errorMessage = null,
  title,
  subTitle,
  children,
  open = false,
  onSave,
  thumbnailSrc,
  uniqueId = '123456789',
  hideSaveButton,
  disabled,
}) => {
  const [isOpen, toggleIsOpen] = useState(open);

  function handleToggleOpen() {
    toggleIsOpen(!isOpen);
  }

  function handleOnSave(event) {
    // event.stopPropagation()
    onSave();
  }

  return (
    <StyledCard data-test="StyledCard">
      <Header
        data-test="Header"
        isOpen={isOpen}
        onMouseDown={e => e.stopPropagation()}
        onClick={handleToggleOpen}
      >
        <Thumbnail thumbnailSrc={thumbnailSrc} />
        <TitleWrapper>
          <Title>{title}</Title>
          <SubTitle>{subTitle}</SubTitle>
        </TitleWrapper>
        <Alert
          color="danger"
          isOpen={errorMessage}
          style={{ position: 'absolute', right: '144px' }}
        >
          {errorMessage}
        </Alert>
        {!hideSaveButton && (
          <Button
            style={{
              display: isOpen ? 'flex' : 'none',
              opacity: isOpen ? '1' : '0',
              transition: 'opacity .3s ease-in-out',
            }}
            id={uniqueId}
            type="button"
            disabled={disabled || !isOpen}
            outline
            color="primary"
            className={'ml-auto d-flex'}
            // onMouseDown={e => e.stopPropagation()}
            onClick={e => handleOnSave(e)}
          >
            Opslaan
          </Button>
        )}
        {!hideSaveButton && (
          <Popover placement="left" isOpen={Boolean(successMessage)} target={uniqueId}>
            <PopoverBody>{successMessage}</PopoverBody>
          </Popover>
        )}
      </Header>
      <Collapse isOpen={isOpen}>
        <CollapseWrapper data-test="CollapseWrapper">{children}</CollapseWrapper>
      </Collapse>
    </StyledCard>
  );
};

Card.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  successMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  children: PropTypes.any,
  open: PropTypes.bool,
  onSave: PropTypes.func,
  thumbnailSrc: PropTypes.node,
  uniqueId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool.isRequired,
};

export default Card;
