import styled from 'styled-components'

export const ShowMore = styled.span`
  font-size: 1rem;
  transition: color 200ms linear;
  color: #495057;

  &:hover{
  cursor: pointer;
  color: #18191d;
  transition: color 200ms linear;
  }
`;
