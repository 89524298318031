import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Row, Col, Button, Input, FormGroup, Label, FormFeedback } from 'reactstrap';
import Card from 'components/Card';
import RSSIcon from "assets/icons/rss-icon.svg";
import { GET_RSS_SETTINGS, UPDATE_RSS_SETTINGS } from './apollo';
import { Formik } from 'formik';
import { validation, formatForUpdateCampaign, formatInitialCampaign } from './helpers';
import { Mutation, Query } from 'react-apollo';

const CampagneTracking = props => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const website = localStorage.getItem('country_id');

  const handleCompletion = event => {
    setSuccessMessage('Wijzigingen zijn succesvol opgeslagen');
    setTimeout(() => {
      setSuccessMessage(null);
    }, 3000);
  };

  const handleError = event => {
    setSuccessMessage('Er is iets misgegaan tijdens het opslaan');
    setTimeout(() => {
      setErrorMessage(null);
    }, 3000);
  };

  return (
    <Query query={GET_RSS_SETTINGS} variables={{ where: { website: website } }}>
      {({ loading, error, data }) => {
        if (loading) return <p>loading</p>;
        if (error) return <p>Error</p>;

        if (data && data.rsssettings && data.rsssettings[0])
          return (
            <Row>
              <Col>
                <Mutation
                  mutation={UPDATE_RSS_SETTINGS}
                  onCompleted={handleCompletion}
                  onError={handleError}
                  fetchPolicy="no-cache"
                >
                  {updateRssSettings => (
                    <Formik
                      validationSchema={validation}
                      enableReinitialize={false}
                      initialValues={formatInitialCampaign(data.rsssettings[0])}
                      onSubmit={values => {
                        updateRssSettings(formatForUpdateCampaign(values));
                      }}
                    >
                      {props => {
                        const {
                          setValues,
                          values,
                          errors,
                          handleChange,
                          handleSubmit,
                          validateForm,
                        } = props;
                        if (values.length < 1)
                          return <p>Er zijn nog geen column_deals ingesteld</p>;
                        return (
                          <Card
                            disabled={Object.keys(errors).length > 0}
                            errorMessage={errorMessage}
                            successMessage={successMessage}
                            thumbnailSrc={RSSIcon}
                            title="Campagne Tracking"
                            onSave={() => handleSubmit()}
                            uniqueId="Campaigntracking"
                          >
                            <Row>
                              <Col md={6} lg={4}>
                                <FormGroup>
                                  <Label htmlFor="campaign__source">
                                    Campagne Source <span style={{ color: 'red' }}>*</span>
                                  </Label>
                                  <Input
                                    type="text"
                                    name="campaign_source"
                                    onChange={handleChange}
                                    value={values.campaign_source}
                                    invalid={Boolean(errors && errors.campaign_source)}
                                  />
                                  {(errors && errors.campaign_source) && <FormFeedback>{errors.campaign_source}</FormFeedback>}
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6} lg={4}>
                                <FormGroup>
                                  <Label htmlFor="campaign_name">
                                    Campagne Name <span style={{ color: 'red' }}>*</span>
                                  </Label>
                                  <Input
                                    type="text"
                                    name="campaign_name"
                                    onChange={handleChange}
                                    value={values.campaign_name}
                                    invalid={Boolean(errors && errors['campaign_name'])}
                                  />
                                  {(errors && errors['campaign_name']) && <FormFeedback>{errors.campaign_name}</FormFeedback>}
                                </FormGroup>
                              </Col>
                            </Row>
                          </Card>
                        );
                      }}
                    </Formik>
                  )}
                </Mutation>
              </Col>
            </Row>
          );
      }}
    </Query>
  );
};

CampagneTracking.propTypes = {};

CampagneTracking.defaultProps = {};

export default CampagneTracking;
