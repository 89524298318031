const getPricesId = (prices) => prices.map(price => price.id);

export function formatForUpdate(values) {
  const discount_expire = values.discount_expire
    ? new Date(values.discount_expire).toISOString()
    : null;

  const variables = {
      id: values.id ? values.id : null,
      formula_price_4_lg: values.formula_price_4_lg || values.formula_price_4_lg === 0 ? Number(values.formula_price_4_lg) : null,
      formula_price_4_hp: values.formula_price_4_hp || values.formula_price_4_hp === 0? Number(values.formula_price_4_hp) : null,
      formula_price_4_ai: values.formula_price_4_ai || values.formula_price_4_ai === 0? Number(values.formula_price_4_ai) : null,
      formula_price_8_lg: values.formula_price_8_lg || values.formula_price_8_lg === 0? Number(values.formula_price_8_lg) : null,
      formula_price_8_hp: values.formula_price_8_hp || values.formula_price_8_hp === 0? Number(values.formula_price_8_hp) : null,
      formula_price_8_ai: values.formula_price_8_ai || values.formula_price_8_ai === 0? Number(values.formula_price_8_ai) : null,
      formula_price_15_lg: values.formula_price_15_lg || values.formula_price_15_lg === 0? Number(values.formula_price_15_lg) : null,
      formula_price_15_hp: values.formula_price_15_hp || values.formula_price_15_hp === 0? Number(values.formula_price_15_hp) : null,
      formula_price_15_ai: values.formula_price_15_ai || values.formula_price_15_ai === 0? Number(values.formula_price_15_ai) : null,
      discount: values.discount || values.discount === 0 ? Number(values.discount) : null,
      discount_expire: discount_expire,
      published_deal: values.published_deal ? values.published_deal: null,
      prices: (values.prices && values.prices.length > 0) ? getPricesId(values.prices) : null,
  };
  return variables;
}


export function countNumberOfFilledInInputs(values) {
  let counter = 0;
  values.formula_price_4_lg > 0 ? (counter += 1) : (counter += 0);
  values.formula_price_8_lg > 0 ? (counter += 1) : (counter += 0);
  values.formula_price_15_lg > 0 ? (counter += 1) : (counter += 0);
  values.formula_price_4_hp > 0 ? (counter += 1) : (counter += 0);
  values.formula_price_8_hp > 0 ? (counter += 1) : (counter += 0);
  values.formula_price_15_hp > 0 ? (counter += 1) : (counter += 0);
  values.formula_price_4_ai > 0 ? (counter += 1) : (counter += 0);
  values.formula_price_8_ai > 0 ? (counter += 1) : (counter += 0);
  values.formula_price_15_ai > 0 ? (counter += 1) : (counter += 0);

  return counter;
}

export const boardTypesEnums = [
  { label: 'Logies', value: 'LG' },
  { label: 'Half pension', value: 'HP' },
  { label: 'Logies en Onbijt', value: 'LO' },
  { label: 'Vol pension', value: 'VP' },
  { label: 'All inclusive', value: 'AI' },
  { label: 'Ultra inclusive', value: 'UA' },
];

export const transportTypesEnums = [
  { label: 'Vliegtuig', value: 'flight' },
  { label: 'Bus', value: 'bus' },
  { label: 'Eigen vervoer', value: 'owntransport' },
  { label: 'Trein', value: 'train' },
];
