import React from 'react';

export default React.createContext({
  token: null,
  user: null,
  loading: true,
  error: false,
  login: (token, user) => {},
  logout: () => {},
});
