import React, { useState } from 'react';
import { Row, Col, Spinner, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SelectableImg from 'components/SelectableImg';
import { Query } from 'react-apollo';
import { GET_ACCOMMODATION_AND_DESTINATION_IMAGES } from 'queries/deals';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';

const ImagesModal = ({
  values,
  isOpen,
  toggle,
  imgSrc,
  handleOnClickAddImages,
  title,
  mediaSelected,
  accommodationId,
  destinationId,
}) => {
  const [images, setImages] = useState([]);

  function setSelectedValueOfImage(index, value) {
    let newImages = [...images];
    let image = newImages[index];
    image.selected = value;
    setImages(newImages);
  }

  function onClickSave(images) {
    const result = images.filter(
      image => image.selected === true && !mediaSelected.find(media => media.id === image.id),
    );
    handleOnClickAddImages(result);
    setImages([]);
  }

  if (values.accommodation || values.destination || destinationId || accommodationId) {
    return (
      <Modal isOpen={isOpen} toggle={toggle} size="lg">
        {!values.accommodation && !accommodationId && imgSrc === 'accommodation' && (
          <h2>Selecteer eerst een accommodatie</h2>
        )}
        {!values.destination && !destinationId && imgSrc !== 'accommodation' && (
          <h2>Selecteer eerst een bestemming</h2>
        )}

        <Query
          query={GET_ACCOMMODATION_AND_DESTINATION_IMAGES}
          variables={{
            accommodationID: accommodationId
              ? accommodationId
              : values.accommodation
              ? values.accommodation.value
              : 'none',
            destinationID: destinationId
              ? destinationId
              : values.destination
              ? values.destination.value
              : 'none',
          }}
        >
          {({ loading, error, data, refetch }) => {
            if (loading) return <Spinner type="grow" color="primary" />;
            if (error) return `Error! ${error.message}`;
            let images =
              imgSrc === 'accommodation' ? data.accommodation.media : data.destination.media;
            setImages(images);
            return (
              <>
                <ModalHeader toggle={toggle}>{title}</ModalHeader>
                <ModalBody>
                  <Row>
                    {images.length > 0 &&
                      images.map((image, index) => {
                        return (
                          <Col lg={4} key={index} className={index > 2 ? 'mt-4' : 'mt-0'}>
                            <SelectableImg
                              src={`${image.thumb}`}
                              handleOnSelect={() => setSelectedValueOfImage(index, true)}
                              handleOnDeselect={() => setSelectedValueOfImage(index, false)}
                              alreadySelected={mediaSelected.find(media => media.id === image.id)}
                            />
                          </Col>
                        );
                      })}
                    {images.length === 0 && (
                      <h5 className="text-danger text-center w-100 mb-0">
                        Er zijn geen foto's gevonden
                      </h5>
                    )}
                  </Row>
                </ModalBody>
                <ModalFooter>
                  <FontAwesomeIcon
                    className="mr-auto"
                    icon={faSync}
                    onClick={() => refetch()}
                    style={{ cursor: 'pointer' }}
                  />{' '}
                  <Button color="primary" onClick={() => onClickSave(images)}>
                    Foto's toevoegen
                  </Button>{' '}
                  <Button color="secondary" onClick={toggle}>
                    Annuleren
                  </Button>
                </ModalFooter>
              </>
            );
          }}
        </Query>
      </Modal>
    );
  } else {
    return (
      <Modal isOpen={isOpen} toggle={toggle} size="lg">
        <ModalBody>
          {!values.accommodation && imgSrc === 'accommodation' && (
            <h2 className="text-danger">Selecteer eerst een accommodatie</h2>
          )}
          {!values.destination && imgSrc !== 'accommodation' && (
            <h2 className="text-danger">Selecteer eerst een bestemming</h2>
          )}
        </ModalBody>
      </Modal>
    );
  }
};

export default ImagesModal;
