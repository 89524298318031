import styled from 'styled-components';
import { color, setFont, setRem } from 'styles/styles';

export const StyledCard = styled.div`
  width: 100%;
  background: #ffffff;
  /* box-sizing: border-box; */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
  margin-bottom: ${setRem(20)};
`;

export const Header = styled.div`
  width: 100%;
  padding: ${setRem(25)} ${setRem(30)};
  background: #ffffff;
  border-bottom: ${props => (props.isOpen ? '1px solid rgba(0,0,0,0.12)' : 'none')};
  box-sizing: border-box;
  border-radius: ${props => (props.isOpen ? '0px' : '4px')};
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.span`
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  white-space: nowrap;
  color: ${color.mainBlack};
  ${setFont.main};
`;

export const SubTitle = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  color: ${color.mainGrey};
  ${setFont.main};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 767px) {
    width: ${props => (props.isOpen ? '251px' : '150px')};
  }
`;

export const Thumbnail = styled.div`
  background-image: url(${props => props.thumbnailSrc});
  background-size: contain;
  width: ${setRem(46)};
  height: ${setRem(46)};
  background-repeat: no-repeat;
  border: 0px ${color.lightGrey};
  color: ${color.lightGrey};
  margin-right: ${setRem(27)};
  @media (max-width: 467px) {
    margin-right: ${setRem(17)};
    display: flex;
    align-items: center;
  }
`;

export const CollapseWrapper = styled.div`
  padding: ${setRem(25)} ${setRem(60)};
  @media (max-width: 467px) {
    padding: ${setRem(10)} ${setRem(20)};
  }
`;
