import React, { useState, memo } from 'react';
import Card from 'components/Card';
import IntroIcon from 'assets/icons/intro-icon.svg';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'weflycheap-cke5-wordcount';
import { Input, FormGroup, Label } from 'reactstrap';
import { createEventTarget } from 'utils/helpers';
import { UPDATE_DESTINATION, CREATE_DESTINATION } from 'queries/deals';
import { Mutation } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import debounce from 'debounce-promise';
import axios from 'axios';
import { formatForUpdate } from './helpers';
import PropTypes from 'prop-types';
const baseUrl = process.env.REACT_APP_BASE_URL;

function getAsync(inputValue, types, field) {
  const API_URL = `${baseUrl}/graphql`;
  const token = localStorage.getItem('token');

  async function loadData() {
    const data = await axios.post(
      API_URL,
      {
        query: `
          query{
            ${types}(where: {
              ${field}: "${inputValue}"
            }) {
              id
              name
            }
        }
      `,
        variables: {},
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${token}`,
        },
      },
    );
    return data.data.data[types].map(type => {
      return { value: type.id, label: type.name };
    });
  }

  if (!inputValue) return null;
  return new Promise(resolve => {
    const data = loadData();
    resolve(data);
  });
}

const DestinationIntroduction = memo(({ handleChange, values, errors, history }) => {
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const mutation = values.id ? UPDATE_DESTINATION : CREATE_DESTINATION;
  function handleDescriptionValueChange(data) {
    handleChange(createEventTarget('description', data));
  }

  async function handleUpdateDestination(callback) {
    const response = await callback(formatForUpdate(values));
    //Als de mutation createDestination is dan wordt er geredirect na het aanmaken naar het updaten.
    if (response && response.data.createDestination) {
      history.push(`/content/destinations/${response.data.createDestination.destination.id}`);
    }
  }

  function handleCompletion() {
    setSuccessMessage('Bestemmingstekst is succesvol opgeslagen');
    setTimeout(() => setSuccessMessage(null), 3000);
  }

  function handleError() {
    setErrorMessage('Oeps, er ging iets mis');
  }

  const loadDestinations = inputValue => getAsync(inputValue, 'destinations', 'name_contains');

  const debouncedDestinations = debounce(loadDestinations, 350, {
    leading: true,
  });

  function handleParentChange(value, handleChange) {
    handleChange(createEventTarget('parent', value));
  }

  return (
    <Mutation mutation={mutation} onCompleted={handleCompletion} onError={handleError}>
      {(updateDestination, { loading }) => (
        <Card
          errors={errors}
          title="Bestemmingstekst"
          subTitle={values.name}
          thumbnailSrc={IntroIcon}
          onSave={() => handleUpdateDestination(updateDestination)}
          uniqueId="introductie"
          errorMessage={errorMessage}
          successMessage={successMessage}
          disabled={false}
        >
          {loading && <h1>laden</h1>}
          <FormGroup>
            <Label for="intro" size="sm">
              Naam
            </Label>
            <Input type="text" name="name" id="name" value={values.name} onChange={handleChange} />
          </FormGroup>

          <FormGroup>
            <Label for="accommodation" size="sm">
              Bevind zich in
            </Label>
            <AsyncSelect
              cacheOptions
              name="parent"
              loadOptions={debouncedDestinations}
              onChange={value => handleParentChange(value, handleChange)}
              defaultOptions
              value={values.parent}
              noOptionsMessage={() => null}
            />
          </FormGroup>

          <FormGroup>
            <Label for="intro" size="sm">
              Introductie
            </Label>
            <CKEditor
              editor={ClassicEditor}
              data={values.description}
              onChange={(event, editor) => {
                const data = editor.getData();
                handleDescriptionValueChange(data);
              }}
              name="description"
              id="description"
              config={{
                toolbar: [
                  'heading',
                  '|',
                  'bold',
                  'italic',
                  'link',
                  'bulletedList',
                  'numberedList',
                  'undo',
                  'redo',
                ],
                heading: {
                  options: [
                    { model: 'paragraph', title: 'Alinea', class: 'ck-heading_paragraph' },
                    {
                      model: 'heading2',
                      view: 'h2',
                      title: 'Heading 2',
                      class: 'ck-heading_heading2',
                    },
                  ],
                },
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label for="latitude" size="sm">
              Latitude
            </Label>
            <Input type="number" name="latitude" id="latitude" value={values.latitude} onChange={handleChange} />
          </FormGroup>
          <FormGroup>
            <Label for="longitude" size="sm">
              Longitude
            </Label>
            <Input type="number" name="longitude" id="longitude" value={values.longitude} onChange={handleChange} />
          </FormGroup>
          <a href="https://www.latlong.net/">Coördinaten zoeken</a>
        </Card>
      )}
    </Mutation>
  );
}, areEqual);

function areEqual(prevProps, nextProps) {
  return (
    JSON.stringify(prevProps.values.parent) === JSON.stringify(nextProps.values.parent) &&
    prevProps.values.name === nextProps.values.name &&
    prevProps.values.latitude === nextProps.values.latitude &&
    prevProps.values.longitude === nextProps.values.longitude &&
    prevProps.values.description === nextProps.values.description
  );
}

DestinationIntroduction.propTypes = {
  handleChange: PropTypes.func,
  values: PropTypes.object.isRequired,
  history: PropTypes.object,
};

export default withRouter(DestinationIntroduction);
