import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';

const TooltipText = ({ text, tooltipText, index }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  function toggle() {
    setTooltipOpen(!tooltipOpen);
  }

  return (
    <span>
      <span id={`tooltip${index}`}>{text}</span>
      <Tooltip placement="top" isOpen={tooltipOpen} target={`tooltip${index}`} toggle={toggle}>
        {tooltipText}
      </Tooltip>
    </span>
  );
};

export default TooltipText;
