import React, { useState } from 'react';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Collapse,
  Alert,
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import AsyncSelect from 'react-select/async/dist/react-select.esm';
import { getDealsAsync } from 'utils/helpers';
import debounce from 'debounce-promise';
import { createEventTarget } from 'utils/helpers';
import ImageSelector from '../ImageSelector';
import DebouncedInput from '../DebouncedInput';
import { ShowMore } from './ColumnDealCSS';
const baseUrl = process.env.REACT_APP_BASE_URL;

const ColumnDeal = ({ columnDeal, index, handleChange, values, deleteColumnDeal, errors, initialVisible = false }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(initialVisible);

  const toggleModal = () => setIsModalOpen(state => !state);

  const loadDeals = inputValue => getDealsAsync(inputValue, 'filterDeal', 'slug');
  const debouncedDeals = debounce(loadDeals, 350, {
    leading: true,
  });

  const updateValues = (name, value) => {
    const currentColumnDeal = { ...values.columnDeals[index], [name]: value };
    const currentColumnDeals = [...values.columnDeals];
    currentColumnDeals[index] = currentColumnDeal;
    handleChange(createEventTarget('columnDeals', currentColumnDeals));
  };

  const handleDealChange = value => {
    const deal = {...value};
    const url = values.columnDeals[index].direct_link ? deal.affiliate_url : `https://weflycheap.${localStorage.getItem('country') === 'BE' ? 'be' : 'nl'}/${deal.slug}`;
    const currentColumnDeal = {
      ...values.columnDeals[index],
      deal,
      image: null,
      selectedMedia: value.selectedMedia,
      url
    };
    const currentColumnDeals = [...values.columnDeals];
    currentColumnDeals[index] = currentColumnDeal;
    handleChange(createEventTarget('columnDeals', currentColumnDeals));
  };

  const handleDirectLinkChange = event => {
    const direct_link = event.target.checked;
    const url = direct_link ? values.columnDeals[index].deal.affiliate_url : `https://weflycheap.${localStorage.getItem('country') === 'BE' ? 'be' : 'nl'}/${values.columnDeals[index].deal.slug}`;

    const currentColumnDeal = { ...values.columnDeals[index], direct_link, url };
    const currentColumnDeals = [...values.columnDeals];
    currentColumnDeals[index] = currentColumnDeal;
    handleChange(createEventTarget('columnDeals', currentColumnDeals));
  };

  const handleImageChange = image => {
    const currentColumnDeal = { ...values.columnDeals[index], image };
    const currentColumnDeals = [...values.columnDeals];
    currentColumnDeals[index] = currentColumnDeal;
    handleChange(createEventTarget('columnDeals', currentColumnDeals));
  };

  return (
    <>
      {errors && errors.columnDeals && errors.columnDeals[index] && (
        <Row>
          <Col>
            <Alert color="danger">Vul a.u.b. alle verplichte velden in!</Alert>
          </Col>
        </Row>
      )}
      <Row>
        <Col sm={12}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>{!isVisible && <span style={{ fontSize: '16px' }}>{columnDeal.title}</span>}</div>

            <ShowMore onClick={() => setIsVisible(!isVisible)}>
              {isVisible ? 'Toon minder' : 'Toon meer'}
            </ShowMore>
          </div>
        </Col>
      </Row>
      <Collapse isOpen={isVisible}>
        <Row>
          <Col>
            <FormGroup>
              <Label for="dailydeal" size="sm">
                Deal<span style={{ color: 'red' }}>*</span>
              </Label>
              <AsyncSelect
                name="dailydeal"
                cacheOptions
                name="parent"
                loadOptions={debouncedDeals}
                noOptionsMessage={() => null}
                onChange={handleDealChange}
                value={columnDeal.deal}
              />
            </FormGroup>
          </Col>
        </Row>
        {columnDeal.deal && (
          <Row>
            <Col>
              <a
                href={`/deals/${columnDeal.deal.value}/`}
                target="_blank"
              >
                Bekijk deal
              </a>
            </Col>
          </Row>
        )}
        <Row>
          <Col lg={4}>
            <ImageSelector
              handleImageChange={handleImageChange}
              selectedMedia={columnDeal.selectedMedia}
              selectedImage={columnDeal.image}
            />
          </Col>
          <Col lg={8}>
            <Row>
              <Col sm={12}>
                <DebouncedInput
                    maxLength={60}
                  value={columnDeal.title}
                  handleChange={updateValues}
                  type="text"
                  name="title"
                  reInitialize={true}
                  autoComplete="off"
                  placeholder="Vul een titel in"
                  label="Titel"
                />
              </Col>
              <Col sm={12}>
                <DebouncedInput
                    maxLength={200}
                  value={columnDeal.description}
                  handleChange={updateValues}
                  type="textarea"
                  name="description"
                  autoComplete="off"
                  label="Beschrijving"
                  placeholder="Vul content in"
                />
              </Col>
              {values.columnDeals[index].direct_link && (
              <Col>
                <DebouncedInput
                  value={values.columnDeals[index].url}
                  handleChange={updateValues}
                  type="text"
                  name="url"
                  reInitialize={true}
                  autoComplete="off"
                  placeholder="Vul een url in"
                  label="Url"
                />
              </Col>
              )}

            </Row>
            <Row>
              <Col lg={6}>
                <FormGroup style={{ marginLeft: '20px' }}>
                  <Label check>
                    <Input
                      checked={columnDeal.direct_link}
                      name="direct_link"
                      onChange={handleDirectLinkChange}
                      type="checkbox"
                    />{' '}
                    Directe link
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg={8}>
                <DebouncedInput
                  value={columnDeal.button_text}
                  handleChange={updateValues}
                  type="text"
                  name="button_text"
                  autoComplete="off"
                  label="Button tekst"
                  placeholder="Vul een tekst voor de button in"
                />
              </Col>
            </Row>

            <Row>
              <Col lg={8}>
                <DebouncedInput
                    value={columnDeal.alt}
                    handleChange={updateValues}
                    type="text"
                    name="alt"
                    autoComplete="off"
                    label="Alt tekst"
                    placeholder="Vul een alt tekst in"
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button color="danger" style={{ marginBottom: '10px' }} size="sm" onClick={toggleModal}>
              Verwijder
            </Button>
          </Col>
          <Modal isOpen={isModalOpen} toggle={toggleModal}>
            <ModalBody>
              <div style={{ display: 'flex' }}>
                <p style={{ margin: '0 auto', fontSize: '1.3rem' }}>Weet je het zeker?</p>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="danger"
                onClick={() => {
                  deleteColumnDeal(index);
                  toggleModal();
                }}
              >
                Ja
              </Button>{' '}
              <Button color="primary" onClick={toggleModal}>
                Annuleren
              </Button>
            </ModalFooter>
          </Modal>
        </Row>
      </Collapse>
      <hr />
    </>
  );
};

export default ColumnDeal;
