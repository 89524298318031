import * as yup from 'yup';

const REQUIRED_FIELD = 'Verplicht veld';

export const validation = yup.object().shape({
    campaign_name: yup.string().required('Verplicht veld'),
    campaign_source: yup.string().required('Verplicht veld'),
});


export const formatInitialCampaign = ({id, campaign_name, campaign_source}) => {
    return {
        id,
        campaign_name: campaign_name ? campaign_name : '',
        campaign_source: campaign_source ? campaign_source : '',
    };
};

export const formatForUpdateCampaign = ({id, campaign_name, campaign_source}) => {
    return {
        variables: {
            id,
            campaign_name: campaign_name ? campaign_name.trim() : null,
            campaign_source: campaign_source ? campaign_source.trim() : null,
        }
    };
};
