import moment from 'moment';

export function formatInitialIntro(data) {
  return {
    id: data.id,
    title: data.title ? data.title : '',
    subtitle: data.subtitle ? data.subtitle : '',
    intro: data.intro ? data.intro : '',
    destination: data.destination ? data.destination.name : '',
    destinationParent: data.destination && data.destination.parent ? data.destination.parent.name : 'NVT',
    additional_html: data.additional_html ? data.additional_html : ''
  };
}

export function formatInitialDetails(data) {
  const date = data.date ? moment(data.date).format('YYYY-MM-DD') : '';

  return {
    id: data.id,
    // Details
    price: data.price ? data.price : '',
    days: data.days ? data.days : '',
    nights: data.nights ? data.nights : '',
    date,
    alternative_date: data.alternative_date ? data.alternative_date : '',
    providers:
      data.providers && data.providers.length > 0
        ? { value: data.providers[0].id, label: data.providers[0].name }
        : null,
    board_type: data.board_type ? { value: data.board_type.id, label: data.board_type.name } : null,
    persons: data.persons ? data.persons : '',
    departure_airport: data.departure_airport
      ? { value: data.departure_airport.id, label: data.departure_airport.name }
      : null,
    arrival_airport: data.arrival_airport
      ? { value: data.arrival_airport.id, label: data.arrival_airport.name }
      : null,
    notes: data.notes ? data.notes : '',
    booking_examples: data.booking_examples ? data.booking_examples : null,
    dealtypes: data.dealtypes
      ? data.dealtypes.map(dealType => ({ value: dealType.id, label: dealType.name }))
      : null,
    including_accommodation: data.including_accommodation ? data.including_accommodation : false,
    including_flight: data.including_flight ? data.including_flight : false,
    including_rentalcar: data.including_rentalcar ? data.including_rentalcar : false,
    including_transfer: data.including_transfer ? data.including_transfer : false,
    including_train_travel: data.including_train_travel ? data.including_train_travel : false,
    destination: data.destination
      ? { value: data.destination.id, label: data.destination.name, media: data.destination.media }
      : null,
    accommodation: data.accommodation
      ? {
          value: data.accommodation.id,
          label: data.accommodation.name,
          media: data.accommodation.media,
        }
      : null,
    // Urls
    dealproviders: data.dealproviders ? data.dealproviders : null,
    automatic: data.automatic,
  };
}

export function formatInitialImages(data) {
  return {
    id: data.id,
    selected_media: data.media ? data.media : [],
    accommodation: data.accommodation
      ? {
          value: data.accommodation.id,
          label: data.accommodation.name,
          media: data.accommodation.media,
        }
      : null,
    destination: data.destination
      ? { value: data.destination.id, label: data.destination.name, media: data.destination.media }
      : null,
  };
}

export function formatInitialDealProviders(data) {
  return {
    id: data.id,
    dealproviders: data.dealproviders ? data.dealproviders : [],
  };
}

export function formatInitialPrices(data) {
  const discount_expire = data.discount_expire
    ? moment(data.discount_expire).format('YYYY-MM-DD')
    : '';
  return {
    // Prijzen en kortingen??
    id: data.id,
    formula_price_4_hp:
      data.formula_price_4_hp || data.formula_price_4_hp === 0 ? data.formula_price_4_hp : '',
    formula_price_4_lg:
      data.formula_price_4_lg || data.formula_price_4_lg === 0 ? data.formula_price_4_lg : '',
    formula_price_4_ai:
      data.formula_price_4_ai || data.formula_price_4_ai === 0 ? data.formula_price_4_ai : '',
    formula_price_8_lg:
      data.formula_price_8_lg || data.formula_price_8_lg === 0 ? data.formula_price_8_lg : '',
    formula_price_8_hp:
      data.formula_price_8_hp || data.formula_price_8_hp === 0 ? data.formula_price_8_hp : '',
    formula_price_8_ai:
      data.formula_price_8_ai || data.formula_price_8_ai === 0 ? data.formula_price_8_ai : '',
    formula_price_15_lg:
      data.formula_price_15_lg || data.formula_price_15_lg === 0 ? data.formula_price_15_lg : '',
    formula_price_15_hp:
      data.formula_price_15_hp || data.formula_price_15_hp === 0 ? data.formula_price_15_hp : '',
    formula_price_15_ai:
      data.formula_price_15_ai || data.formula_price_15_ai === 0 ? data.formula_price_15_ai : '',
    discount: data.discount ? data.discount : '',
    discount_expire,
    slug: data.slug,
    prices: data.prices,
    published_deal: data.published_deal
  };
}

export function formatInitialData(data) {
  const date = data.date ? moment(data.date).format('YYYY-MM-DD') : '';
  const discount_expire = data.discount_expire
    ? moment(data.discount_expire).format('YYYY-MM-DD')
    : '';

  return {
    ...data,
    // Intro
    title: data.title ? data.title : '',
    intro: data.intro ? data.intro : '',
    // Details
    price: data.price ? data.price : '',
    days: data.days ? data.days : '',
    date,
    alternative_date: data.alternative_date ? data.alternative_date : '',
    board_type: data.board_type ? { value: data.board_type.id, label: data.board_type.name } : null,
    persons: data.persons ? data.persons : '',
    departure_airport: data.departure_airport
      ? { value: data.departure_airport.id, label: data.departure_airport.name }
      : null,
    arrival_airport: data.arrival_airport
      ? { value: data.arrival_airport.id, label: data.arrival_airport.name }
      : null,
    notes: data.notes ? data.notes : '',
    booking_examples: data.booking_examples ? data.booking_examples : null,
    dealtypes: data.dealtypes
      ? data.dealtypes.map(dealType => ({ value: dealType.id, label: dealType.name }))
      : null,
    including_accommodation: data.including_accommodation ? data.including_accommodation : false,
    including_flight: data.including_flight ? data.including_flight : false,
    including_rentalcar: data.including_rentalcar ? data.including_rentalcar : false,
    including_transfer: data.including_transfer ? data.including_transfer : false,
    including_train_travel: data.including_train_travel ? data.including_train_travel : false,
    destination: data.destination
      ? { value: data.destination.id, label: data.destination.name, media: data.destination.media }
      : null,
    accommodation: data.accommodation
      ? {
          value: data.accommodation.id,
          label: data.accommodation.name,
          media: data.accommodation.media,
        }
      : null,
    // Urls
    dealproviders: data.dealproviders ? data.dealproviders : null,
    // Foto's
    selected_media: data.selected_media ? data.selected_media : null,
    // Sidebar
    publish_date: data.publish_date
      ? moment(data.publish_date, 'YYYY-MM-DD HH:mm:ss').toDate()
      : new moment().format('YYYY-MM-DD HH:mm:ss'),
    expire_date: data.expire_date
      ? moment(data.expire_date, 'YYYY-MM-DD HH:mm:ss').toDate()
      : new moment().format('YYYY-MM-DD HH:mm:ss'),
    // Prijzen en kortingen??
    formula_price_4_hp: data.formula_price_4_hp ? data.formula_price_4_hp : 0,
    formula_price_4_lg: data.formula_price_4_lg ? data.formula_price_4_lg : 0,
    formula_price_4_ai: data.formula_price_4_ai ? data.formula_price_4_ai : 0,
    formula_price_8_lg: data.formula_price_8_lg ? data.formula_price_8_lg : 0,
    formula_price_8_hp: data.formula_price_8_hp ? data.formula_price_8_hp : 0,
    formula_price_8_ai: data.formula_price_8_ai ? data.formula_price_8_ai : 0,
    formula_price_15_lg: data.formula_price_15_lg ? data.formula_price_15_lg : 0,
    formula_price_15_hp: data.formula_price_15_hp ? data.formula_price_15_hp : 0,
    formula_price_15_ai: data.formula_price_15_ai ? data.formula_price_15_ai : 0,
    discount: data.discount ? data.discount : 0,
    discount_expire,
    published_deal: data.published_deal ? data.published_deal: null,
  };
}

export function formatForUpdate(values) {
  const date = new Date(values.date).toISOString();
  const dealtypes = values.dealtypes.map(dealtype => dealtype.value);
  const expire_date = values.expire_date
    ? moment(values.expire_date).format('YYYY-MM-DDTHH:mm:ss.sss')
    : null;
  const publish_date = values.expire_date
    ? moment(values.publish_date).format('YYYY-MM-DDTHH:mm:ss.sss')
    : null;
  const discount_expire = values.discount_expire
    ? new Date(values.discount_expire).toISOString()
    : null;
  const selected_media = [];

  values.selected_media.forEach((media, index) => {
    let newMedia = { id: media.id, order: index };
    selected_media.push(newMedia);
  });

  return {
    ...values,
    selected_media: selected_media,
    departure_airport: values.departure_airport.value,
    arrival_airport: values.arrival_airport.value,
    date: date,
    dealtypes,
    board_type: values.board_type.value,
    accommodation: values.accommodation.value,
    destination: values.destination.value,
    publish_date: publish_date,
    expire_date: expire_date,
    discount_expire: discount_expire,
    booking_examples: formatBookingExamplesForUpdate(values.booking_examples),
  };
}

export function formatDealproviders(dealProviders, dealId) {
  return dealProviders.map(dealprovider => {
    return {
      id: dealprovider.is_new ? null : dealprovider.id,
      deal: dealId,
      provider: dealprovider.provider.id,
      active: dealprovider.active,
      original_link: dealprovider.original_link,
      is_deleted: dealprovider.is_deleted,
      not_affiliate: dealprovider.not_affiliate,
    };
  });
}

export function formatInitialUrls(values) {
  return {
    affiliate_url: values.affiliate_url ? values.affiliate_url : '',
    hashed_slug: values.hashed_slug ? values.hashed_slug : '',
    wordpress_id: values.published_deal && values.published_deal.wordpress_id ? values.published_deal.wordpress_id : '',
    country_code: values.website && values.website.country_code ? values.website.country_code.toLowerCase() : ''
  };
}

export function formatBookingExamplesForUpdate(bookingExamples) {
  if (bookingExamples.length < 1) return [];
  const formatted = bookingExamples.map(example => example.id);
  return formatted;
}

export function formatInitialOthers(data) {
  const country = localStorage.getItem('country');
  return {
    id: data.id,
    show_on_homepage: data.show_on_homepage ? true : false,
    show_on_be: country === 'BE' || data.show_on_be ,
    hide_partners: data.hide_partners ? true : false,
    meta_title: data.meta_title ? data.meta_title : '',
    meta_description: data.meta_description ? data.meta_description : '',
  };
}

export function formatInitialDate(date) {
  const formattedDate = new Date(date);
  return formattedDate;
}

export function formatForUpdateDate(date) {
  if (date) {
    const formattedDate = date.toISOString();
    return formattedDate;
  } else {
    return null;
  }
}
