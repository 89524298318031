export function formatForUpdate(values) {
  return {
    variables: {
      id: values.id,
      title: values.title,
      intro: values.intro,
    },
  };
}

export const ckEditorConfig = {
  toolbar: [
    'heading',
    '|',
    'bold',
    'italic',
    'link',
    'bulletedList',
    'numberedList',
    'undo',
    'redo',
  ],
  heading: {
    options: [
      { model: 'paragraph', title: 'Alinea', class: 'ck-heading_paragraph' },
      {
        model: 'heading2',
        view: {
          name: 'h2',
          classes: 'heading-mt',
        },
        title: 'Heading 2',
        class: 'ck-heading_heading2',
        converterPriority: 'high'
      },
    ],
  },
};

export function getShortCodes(values) {
  return [
    { code: '[verzorging]' },
    { code: '[prijs]', value: values.price },
    { code: '[datum]', value: values.date },
    { code: '[vertrekluchthaven]', value: values.departure_airport },
    { code: '[aankomstluchthaven]', value: values.arrival_airport },
    { code: '[daagse]', value: values.days },
    { code: '[dagen]', value: values.days },
    { code: '[reisgezelschap]', value: values.persons },
  ];
}
