import * as Yup from 'yup';

const errorText = {
  required: 'Verplicht Veld',
};

const validation = Yup.object().shape({
  title: Yup.string()
    .max(130, 'Maximaal 130 tekens')
    .required(errorText.required),
  intro: Yup.string()
    .required(errorText.required),
  introLength: Yup.number()
      .max(1000)
});

export default validation;
