import styled from 'styled-components';
import { color } from 'styles/styles';

export const StyledTable = styled.div`
  background-color: #ffffff;
  border-top: 1px solid ${color.borderGrey};
  margin: 0 auto;
  vertical-align: center;
`;
export const TableHeader = styled.div`
  border-bottom: 1px solid ${color.borderGrey};
  padding: 20px 34px 20px 34px;
  color: ${color.mainGrey};
`;
export const TableImg = styled.img`
  max-width: 66px;
  height: 66px;
  border-radius: 4px;
  margin-right: 50px;
`;

export const TableRow = styled.div`
  padding: 12px 30px;
  color: ${color.mainGrey};
  text-transform: capitalize;
  vertical-align: center;
`;

export const Pagination = styled.div`
  
  padding: 10px 34px 20px 34px;
  
`
export const OpacityOverlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  opacity: .8;
`