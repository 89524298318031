import React, { useState, memo } from 'react';
import Card from 'components/Card';
import IntroIcon from 'assets/icons/intro-icon.svg';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'weflycheap-cke5-wordcount';
import { Input, FormGroup, Label } from 'reactstrap';
import { createEventTarget } from 'utils/helpers';
import { UPDATE_ACCOMMODATION, CREATE_ACCOMMODATION } from 'queries/deals';
import { Mutation } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { formatForUpdate } from './helpers';

const AccommodationIntroduction = memo(({ handleChange, values, errors, history }) => {
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const mutation = values.id ? UPDATE_ACCOMMODATION : CREATE_ACCOMMODATION;
  function handleDescriptionValueChange(data) {
    handleChange(createEventTarget('description', data));
  }

  async function handleUpdateAccommodation(callback) {
    const response = await callback(formatForUpdate(values));
    //Als de mutation createAccommodation is dan wordt er geredirect na het aanmaken naar het updaten.
    if (response) {
      if (response.data.createAccommodation) {
        history.push(`/content/accommodations/${response.data.createAccommodation.accommodation.id}`);
      }
    }
  }

  function handleCompletion() {
    setSuccessMessage('Accommodatietekst is succesvol opgeslagen');
    setTimeout(() => setSuccessMessage(null), 3000);
  }

  function handleError() {
    setErrorMessage('Oeps, er ging iets mis');
  }

  return (
    <Mutation mutation={mutation} onCompleted={handleCompletion} onError={handleError}>
      {(updateAccommodation, { loading }) => (
        <Card
          errors={errors}
          title="Accommodatietekst"
          subTitle={values.name}
          thumbnailSrc={IntroIcon}
          onSave={() => handleUpdateAccommodation(updateAccommodation)}
          uniqueId="introductie"
          errorMessage={errorMessage}
          successMessage={successMessage}
          disabled={false}
        >
          <FormGroup>
            <Label for="intro" size="sm">
              Naam
            </Label>
            <Input
              type="text"
              name="name"
              id="name"
              value={values.name}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="intro" size="sm">
              Introductie
            </Label>
            <CKEditor
              editor={ClassicEditor}
              data={values.description}
              onChange={(event, editor) => {
                const data = editor.getData();
                handleDescriptionValueChange(data);
              }}
              name="description"
              id="description"
              config={{
                toolbar: [
                  'heading',
                  '|',
                  'bold',
                  'italic',
                  'link',
                  'bulletedList',
                  'numberedList',
                  'undo',
                  'redo',
                ],
                heading: {
                  options: [
                    { model: 'paragraph', title: 'Alinea', class: 'ck-heading_paragraph' },
                    {
                      model: 'heading2',
                      view: 'h2',
                      title: 'Heading 2',
                      class: 'ck-heading_heading2',
                    },
                  ],
                },
              }}
            />
          </FormGroup>
        </Card>
      )}
    </Mutation>
  );
},areEqual )

function areEqual(prevProps, nextProps) {
  return prevProps.values.name === nextProps.values.name && prevProps.values.description === nextProps.values.description
}

export default withRouter(AccommodationIntroduction);
