import React from 'react';
import { Container, Row, Col} from 'reactstrap';
import { GET_DAILY_DEALS } from './apollo';
import { Query } from 'react-apollo';
import DailyDeal from './DailyDeal';

const DealOfTheDay = () => {
  return (
    <Container>
      <Query query={GET_DAILY_DEALS}>
        {({ loading, error, data }) => {
          if (loading) return <p>laden ...</p>;
          if (error) return <p>er ging iets mis {error.message}</p>;
          if (data && data.dailydeals) {
            return data.dailydeals.map(dailyDeal => (
              <Row key={dailyDeal.id}>
                <DailyDeal dailyDeal={dailyDeal} />
              </Row>
            ))
          }
          return <p>Kon helaas geen Deals van de dag vinden, probeer het later nog eens.</p>;
        }}
      </Query>
    </Container>
  );
};

export default DealOfTheDay;
