import React, { useState, memo } from 'react';
import Card from 'components/Card';
import DetailIcon from 'assets/icons/detail-icon.svg';
import { Input, FormGroup, Label, Row, Col, Button } from 'reactstrap';
import { createEventTarget } from 'utils/helpers';
import StarRatings from 'react-star-ratings';
import { UPDATE_ACCOMMODATION, CREATE_ACCOMMODATION } from 'queries/deals';
import { Mutation } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LengthValidator from 'components/LengthValidator';
import { formatForUpdate } from './helpers';

const AccommodationDetails = memo(({ handleChange, values, errors, history }) => {
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [facilityError, setFacilityError] = useState(null);
  const [uspError, setUspError] = useState(null);
  const mutation = values.id ? UPDATE_ACCOMMODATION : CREATE_ACCOMMODATION;
  const [stars, setStars] = useState(values.stars);
  const [facility, setFacility] = useState({ name: '', value: false });
  const [usp, setUsp] = useState('');

  function handleUspModify(value, index) {
    const newUsps = values.usps.map((usp, currentIndex) => {
      if (currentIndex === index) {
        return value;
      }
      return usp;
    });
    const event = {
      target: {
        name: 'usps',
        value: newUsps,
      },
    };
    handleChange(event);
  }

  function handleUspChange(event) {
    setUsp(event.target.value);
  }

  function handleAddUsp() {
    if (usp.length < 1) {
      setUspError('Veld mag niet leeg zijn ');
      return null;
    }
    if (uspError && usp.length > 0) {
      setUspError(null);
    }

    const newUsps = [...values.usps, usp.trim()];
    const event = {
      target: {
        name: 'usps',
        value: newUsps,
      },
    };
    handleChange(event);
    setUsp('');
  }


  function handleFacilityChange(event) {
    setFacility({ ...facility, name: event.target.value });
  }

  function handleFacilityModify(value, index) {
    const newFacilities = values.facilities.map((facility, currentIndex) => {
      if (currentIndex === index) {
        return { ...facility, value: value };
      }
      return facility;
    });
    const event = {
      target: {
        name: 'facilities',
        value: newFacilities,
      },
    };
    handleChange(event);
  }

  function handleAddFacility() {
    if (facility.name.length < 1) {
      setFacilityError('Veld mag niet leeg zijn ');
      return null;
    }
    if (facilityError && facility.name.length > 0) {
      setFacilityError(null);
    }

    const trimmedFacility = { ...facility, value: facility.name.trim() };
    const event = {
      target: {
        name: 'facilities',
        value: [...values.facilities, trimmedFacility],
      },
    };
    handleChange(event);
    setFacility({ name: '', value: false });
  }

  async function handleStarsChange(e) {
    setStars(e);
    handleChange(createEventTarget('stars', e));
  }

  async function handleUpdateAccommodation(callback) {
    const response = await callback(formatForUpdate(values));
    //Als de mutation createAccommodation is dan wordt er geredirect na het aanmaken naar het updaten.
    if (response.data.createAccommodation) {
      history.push(`/content/accommodations/${response.data.createAccommodation.accommodation.id}`);
    }
  }

  function handleCompletion() {
    setSuccessMessage('Accommodatie details zijn succesvol opgeslagen');
    setTimeout(() => setSuccessMessage(null), 3000);
  }

  function handleError() {
    setErrorMessage('Oeps, er ging iets mis');
  }

  return (
    <Mutation mutation={mutation} onCompleted={handleCompletion} onError={handleError}>
      {updateAccommodation => (
        <Card
          errors={errors}
          title="Accommodatie details"
          subTitle="Waardering, beoordeling en usp's"
          thumbnailSrc={DetailIcon}
          onSave={() => handleUpdateAccommodation(updateAccommodation)}
          uniqueId="details"
          errorMessage={errorMessage}
          successMessage={successMessage}
          hideSaveButton={Boolean(!values.id)}
          disabled={false}
        >
          <Row form>
            <Col lg={10}>
              <FormGroup>
                <Label for="intro" size="sm">
                  Waardering
                </Label>
                <br />
                <StarRatings
                  rating={stars}
                  changeRating={handleStarsChange}
                  numberOfStars={5}
                  name="stars"
                  starEmptyColor="#E1E1E1"
                  starHoverColor="#B10000"
                  starRatedColor="#c20000"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col lg={2}>
              <FormGroup>
                <Label for="intro" size="sm">
                  Beoordeling
                </Label>
                <Input
                  type="number"
                  name="rating"
                  id="rating"
                  // step="0.1"
                  value={values.rating}
                  onChange={handleChange}
                />
              </FormGroup>
            </Col>
          </Row>

          <hr/>

          <Row form>
            <Col s={10} md={12} lg={12} xl={8}>
              <Row>
                <Col>
                  <h6>Faciliteiten</h6>
                </Col>
              </Row>
              <Row>
                {values.facilities.length > 0 ? (
                  values.facilities.map((facility, index) => {
                    return (
                      <Col md={6}>
                        <FormGroup key={index} check>
                          <Label check>
                            <Input
                              checked={facility.value}
                              onChange={event => handleFacilityModify(event.target.checked, index)}
                              type="checkbox"
                            />
                            {facility.name}
                          </Label>
                        </FormGroup>
                      </Col>
                    );
                  })
                ) : (
                  <p>Voeg eerst een faciliteit toe.</p>
                )}
              </Row>
            </Col>
          </Row>

          <Row form style={{marginTop: '15px'}}>
            <Col md={6} lg={4}>
              <FormGroup>
                <Input
                  type="text"
                  name="facility"
                  id="facility"
                  // step="0.1"
                  value={facility.name}
                  onChange={handleFacilityChange}
                />
              </FormGroup>
              {facilityError && <p style={{ color: 'red' }}>{facilityError}</p>}
            </Col>

            <Col>
              <Button outline color="secondary" onClick={handleAddFacility}>
                <FontAwesomeIcon icon="plus-circle" />
                Toevoegen
              </Button>
            </Col>
          </Row>

          <hr />
            <Row>
              <Col>
                <h6>Usp's</h6>
              </Col>
            </Row>


          {values.usps.length > 0 ? (
            values.usps.map((usp, index) => {
              return (
                <Row form>
                  <Col md={6} lg={8}>
                    <FormGroup>
                      <Input
                        type="text"
                        name="usp"
                        id="usp"
                        value={usp}
                        onChange={event => handleUspModify(event.target.value, index)}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <LengthValidator countThisValue={usp} maxValue={25} />
                  </Col>
                </Row>
              );
            })
          ) : (
            <p>Voeg eerst een usp toe</p>
          )}

          <Row form>
            <Col md={6} lg={4}>
              <FormGroup>
                <Input type="text" name="usp" id="usp" value={usp} onChange={handleUspChange} />
              </FormGroup>
            </Col>
           <Col md={1} lg={1}>
               <LengthValidator countThisValue={usp} maxValue={25} />
           </Col>

            <Col>
              <Button outline color="secondary" onClick={handleAddUsp}>
                <FontAwesomeIcon icon="plus-circle" />
                Toevoegen
              </Button>
            </Col>
            <Col xs={12}>{uspError && <p style={{ color: 'red' }}>{uspError}</p>}</Col>
          </Row>
        </Card>
      )}
    </Mutation>
  );
},areEqual );


function areEqual(prevProps, nextProps) {
  return JSON.stringify(prevProps.values.facilities) === JSON.stringify(nextProps.values.facilities) &&
      JSON.stringify(prevProps.values.usps) === JSON.stringify(nextProps.values.usps) &
      prevProps.values.rating === nextProps.values.rating
}


export default withRouter(AccommodationDetails);
