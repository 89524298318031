import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Card from 'components/Card';
import { Row, Col, Label, Input, FormGroup, Button, Alert } from 'reactstrap';
import { UPDATE_DAILY_DEAL } from './apollo';

import { Mutation } from 'react-apollo';
import { Field, Formik } from 'formik';
import {
  formatInitialDailyDeal,
  getAsync,
  formatForUpdateDailyDeal,
  validation,
  getResettedValues,
} from './helpers';
import AsyncSelect from 'react-select/async';
import debounce from 'debounce-promise';
import MessageIcon from '../../../../assets/icons/message-icon.svg';

const DailyDeal = ({ dailyDeal }) => {
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleCompletion = () => {
    setSuccessMessage('Deal van de dag is succesvol opgeslagen');
    setTimeout(() => setSuccessMessage(null), 3000);
  };

  const handleError = () => {
    setErrorMessage('Er is iets mis gegaan tijdens het opslaan');
    setTimeout(() => setSuccessMessage(null), 3000);
  };

  const loadDeals = inputValue => getAsync(inputValue, 'filterDeal', 'slug');

  const debouncedDeals = debounce(loadDeals, 500, {
    leading: true,
  });

  const resetFields = (setValues, values) => {
    setValues(getResettedValues(values));
  };

  return (
    <Mutation mutation={UPDATE_DAILY_DEAL} onCompleted={handleCompletion} onError={handleError}>
      {updateDailyDeal => (
        <Formik
          validationSchema={validation}
          enableReinitialize={true}
          initialValues={formatInitialDailyDeal(dailyDeal)}
          onSubmit={(values, { setValues }) => {
            updateDailyDeal(formatForUpdateDailyDeal(values));
          }}
        >
          {props => {
            const { values, errors, handleChange, handleSubmit, setValues } = props;
            return (
              <Card
                thumbnailSrc={MessageIcon}
                disabled={Object.keys(errors).length > 0}
                title={dailyDeal.day}
                subTitle={values.title}
                onSave={() => handleSubmit()}
                uniqueId={dailyDeal.day}
                errorMessage={errorMessage}
                successMessage={successMessage}
                hideSaveButton={Boolean(!values.id)}
              >
                <Row>
                  <Col>
                    <FormGroup>
                      <Label for="title" size="sm">
                        PUSH DATUM
                      </Label>
                      <Input type="date" name="push_date" tag={Field} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label for="dailydeal" size="sm">
                        DEAL VAN DE DAG <span style={{ color: 'red' }}>*</span>
                      </Label>
                      <AsyncSelect
                        name="dailydeal"
                        cacheOptions
                        name="parent"
                        loadOptions={debouncedDeals}
                        onChange={value => {
                          setValues({
                            ...values,
                            deal: value,
                            title: value.label,
                            push_title: value.label,
                            image_url: '',
                          });
                        }}
                        value={values.deal}
                        noOptionsMessage={() => null}
                      />
                      {errors.deal && <p style={{ color: 'red' }}>{errors.deal}</p>}
                    </FormGroup>
                    {values.deal ? (
                      <a href={`/deals/${values.deal.value}`} target="_blank">
                        Bekijk deal
                      </a>
                    ) : null}
                    {values.automatic && <Alert color="warning">
                      Let op: bij qenner deals kun je alleen de [prijs] shortcode gebruiken
                    </Alert>}
                  </Col>
                </Row>

                <Row>
                  <Col lg={12}>
                    <FormGroup>
                      <Label for="title" size="sm">
                        PRIJS
                      </Label>
                      <Input name="price" tag={Field} type="number" />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col lg={12}>
                    <FormGroup>
                      <Label for="title" size="sm">
                        TITEL
                      </Label>
                      <Input name="title" tag={Field} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <FormGroup style={{ marginLeft: '20px' }}>
                      <Label check>
                        <Input
                          checked={values.direct_link}
                          name="direct_link"
                          tag={Field}
                          type="checkbox"
                        />{' '}
                        Direct naar partner
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <FormGroup>
                      <Label for="direct_link_partner" size="sm">
                        Directe link naar partner
                      </Label>
                      <Input
                        name="direct_link_partner"
                        tag={Field}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Button
                    color="danger"
                    className="ml-auto"
                    onClick={() => resetFields(setValues, values)}
                  >
                    Reset velden
                  </Button>
                </Row>
              </Card>
            );
          }}
        </Formik>
      )}
    </Mutation>
  );
};

DailyDeal.propTypes = {
  dailyDeal: PropTypes.object.isRequired,
};

export default DailyDeal;
