import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FILTER_PRICES } from 'queries/deals';
import {
  Alert,
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Popover,
  PopoverBody,
  Row,
} from 'reactstrap';
import Select from 'react-select';
import { formatDepatureAirports } from 'containers/Deal/DealDetails/functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Query } from 'react-apollo';
import { createEventTarget } from 'utils/helpers';
import useFormValidation from 'hooks/useFormValidation';
import PropTypes from 'prop-types';
import PriceRow from './HighlightedPricesModalCSS';
import { Field } from 'formik';
import { setRem } from 'styles/styles';
import { generateBitlyLink } from './helpers.js';
import moment from 'moment';
export function formatForFilter(values) {
  const date_from_eq = values.date_from ? moment(values.date_from).format('YYYY-MM-DDTHH:mm:ss.sss') : null;
  const date_to_eq = values.date_to ? moment(values.date_to).format('YYYY-MM-DDTHH:mm:ss.sss') : null;
  const variables = {
    where: {
      concept: values.concept ? values.concept : null,
      date_from_eq,
      date_to_eq,
      board_type: values.board_type ? values.board_type.value : null,
      departureairport: values.departureairport ? values.departureairport.value : null,
      day_count: values.day_count ? Number(values.day_count) : null,
      persons: values.persons ? Number(values.persons) : null,
      provider: values.provider ? values.provider.value : null,
    },
    start: values.start,
    limit: values.limit,
  };
  return variables;
}

const initialPrice = {
  deals: '',
  board_type: '',
  date_from: '',
  date_to: '',
  departureairport: '',
  day_count: '',
  persons: '',
  provider: '',
  start: 0,
  limit: 5,
};

function shouldILoadMore(data) {
  const {
    pricesConnection: {
      aggregate: { count },
    },
    prices,
  } = data;
  return prices.length < count;
}

const boardTypesEnums = [
  { label: 'Logies', value: 'LG' },
  { label: 'Half pension', value: 'HP' },
  { label: 'Logies en Onbijt', value: 'LO' },
  { label: 'Vol pension', value: 'VP' },
  { label: 'All inclusive', value: 'AI' },
  { label: 'Ultra inclusive', value: 'UA' },
];

function formatInitalProviders(providers) {
  return providers.map(provider => ({ label: provider.name, value: provider.id }));
}

const HighlightedPriceModal = ({
  slug = '',
  providers,
  airports,
  dealId,
  qennerDeeplink = false,
  boardTypes,
  onClose
}) => {
  const [successMessage, setSuccessMessage] = useState(null);
  const { setValues, handleChange, values } = useFormValidation({ ...initialPrice, deals: dealId });
  const [modalVisible, setModalVisible] = useState(false);
  const [qennerUrl, setQennerUrl] = useState({
    qenner: `https://weflycheap.nl/${slug ? `${slug}/` : ''}`,
    bitly: '',
  });
  const [qennerUrlError, setQennerUrlError] = useState(false);
  const [copySuccessQenner, setCopySuccessQenner] = useState(false);
  const [copySuccessBitly, setCopySuccessBitly] = useState(false);

  const [selectedPrice, setSelectedPrice] = useState(null)

  useEffect(() => {
    if (selectedPrice) {
      generateUrl();
    }
  }, [selectedPrice])

  function handleDepartureAirportChange(value) {
    const newValue = value ? value : null;
    handleChange(createEventTarget('departureairport', newValue));
  }

  function handleBoardTypeChange(event) {
    const newEvent = {
      target: {
        name: 'board_type',
        value: event,
      },
    };
    handleChange(newEvent);
  }

  function handleProviderChange(event) {
    const newEvent = {
      target: {
        name: 'provider',
        value: event,
      },
    };
    handleChange(newEvent);
  }

  function toggleModal() {
    setModalVisible(!modalVisible);
    if (modalVisible) {
      setValues({ ...initialPrice, deals: dealId });
    }
    if (onClose) {
      onClose();
    }
  }

  let variables = formatForFilter(values);

  async function generateUrl() {

    const unformattedParams = {
      departure_airport: selectedPrice.departureairport ? selectedPrice.departureairport.iata : '',
      arrival_airport: selectedPrice.arrival_airport ? selectedPrice.arrival_airport.iata : '',
      date_from: selectedPrice.date_from ? moment(selectedPrice.date_from).format('YYYY-MM-DD') : '',
      transport_type: selectedPrice.transport_type ? selectedPrice.transport_type : '',
      day_count: selectedPrice.day_count,
      persons: selectedPrice.persons,
      board_type: selectedPrice.board_type ? selectedPrice.board_type.value : '',
    };

    const entries = Object.entries(unformattedParams);
    const params = entries.filter(item => {
      return item[1] ? true : false;
    });

    const generatedParams = params.map(item => {
      return `${item[0]}=${item[1]}`;
    });

    const finishedUrl = generatedParams.join('&');

    const longUrl = `https://weflycheap.nl/${slug ? `${slug}/` : ''}${finishedUrl ? `?${finishedUrl}` : ''}`;
    setQennerUrl({ qenner: longUrl, bitly: qennerUrl.bitly ? qennerUrl.bitly : '' })

    //get bitly url
    //set bitly url in state
  }

  async function generateBitlyUrl() {
    try {
      const bitlyUrl = await generateBitlyLink(qennerUrl.qenner);
      setQennerUrl({ qenner: qennerUrl.qenner ? qennerUrl.qenner : '', bitly: bitlyUrl })
    } catch (e) {
      setQennerUrlError(true);
    }
  }

  function copyUrl(e, name) {
    const textArea = document.createElement('textarea');

    document.body.appendChild(textArea);
    if (name === 'qenner') {
      textArea.value = qennerUrl.qenner;
    }
    if (name === 'bitly') {
      textArea.value = qennerUrl.bitly;
    }
    textArea.select();

    try {
      if (name === 'qenner') {
        setCopySuccessQenner(true);
        document.execCommand('copy');
        document.body.removeChild(textArea);
        setTimeout(() => {
          setCopySuccessQenner(false);
        }, 2000);
      }
      if (name === 'bitly') {
        setCopySuccessBitly(true);
        document.execCommand('copy');
        document.body.removeChild(textArea);
        setTimeout(() => {
          setCopySuccessBitly(false);
        }, 2000);
      }
    } catch (err) { }
    e.target.focus();
  }

  return (
    <>
      <FormGroup>
        {!qennerDeeplink && (
          <>
            <Label for="discount_expire" size="sm">
              Uitgelichte prijs
            </Label>
            <br />
          </>
        )}

        <Button
          name="handmatig-uitgelicht-toevoegen"
          outline={!qennerDeeplink}
          color={qennerDeeplink ? 'success' : 'secondary'}
          onClick={toggleModal}
        >
          {!qennerDeeplink && <FontAwesomeIcon icon="plus-circle" className="mr-2" />}
          {qennerDeeplink ? 'Maak qenner deeplink' : 'toevoegen'}
        </Button>
      </FormGroup>

      <Modal isOpen={modalVisible} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>Maak qenner deeplink</ModalHeader>
        <ModalBody>
          <Row>
            <Col xs={12}>
              <h6>Filter</h6>
            </Col>
          </Row>
          <Row>
            <Col md={6} lg={4}>
              <FormGroup>
                <Label for="discount" size="sm">
                  Verblijfstype
                </Label>
                <Select
                  options={boardTypesEnums}
                  onChange={handleBoardTypeChange}
                  value={values.board_type}
                  name="board_type"
                  id="board_type"
                />
              </FormGroup>
            </Col>

            <Col md={6} lg={4}>
              <FormGroup>
                <Label for="date" size="sm">
                  Vertrekdatum
                </Label>
                <Input
                  type="date"
                  id="date_from"
                  name="date_from"
                  autoComplete="off"
                  placeholder="Selecteer een datum"
                  value={values.date_from}
                  onChange={handleChange}
                />
              </FormGroup>
            </Col>

            <Col md={6} lg={4}>
              <FormGroup>
                <Label for="date" size="sm">
                  Aankomstdatum
                </Label>
                <Input
                  type="date"
                  id="date_to"
                  name="date_to"
                  autoComplete="off"
                  placeholder="Selecteer een datum"
                  value={values.date_to}
                  onChange={handleChange}
                />
              </FormGroup>
            </Col>

            <Col md={6} lg={4}>
              <FormGroup>
                <Label for="discount" size="sm">
                  Luchthaven
                </Label>
                <Select
                  value={values.departureairport}
                  onChange={handleDepartureAirportChange}
                  options={formatDepatureAirports(airports)}
                  name="departureairport"
                  isSearchable={false}
                  placeholder="Luchthaven"
                  noOptionsMessage={() => null}
                />
              </FormGroup>
            </Col>

            <Col md={6} lg={4}>
              <FormGroup>
                <Label for="date" size="sm">
                  Dagen
                </Label>
                <Input
                  type="number"
                  id="day_count"
                  name="day_count"
                  autoComplete="off"
                  placeholder="Vul aantal dagen in"
                  onChange={handleChange}
                  value={values.day_count}
                />
              </FormGroup>
            </Col>

            <Col md={6} lg={4}>
              <FormGroup>
                <Label for="date" size="sm">
                  Personen
                </Label>
                <Input
                  type="number"
                  id="persons"
                  name="persons"
                  autoComplete="off"
                  placeholder="Aantal personen"
                  onChange={handleChange}
                  value={values.persons}
                />
              </FormGroup>
            </Col>

          </Row>

          <Row>
            <Col xs={12}>
              <h6>Gevonden prijzen</h6>
            </Col>
          </Row>

          <Query query={FILTER_PRICES} variables={variables}>
            {({ loading, error, data, fetchMore }) => {
              if (error) return `Error! ${error.message}`;
              if (loading) return <p>Laden...</p>;
              if(data && !data.prices) return <p>Er zijn geen prijzen gevonden</p> 
              if (data && data.prices && data.prices.length < 1) return <p>Er zijn geen prijzen gevonden</p>;
              shouldILoadMore(data);

              return (
                <>
                  <Row>
                    <Col xs={12} md={8}>
                      {data.prices.map(price => (
                        <PriceRow
                          variables={variables}
                          qennerPrice={price}
                          successMessage={successMessage}
                          setSuccessMessage={setSuccessMessage}
                          setSelectedPrice={setSelectedPrice}
                        >
                          {price.id}
                        </PriceRow>
                      ))}
                    </Col>
                    <Col md={4}>
                      {' '}
                      {successMessage && (
                        <Alert color="success">Geselecteerde item is succesvol opgeslagen</Alert>
                      )}
                    </Col>
                  </Row>
                  {shouldILoadMore(data) && (
                    <Row>
                      <Col md={2}>
                        <LoadMore
                          onClick={() => {
                            setValues({ ...values, limit: values.limit + 5 });
                          }}
                        >
                          Meer ..
                        </LoadMore>
                      </Col>
                    </Row>
                  )}
                </>
              );
            }}
          </Query>

          <hr />

          <Row>
            {qennerUrlError && (
              <Alert color="danger">Er ging iets mis tijdens het genereren van bitly url</Alert>
            )}
          </Row>
          <Row>
            <Col md={8}>
              <FormGroup>
                <Input
                  tag={Field}
                  type="text"
                  id="qenner"
                  name="qenner"
                  onChange={e => setQennerUrl({ ...qennerUrl, qenner: e.target.value })}
                  value={qennerUrl.qenner}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Popover placement="right" isOpen={copySuccessQenner} target="copyUrl">
                  <PopoverBody>Gekopieerd!</PopoverBody>
                </Popover>
                <Button id="copyUrl" outline color="secondary" onClick={e => copyUrl(e, 'qenner')}>
                  <FontAwesomeIcon icon="plus-circle" /> Kopieren
                </Button>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={3}>
              <Button style={{ width: '100%' }} color="success" onClick={generateBitlyUrl} disabled={!selectedPrice}>
                Genereer Bitly URL
              </Button>
            </Col>
            <Col md={5}>
              <FormGroup>
                <Input disabled={true} type="text" id="bity" name="bitly" value={qennerUrl.bitly} />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Popover placement="right" isOpen={copySuccessBitly} target="copyBitly">
                  <PopoverBody>Gekopieerd!</PopoverBody>
                </Popover>
                <Button id="copyBitly" outline color="secondary" onClick={e => copyUrl(e, 'bitly')}>
                  <FontAwesomeIcon icon="plus-circle" /> Kopieren
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={toggleModal}>
            Opslaan
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            Annuleren
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const LoadMore = styled.span`
  font-size: ${setRem(18)};
  color: #dddddd;
  &:hover {
    cursor: pointer;
  }
`;

HighlightedPriceModal.propTypes = {
  dealId: PropTypes.string.isRequired,
  providers: PropTypes.array.isRequired,
  airports: PropTypes.array.isRequired,
  qennerDeeplink: PropTypes.bool,
  slug: PropTypes.string,
};

export default HighlightedPriceModal;
