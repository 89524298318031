import React from 'react';
import styled from 'styled-components';
import { setRem, setShadow } from 'styles/styles';
import { Alert, Spinner } from 'reactstrap';
import moment from 'moment';
import { FILTER_PRICES, GET_PRICES, UPDATE_PRICE } from "queries/deals";
import { Mutation } from "react-apollo";

const PriceRow = ({ qennerPrice, variables, setSuccessMessage, setSelectedPrice }) => {
  const { date_from, date_to, price, id, highlight, day_count } = qennerPrice;
  const priceDeals = (qennerPrice && qennerPrice.deals && (qennerPrice.deals.length > 0)) ? [qennerPrice.deals[0].id] : null;

  function handleCompletion(response) {
    
    setSuccessMessage('Wijziging is succesvol opgeslagen')
    setTimeout(() => setSuccessMessage(null), 1500)
  }
  

  return (
    <Mutation mutation={UPDATE_PRICE} onCompleted={(response)=>handleCompletion(response)} variables={{ id: id, highlight: !highlight, deals: priceDeals }} refetchQueries={() => {
      return [
        {
          query: FILTER_PRICES,
          variables: { ...variables },
        },
        {
          query: GET_PRICES,
          variables: { dealId: variables.deals, manual_price: true },
        }
      ];
    }}  >
      {(updatePrice, { loading, error }) => (
        <>
        
          {error && (<Alert color="danger">
            Er ging iets mis tijdens het opslaan
              </Alert>)}
          <StyledPriceRow onClick={() => setSelectedPrice(qennerPrice)} isHighLighted={highlight} >
            {loading ? <Spinner color="light" /> : (
              <>
                <Item>
                  {date_from ? moment(date_from).format('D MMM YY') : 'geen vert. datum'} {` - `}
                  {date_to ? moment(date_to).format('D MMM YY') : 'geen retour datum'} {` - `}
                  {day_count ? `${day_count} dagen` : ''}
                </Item>
                <Item>€{price}</Item>
              </>
            )}
          </StyledPriceRow>
        </>
      )}
    </Mutation>
  );
};

const StyledPriceRow = styled.div`
  color: ${(props) => props.isHighLighted ? 'rgb(66,66,66)' : '#626262'};
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 100%;
  margin: ${setRem(20)} 0;
  padding: ${setRem(10)} ${setRem(20)};
  border: 1px #ced4da solid;
  border-radius: 3px;
  box-shadow: ${setShadow.dark};
  background-color: ${(props) => props.isHighLighted ? '#e6e7ef' : 'white'};
  
  &:hover{
    background-color: #edeef6;
    cursor: pointer;
  }
`;

const Item = styled.div`
  color: #404040;
  font-size: ${setRem(16)};
`;

export default PriceRow;
