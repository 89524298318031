import React, { useState } from 'react';
import { FILTER_DEALS, GET_USERS, GET_PROVIDERS } from './apollo';
import { Col, Input, Row, Badge, Container, FormGroup } from 'reactstrap';
import Table from 'components/Table';
import Search from 'components/Search';
import { Query } from 'react-apollo';
import {
  createSearchWhereQuery,
  getConceptParams,
  getAllParams,
  getPublishedParams,
  getExpiredParams,
} from './helper';

export const defaultSearchQuery = {
  where: {
    is_published_deal_ne: true,
    is_saved_eq: true
  },
  sort: 'publish_date:desc',
  start: 0,
  limit: 20,
};

if(localStorage.getItem('country_id')){
  defaultSearchQuery.where.website = localStorage.getItem('country_id');
}

export function prepareSearchQueryForFilter(searchQuery) {
  if (searchQuery.where.dealtypes) {
    return {
      ...searchQuery,
      where: { ...searchQuery.where, dealtypes: searchQuery.where.dealtypes.id },
    };
  }
  return searchQuery;
}

function firstLetterUpperCase(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const DealsOverview = () => {
  const [searchQuery, setSearchQuery] = useState(defaultSearchQuery);
  let dealCount = 0;
  const [pageNumber, setPageNumber] = useState(1);

  function handleNewSearchTextValue(value) {
    let newWhere = { ...searchQuery.where };
    setPageNumber(1);
    if(value.length < 1 ){
      delete newWhere._q
    } else{
      newWhere._q = value;
    }
    setSearchQuery({ ...searchQuery, where: newWhere, start: 0 });
  }

  function handleNewSelectedDealtype(value) {
    setPageNumber(1);
    const newWhere = { ...searchQuery.where, dealtypes: value };
    setSearchQuery({ ...searchQuery, where: newWhere, start: 0 });
  }

  const handleStatusChange = event => {
    switch (event.target.value) {
      case 'all':
        setSearchQuery(createSearchWhereQuery(getAllParams(), searchQuery));
        break;
      case 'concept':
        setSearchQuery(createSearchWhereQuery(getConceptParams(), searchQuery));
        break;
      case 'published':
        setSearchQuery(createSearchWhereQuery(getPublishedParams(), searchQuery));
        break;
      case 'expired':
        setSearchQuery(createSearchWhereQuery(getExpiredParams(), searchQuery));
        break;
      default:
    }
    setPageNumber(1);
  };

  function handleDealTypeChange(event) {
    function getValue(value) {
      if (value === 'null') {
        return null;
      }
      return value;
    }
    const newSearchQuery = {
      ...searchQuery,
      where: { ...searchQuery.where },
      start: 0,
    };

    if(getValue(event.target.value) !== null){
      newSearchQuery.where.automatic = getValue(event.target.value);
    } else {
      delete newSearchQuery.where.automatic;
    }

    setSearchQuery(newSearchQuery);
    setPageNumber(1);
  }

  function handleAuthorChange(event) {
    let newWhere = { ...searchQuery.where };

    setSearchQuery({
      ...searchQuery,
      where: newWhere,
      start: 0,
    });
  }

  const handleProviderChange = (event) => {
    let newWhere = { ...searchQuery.where };
    if(event.target.value) {
      newWhere.providers_in = [event.target.value];
    } else {
      delete newWhere.providers_in;
    }

    setSearchQuery({
      ...searchQuery,
      where: newWhere,
      start: 0,
    });
  };

  function hanlePaginationChange(type) {
    let newSearchQuery = { ...searchQuery };
    if (type === 'next') {
      setPageNumber(pageNumber + 1);
      newSearchQuery.start = newSearchQuery.start + newSearchQuery.limit;
    } else {
      setPageNumber(pageNumber - 1);
      newSearchQuery.start = newSearchQuery.start - newSearchQuery.limit;
    }
    setSearchQuery(newSearchQuery);
  }

  return (
    <Row>
      <Col
        lg={2}
        className="px-0"
        style={{
          borderRight: '1px solid rgba(0,0,0,0.24)',
          backgroundColor: 'white',
          minHeight: '100%',
        }}
      >
        <Row>
          <Col className="pt-5">
            <div className="px-5">
              <h4 className="mb-4">Filter deals</h4>
              {/* <h6>TYPE DEAL</h6> */}
            </div>
            {/* <ListGroup className="own-list-group mb-0">
              <NavLink to="/deals" activeClassName="active-list-group-item">
                <ListGroupItem>
                  <span>Vakanties</span>
                </ListGroupItem>
              </NavLink>
              <NavLink activeClassName="active-list-group-item">
                <ListGroupItem>
                  <span>Stedentrips</span>
                </ListGroupItem>
              </NavLink>
              <NavLink activeClassName="active-list-group-item">
                <ListGroupItem>
                  <span>Vliegreizen</span>
                </ListGroupItem>
              </NavLink>
            </ListGroup> */}
          </Col>
        </Row>
        {/* <hr /> */}
        <Row className="px-3">
          <Col className="px-5 pt-2">
            <h6>AUTEUR</h6>
            <Query query={GET_USERS} fetchPolicy="">
              {({ loading, error, data }) => {
                if (loading) return <p>laden ...</p>;
                if (error) return <p>er ging iets mis</p>;
                return (
                  <>
                    {data && data.users && (
                      <FormGroup>
                        <Input
                          type="select"
                          name="select"
                          id="exampleSelect"
                          onChange={handleAuthorChange}
                        >
                          <option value="">Alle content tijgers</option>
                          {data.users.map(user => (
                            <option key={user.id} value={user.id}>
                              {firstLetterUpperCase(user.username)}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    )}
                  </>
                );
              }}
            </Query>
          </Col>
        </Row>
        <hr />
        <Row className="px-3">
          <Col className="px-5 pt-2">
            <h6>SOORT DEAL</h6>
            <FormGroup>
              <Input type="select" name="select" id="exampleSelect" onChange={handleDealTypeChange}>
                <option value="null">Toon alles</option>
                <option value="true">Automatisch</option>
                <option value="false">Handmatig</option>
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <hr />
        <Row className="px-3">
          <Col className="px-5 pt-2">
            <h6>STATUS</h6>
            <FormGroup>
              <Input type="select" name="select" id="exampleSelect" onChange={handleStatusChange}>
                <option value="all">Toon alles</option>
                <option value="concept">Concept</option>
                <option value="published">Gepubliceerd</option>
                <option value="expired">Verlopen</option>
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <hr />
        <Row className="px-3">
          <Col className="px-5 pt-2">
            <h6>AANBIEDER</h6>
            <Query query={GET_PROVIDERS}>
              {({ loading, error, data }) => {
                if (loading) return <p>laden ...</p>;
                if (error) return <p>er ging iets mis</p>;
                return (
                    <>
                      {data && data.providers && (
                          <FormGroup>
                            <Input
                                type="select"
                                name="select"
                                id="exampleSelect"
                                onChange={handleProviderChange}
                            >
                              <option value="">Alle aanbieders</option>
                              {data.providers.map(provider => (
                                  <option key={provider.id} value={provider.id}>
                                    {provider.name}
                                  </option>
                              ))}
                            </Input>
                          </FormGroup>
                      )}
                    </>
                );
              }}
            </Query>
          </Col>
        </Row>
      </Col>
      <Col lg={10} style={{ backgroundColor: '#FBFBFD', minHeight: '100vh' }} className="p-5">
        <Container>
          <Row className="d-flex justify-content-center">
            <Col lg={12}>
              <Row className="d-flex justify-content-center">
                <Col lg={8} className="mb-5">
                  <Search
                    handleNewSearchTextValue={handleNewSearchTextValue}
                    handleNewSelectedDealtype={handleNewSelectedDealtype}
                    isUsedInDeals={true}
                  />
                </Col>
                <Col lg={12}>
                  {JSON.stringify(searchQuery) !== JSON.stringify(defaultSearchQuery) && (
                    <div className="d-flex mb-3 align-items-center">
                      {searchQuery.where.search_contains !== '' && (
                        <span className="mr-2">Gezocht voor: </span>
                      )}
                      <h5 className="mb-0">
                        <Badge color="primary" pill style={{ fontWeight: '100' }}>
                          {searchQuery.where.search_contains}
                        </Badge>
                      </h5>
                      {searchQuery.where.dealtypes && (
                        <>
                          {searchQuery.where.dealtypes !== '' &&
                            searchQuery.where.search_contains !== '' && (
                              <span className="mx-2">in:</span>
                            )}
                          {searchQuery.where.dealtypes !== '' &&
                            searchQuery.where.search_contains === '' && (
                              <span className="mx-2">Geslecteerd:</span>
                            )}
                          <h5 className="mb-0">
                            <Badge color="primary" pill style={{ fontWeight: '100' }}>
                              {searchQuery.where.dealtypes.name}
                            </Badge>
                          </h5>
                        </>
                      )}
                    </div>
                  )}
                  {JSON.stringify(searchQuery) === JSON.stringify(defaultSearchQuery) && (
                    <div className="d-flex mb-3 align-items-center"></div>
                  )}

                  <Query query={FILTER_DEALS} variables={prepareSearchQueryForFilter(searchQuery)} fetchPolicy="cache-and-network" >
                    {({ loading, error, data }) => {
                      if (error) return `Error! ${error.message}`;
                      if (data) {
                        if (data.dealConceptsConnection) {
                          // setDealCount(data.dealsConnection.aggregate.count);
                          dealCount = data.dealConceptsConnection.aggregate.count;
                        }
                        // if (data.deals && data.deals[0] && data.deals[0].website.id !== localStorage.getItem('country_id')){
                        //   window.location.reload();
                        // }

                        return (
                            <Table
                                searchQueryVariables={prepareSearchQueryForFilter(searchQuery)}
                                title="Deals"
                                data={data.dealConcepts}
                                loading={loading}
                                url="/content/accommodations"
                                paginationOnClickPrevious={() => hanlePaginationChange('previous')}
                                paginationOnClickNext={() => hanlePaginationChange('next')}
                                start={searchQuery.start}
                                limit={searchQuery.limit}
                                pageNumber={pageNumber}
                                total={dealCount}
                                disablePrevious={Boolean(searchQuery.start === 0)}
                                disableNext={Boolean(
                                    searchQuery.start > dealCount - searchQuery.limit ||
                                    searchQuery.limit >= dealCount,
                                )}
                                pagination={true}
                            />
                        );

                      }

                      return null;


                    }}
                  </Query>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Col>
    </Row>
  );
};

export default DealsOverview;
