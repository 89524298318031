import React, { memo } from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';

export const InputField = memo(
  ({ value, error, touched, handleChange, handleBlur, name, type, label, placeholder }) => {
    return (
      <FormGroup>
        <Label for="days" size="sm">
          {label}
        </Label>
        <Input
          onChange={handleChange}
          onBlur={handleBlur}
          value={value}
          type={type}
          name={name}
          invalid={Boolean(error && touched)}
          autoComplete="off"
          placeholder={placeholder}
        />
        {error && touched && <FormFeedback>{error}</FormFeedback>}
      </FormGroup>
    );
  },
  areEqual,
);

function areEqual(prevProps, nextProps) {
  return (
    JSON.stringify(prevProps.touched) === JSON.stringify(nextProps.touched) &&
    JSON.stringify(prevProps.error) === JSON.stringify(nextProps.error) &&
    JSON.stringify(prevProps.value) === JSON.stringify(nextProps.value)
  );
}
