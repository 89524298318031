import React from 'react';
import { FormGroup, Input, Label, Col, Row } from 'reactstrap';
import DealImg from 'components/DealImg';

const BookingExample = ({
  loading,
  error,
  bookingExample,
  removeBookingExample,
  index,
  onChange,
}) => {
  function handleChange(event) {
    onChange(event, index);
  }

  function handleRemoveBookingExample() {
    removeBookingExample(index);
  }

  return (
    <div style={{ marginTop: '1rem' }}>
      <Row>
        <Col lg={3}>
          <DealImg
            src={bookingExample.localImageUrl}
            handleOnClickDelete={handleRemoveBookingExample}
          />
        </Col>
        <Col lg={8}>
          <Row>
            <Col xs={12}>
              <FormGroup>
                <Label for="name">Titel</Label>
                <Input
                  disabled={loading || error}
                  type="text"
                  onChange={handleChange}
                  value={bookingExample.title}
                  name="title"
                  id="title"
                  placeholder="titel afbeelding"
                />
              </FormGroup>
            </Col>
            <Col xs={12}>
              <FormGroup>
                <Label for="url">Deeplink url</Label>
                <Input
                  disabled={loading || error}
                  onChange={handleChange}
                  value={bookingExample.url}
                  type="text"
                  name="url"
                  id="url"
                  placeholder="Vul hier deeplink url in"
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default BookingExample;
