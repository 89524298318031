import gql from "graphql-tag";

export const UPDATE_RSS_SETTINGS = gql`
    mutation updateRssSettings( $id: ID!, $campaign_name: String, $campaign_source: String) {
        updateRsssetting(input: {where: {id: $id}, data: { campaign_name: $campaign_name, campaign_source: $campaign_source }}) {
            rsssetting{
                id
                website{
                    id
                }
                campaign_name
                campaign_source
            }
        }
    }
`;

export const GET_RSS_SETTINGS = gql`
    query rssSettings($where: JSON) {
        rsssettings(where: $where){
            id
            website{
                id
            }
            campaign_name
            campaign_source
        }
    }
`;

