import React, { useState } from 'react';
import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Row,
  Col,
  Spinner,
  FormGroup,
  Label,
  Input, Alert,
} from 'reactstrap';
import Dropzone from 'react-dropzone';
import DealImg from 'components/DealImg';
import axios from 'axios';
import { createEventTarget } from 'utils/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImport } from '@fortawesome/free-solid-svg-icons';
const baseUrl = process.env.REACT_APP_BASE_URL;

function checkIfDuplicateExists(w){

  return new Set(w).size !== w.length
}

const UploadModal = ({ handleChange, toggleModal, isModalVisible, values, type }) => {
  const [toBeUploadedImages, setToBeUploadedImages] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [altError, setAltError] = useState(null);

  function onDrop(acceptedFiles) {
    const result = acceptedFiles.map(localFile => {
      const localImageUrl = URL.createObjectURL(localFile);
      return {
        name: localFile.name.split('.').slice(0, -1).join('.').replace(/_/g, ' ').replace(/-/g, ' '),
        localImageUrl,
        localFile,
      };
    });

    setToBeUploadedImages([...toBeUploadedImages, ...result]);
  }

  function handleOnClickDelete(index) {
    let newMedia = [...toBeUploadedImages];
    newMedia.splice(index, 1);
    setToBeUploadedImages(newMedia);
  }

  function handleFileNameChange(event, index) {
    let newImages = [...toBeUploadedImages];
    let toBeChangedImage = newImages[index];
    toBeChangedImage.name = event.target.value;
    toBeChangedImage.localFile.alt = event.target.value;
    setToBeUploadedImages(newImages);
  }

  function createPromises(files, token) {
    return files.map(file => {
      let formData = new FormData();
      formData.append('files', file);
      formData.append('alt', file.name.split('.').slice(0, -1).join('.').replace(/_/g, ' ').replace(/-/g, ' '));
      formData.append('type', type ? type : 'other');
      return axios({
        url: `${baseUrl}/upload`,
        method: 'POST',
        headers: {
          authorization: `Bearer ${token}`,
        },
        data: formData,
      });
    });
  }

  async function handleImageUpload() {
    const token = localStorage.getItem('token');
    const files = toBeUploadedImages.map(image => image.localFile);

    const alts = toBeUploadedImages.map(file => file.name);
    const duplicateExists = checkIfDuplicateExists(alts);
    if(duplicateExists){
      setAltError('Titels moeten uniek zijn');
      return null
    }

    if(altError){
      setAltError(null)
    }

    const promises = createPromises(files, token);
    setIsUploading(true);
    const results = await Promise.all(promises);

    const uploadedImages = files.map((file, index) => {
      return {
        name: toBeUploadedImages[index].name,
        destination: values.id,
        id: results[index].data[0].id,
        thumb: results[index].data[0].thumb,
      };
    });

    let newMedia = [...values.media];
    newMedia = newMedia.concat(uploadedImages);
    await handleChange(createEventTarget('media', newMedia));
    setIsUploading(false);
    setToBeUploadedImages([]);
    toggleModal();
  }

  return (
    <Modal size="lg" isOpen={isModalVisible} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>Afbeelding uploaden</ModalHeader>
      <ModalBody>
        <Dropzone onDrop={acceptedFiles => onDrop(acceptedFiles)}>
          {({ getRootProps, getInputProps }) => (
            <section>
              <div
                style={{
                  padding: '2rem 3rem',
                  border: 'dashed 2px rgba(0, 0, 0, 0.24)',
                  textAlign: 'center',
                }}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <FontAwesomeIcon
                  icon={faFileImport}
                  size="3x"
                  className="mx-auto mb-3"
                  style={{ color: '#6c757d' }}
                />
                <p className="mb-0">Sleep een afbeelding hier naar toe, of klik op mij!</p>
              </div>
            </section>
          )}
        </Dropzone>
        <Row>
          <Col>
            {altError && <Alert color="danger">{altError}</Alert>}
          </Col>
        </Row>
        <Row className="mt-3">
          {toBeUploadedImages.length > 0 &&
            toBeUploadedImages.map((image, index) => (
              <Col key={index} lg={12}>
                <Row className="my-3">
                  <Col lg={4}>
                    <DealImg
                      src={image.localImageUrl}
                      handleOnClickDelete={() => handleOnClickDelete(index)}
                    />
                  </Col>
                  <Col lg={8}>
                    <FormGroup>
                      <Label size="sm">Titel</Label>
                      <Input
                        type="text"
                        name="name"
                        value={image.name}
                        onChange={e => handleFileNameChange(e, index)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            ))}
        </Row>
        <Button
          color="success"
          onClick={handleImageUpload}
          disabled={
            isUploading || toBeUploadedImages.filter(image => image.name.length < 2).length > 0
          }
        >
          {isUploading && <Spinner color="white" size="sm" />} Foto's uploaden
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default UploadModal;
