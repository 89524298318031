import React, { useState } from 'react';
import RSSIcon from "assets/icons/rss-icon.svg";
import { Button } from 'reactstrap';
import Card from 'components/Card';
import debounce from 'debounce-promise';
import { getDealsAsync } from 'utils/helpers';
import { UPDATE_RSS_COLUMNS } from './apollo';
import {
  TITLES,
  formatInitialValues,
  formatForUpdate,
  initialColumnDeal,
  validation,
} from './helpers';

//Components
import { Formik } from 'formik';
import { Mutation } from 'react-apollo';
import ColumnDeal from '../ColumnDeal';

const RssColumn = ({ positionType, columnDeals, rssColumnId }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const handleCompletion = event => {
    setSuccessMessage('Wijzigingen zijn succesvol opgeslagen');
    setTimeout(() => {
      setSuccessMessage(null);
    }, 3000);
  };

  const handleError = event => {
    setSuccessMessage('Er is iets misgegaan tijdens het opslaan');
    setTimeout(() => {
      setErrorMessage(null);
    }, 3000);
  };

  const handleFormErrors = event => {
    setErrorMessage('Vul a.u.b. alle verplichte velden in!');
    setTimeout(() => {
      setErrorMessage(null);
    }, 3000);
  };

  const loadDeals = inputValue => getDealsAsync(inputValue, 'filterDeal', 'slug');
  const debouncedDeals = debounce(loadDeals, 500, {
    leading: true,
  });

  const title = TITLES[positionType];

  const addColumnDeal = (values, callback) => {
    const newValues = { ...values, columnDeals: [...values.columnDeals, initialColumnDeal] };
    callback(newValues);
  };

  const deleteColumnDeal = (index, values, callback) => {
    const newColumnDeals = [...values.columnDeals];
    newColumnDeals.splice(index, 1);
    const newValues = { ...values, columnDeals: newColumnDeals };
    callback(newValues);
  };


  return (
    <Mutation
      mutation={UPDATE_RSS_COLUMNS}
      onCompleted={handleCompletion}
      onError={handleError}
      fetchPolicy="no-cache"
    >
      {updateRssColumn => (
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={validation}
          enableReinitialize={false}
          initialValues={formatInitialValues(columnDeals)}
          onSubmit={values => {
            updateRssColumn(formatForUpdate(rssColumnId, values.columnDeals));
          }}
        >
          {props => {
            const { setValues, values, errors, handleChange, handleSubmit, validateForm } = props;
            if (values.length < 1) return <p>Er zijn nog geen column_deals ingesteld</p>;
            return (
              <Card
                errorMessage={errorMessage}
                successMessage={successMessage}
                thumbnailSrc={RSSIcon}
                title={title}
                uniqueId={positionType}
                onSave={() => validateForm().then((errors) => {
                  if(errors && errors.columnDeals){
                    handleFormErrors();
                  } else {
                    handleSubmit()
                  }
                })}
              >
                {values.columnDeals.length > 0 &&
                  values.columnDeals.map((columnDeal, index) => (
                    <ColumnDeal
                      key={columnDeal.id}
                      columnDeal={columnDeal}
                      index={index}
                      handleChange={handleChange}
                      values={values}
                      errors={errors}
                      deleteColumnDeal={columnDealIndex =>
                        deleteColumnDeal(columnDealIndex, values, setValues)
                      }
                      initialVisible={columnDeal.initialVisible}
                    />
                  ))}
                <Button
                  style={{ marginRight: '10px' }}
                  color="success"
                  onClick={() => addColumnDeal(values, setValues)}
                >
                  + Deal
                </Button>
              </Card>
            );
          }}
        </Formik>
      )}
    </Mutation>
  );
};

export default RssColumn;
