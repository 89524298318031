import React, { useState } from 'react';
import { Query, Mutation } from 'react-apollo';
import { GET_ACCOMMODATION, UPDATE_ACCOMMODATION, CREATE_ACCOMMODATION } from 'queries/deals';
import { Col, Row, Alert, Spinner } from 'reactstrap';
import { Formik, Form } from 'formik';
import AccommodationIntroduction from 'containers/Content/Accommodations/AccommodationIntroduction';
import AccommodationImages from 'containers/Content/Accommodations/AccommodationImages';
import AccommodationDetails from 'containers/Content/Accommodations/AccommodationDetails';

const initialFacilities = [
  { name: '24 uur receptie', value: false },
  { name: 'Entertainment', value: false },
  { name: 'Wifi op de kamer', value: false },
  { name: 'Kindvriendelijk', value: false },
  { name: 'Wifi in openbare ruimte', value: false },
  { name: 'Ligging in het centrum', value: false },
];

let accommodation = {
  id: '',
  name: '',
  description: '',
  stars: 0,
  rating: 0,
  facilities: initialFacilities,
  usps: '',
};

function formatInitialAccommodation(values) {
  let accommodation = {};
  accommodation.id = values.id;
  accommodation.name = values.name ? values.name : '';
  accommodation.description = values.description ? values.description : '';
  accommodation.stars = values.stars ? values.stars : 0;
  accommodation.rating = values.rating ? values.rating : 0;
  accommodation.media = values.media ? values.media : [];
  accommodation.usps = values.usps ? JSON.parse(values.usps) : [];
  accommodation.facilities = values.facilities ? JSON.parse(values.facilities) : initialFacilities;
  return accommodation;
}

const AccommodationUpdate = ({ match, history, usedInModal }) => {
  const mutation =
    match.params.id !== 'new' && !usedInModal ? UPDATE_ACCOMMODATION : CREATE_ACCOMMODATION;
  const [displayUpdateSuccess, setDisplayUpdateSuccess] = useState(false);
  const [succesMessage, setSuccessMessage] = useState('');

  function formatForUpdate(values) {
    return {
      variables: {
        id: values.id !== '' ? values.id : null,
        name: values.name,
        description: values.description,
        stars: values.stars,
        rating: values.rating,
        media: values.media.map(media => media.id),
        facilities: values.facilities ? JSON.stringify(values.facilities) : null,
        usps: values.usps ? JSON.stringify(values.usps) : null,
      },
    };
  }

  //Als er op nieuwe bestemming wordt geklikt als de gebruiker al op deze pagina zit dan moeten deze waardes weer leeg gemaakt worden
  if (match.params.id === 'new') {
    accommodation.id = '';
    accommodation.name = '';
    accommodation.description = '';
    accommodation.stars = 0;
    accommodation.rating = 0;
    accommodation.media = [];
  }

  function handleCompletion() {
    setSuccessMessage('Accommodatie succesvol opgeslagen');
    setDisplayUpdateSuccess(true);
    setTimeout(() => {
      setSuccessMessage(null);
      setDisplayUpdateSuccess(false);
    }, 3000);
  }

  async function handleUpdateAccommodation(values, callback) {
    const response = await callback(formatForUpdate(values));
    if (response && match.params.id === 'new') {
      history.push(`/content/accommodations/${response.data.createAccommodation.accommodation.id}`);
    }
  }

  return (
    <Query query={GET_ACCOMMODATION} variables={{ id: match.params.id }}>
      {({ loading, errors, data }) => {
        if (loading) return <Spinner type="grow" color="primary" />;
        if (match.params.id !== 'new') {
          if (errors) return `Error! ${errors.message}`;

          if (data.accommodation) {
            accommodation = formatInitialAccommodation(data.accommodation);
          }
        }

        return (
          data && (
            <Mutation mutation={mutation} onCompleted={handleCompletion}>
              {updateAccommodation => (
                <Formik
                  enableReinitialize={false}
                  initialValues={accommodation}
                  onSubmit={values => handleUpdateAccommodation(values, updateAccommodation)}
                >
                  {props => {
                    const { values, errors, handleChange, handleSubmit } = props;

                    return (
                      <Form onSubmit={handleSubmit}>
                        {displayUpdateSuccess && <Alert color="success">{succesMessage}</Alert>}
                        {!data.accommodation && match.params.id !== 'new' ? (
                          <Alert color="danger">Oeps deze accommodatie is niet gevonden.</Alert>
                        ) : (
                          <Row className="d-flex justify-content-center">
                            <Col lg={12}>
                              <AccommodationIntroduction
                                values={values}
                                errors={errors}
                                handleChange={handleChange}
                              />
                              {values.id && (
                                <>
                                  <AccommodationDetails
                                    values={values}
                                    errors={errors}
                                    handleChange={handleChange}
                                  />
                                  <AccommodationImages
                                    values={values}
                                    errors={errors}
                                    handleChange={handleChange}
                                  />
                                </>
                              )}
                            </Col>
                          </Row>
                        )}
                      </Form>
                    );
                  }}
                </Formik>
              )}
            </Mutation>
          )
        );
      }}
    </Query>
  );
};

export default AccommodationUpdate;
