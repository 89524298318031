import gql from 'graphql-tag';

export const UPDATE_DAILY_DEAL = gql`
  mutation UpdateDailyDeal(
    $id: ID!
    $deal: ID
    $title: String
    $push_title: String
    $intro: String
    $push_text: String
    $image_url: String
    $direct_link: Boolean
    $direct_link_partner: String
    $url: String
    $push_date: DateTime
    $price: Float
  ) {
    updateDailydeal(
      input: {
        where: { id: $id }
        data: {
          deal: $deal
          title: $title
          push_title: $push_title
          intro: $intro
          push_text: $push_text
          image_url: $image_url
          direct_link: $direct_link
          direct_link_partner: $direct_link_partner
          url: $url
          push_date: $push_date
          price: $price
        }
      }
    ) {
      dailydeal {
        id
      }
    }
  }
`;
