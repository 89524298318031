import * as Yup from 'yup';

const errorText = {
  required: 'Verplicht Veld',
};

export const automatic_validation = Yup.object().shape({
    // arrival_airport: Yup.string().nullable().required('Verplicht veld'),
    dealtypes: Yup.array().min(1),
    destination: Yup.object()
      .nullable()
      .required('Selecteer een bestemming')
  });

  export default automatic_validation;
