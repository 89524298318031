import React, { useState } from 'react';
import { StyledTable, TableRow, TableImg, Pagination } from './TableCSS';
import {
  Container,
  Button,
  Row,
  Col,
  Spinner,
  ButtonGroup,
  Input,
  FormGroup,
  Table as BootstrapTable,
  Alert,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft, faCircle } from '@fortawesome/free-solid-svg-icons';
import Tooltip from 'components/Tooltip';
import PropTypes from 'prop-types';
import moment from 'moment';
import notfound from 'assets/notfound.png';
// import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, Input, FormGroup, Label } from 'reactstrap';
import { Mutation} from 'react-apollo';
import { UPDATE_DEAlSTATUS } from 'queries/deals';

const statusEnums = [
  { name: 'Gepubliceerd', value: 'published' },
  { name: 'Verlopen', value: 'expired' },
  { name: 'Concept', value: 'concept' },
];

// { name: 'Gepubliceerd', value: {published: false, expire_date: moment().subtract(1, 'days')}},
// { name: 'Verlopen', value: {published: true, expire_date: moment().subtract(1, 'days')} },
// { name: 'Concept', value: {published: false }},

const createUpdateObject = (id, published, expire_date) => {
  return {
    id,
    published,
    expire_date,
  };
};

const formatForUpdateStatus = (selected, id) => {
  let expire_date = new Date();
  if (selected === 'published') {
    expire_date.setDate(expire_date.getDate() + 14);
    return createUpdateObject(id, true, expire_date);
  }
  if (selected === 'expired') {
    expire_date.setDate(expire_date.getDate() - 1);
    return createUpdateObject(id, true, expire_date);
  }
  if (selected === 'concept') {
    return createUpdateObject(id, false, expire_date);
  }
};

const Table = ({
  title,
  data,
  url,
  loading,
  paginationOnClickPrevious,
  paginationOnClickNext,
  total,
  disableNext,
  disablePrevious,
  start,
  pageNumber,
  pagination,
  searchQueryVariables,
}) => {
  // const [dropDownOpen, setDropDownOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const getStatus = (published, expireDate) => {
    // const date = moment(expireDate).format('DD-MM-YYYY');
    if (published && expireDate) {
      const date = moment(expireDate);
      if (date.diff(moment(), 'days') < 0) {
        // const test = date.diff(moment(), 'days');
        return statusEnums.find(status => status.value === 'expired');
      }
    }

    if(published){
      return statusEnums.find(status => status.value === 'published');
    }

    return statusEnums.find(status => status.value === 'concept');
  };

  function handleError(event) {
    setErrorMessage('Status is successvol bijgewerkt');
    setTimeout(() => setErrorMessage(null), 2000);
  }

  function handleSuccess(event) {
    setSuccessMessage('Status is successvol bijgewerkt');
    setTimeout(() => setSuccessMessage(null), 2000);
  }

  return (
    <div>
      {/*<div style={{ overflowX: 'scroll' }}>   For mobile/tablet vie*/}
      {successMessage && (
        <>
          <Alert color="success">
            Status is succesvol geupdatet
          </Alert>
          <br />
        </>
      )}
      {errorMessage && (
        <>
          <Alert color="danger">Er is iets mis gegaan tijdens het updaten van de status</Alert>
          <br />
        </>
      )}
      <Container>
        <BootstrapTable
          style={{
            backgroundColor: '#fff',
            borderRadius: '4px',
            boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
          }}
        >
          <thead>
            {title !== 'Deals' && (
              <tr>
                <th
                  className="py-4"
                  style={{
                    fontWeight: 'normal',
                    color: '#6B6C6F',
                    borderTop: '0px',
                    borderBottom: '0px',
                    paddingLeft: '30px',
                  }}
                >
                  {title}
                </th>
              </tr>
            )}
            {title === 'Deals' && (
              <tr>
                <th
                  className="py-4"
                  style={{ fontWeight: 'normal', color: '#6B6C6F', borderTop: '0px' }}
                ></th>
                <th
                  className="py-4"
                  style={{ fontWeight: 'normal', color: '#6B6C6F', borderTop: '0px' }}
                >
                  Titel
                </th>
                <th
                  className="py-4"
                  style={{ fontWeight: 'normal', color: '#6B6C6F', borderTop: '0px' }}
                >
                  Bestemming
                </th>
                <th
                  className="py-4"
                  style={{ fontWeight: 'normal', color: '#6B6C6F', borderTop: '0px' }}
                >
                  Automatisch
                </th>
                <th
                  className="py-4"
                  style={{ fontWeight: 'normal', color: '#6B6C6F', borderTop: '0px' }}
                >
                  Publicatie
                </th>
                <th
                  className="py-4"
                  style={{ fontWeight: 'normal', color: '#6B6C6F', borderTop: '0px' }}
                >
                  Prijs
                </th>
                <th
                  className="py-4"
                  style={{ fontWeight: 'normal', color: '#6B6C6F', borderTop: '0px' }}
                >
                  Status
                </th>
                <th
                  className="py-4"
                  style={{ fontWeight: 'normal', color: '#6B6C6F', borderTop: '0px' }}
                >
                  Bewerken
                </th>
              </tr>
            )}
          </thead>
          <tbody>
            <>
              {loading && (
                <tr>
                  <Spinner
                    type="grow"
                    color="primary"
                    className="mx-auto"
                    style={{ position: 'absolute', left: '0', right: '0' }}
                  />
                </tr>
              )}
              <tr>
              {data &&
                title !== 'Deals' &&
                data.map((item, index) => (
                  <StyledTable key={index}>
                    <TableRow key={index} style={{ opacity: loading ? '.8' : '1' }}>
                      <Row>
                        <Col lg={9} className="d-flex align-items-center">
                          {item.media && (
                            <TableImg src={item.media[0] ? item.media[0].thumb : notfound} />
                          )}
                          {item.name && <span>{item.name}</span>}
                          {item.title && <span>{item.title}</span>}
                        </Col>
                        <Col lg={3} className="d-flex align-items-center justify-content-end">
                          <Link to={`${url}/${item.id}`}>
                            <Button outline>Bewerken</Button>
                          </Link>
                        </Col>
                      </Row>
                    </TableRow>
                  </StyledTable>
                ))}
              </tr>
              {data &&
                title === 'Deals' &&
                data.map((item, index) => (
                  <tr key={index} style={{ opacity: loading ? '.8' : '1' }}>
                    <th scope="row" className="pl-5">
                      {item.media && (
                        <TableImg
                          src={item.media[0] ? item.media[0].thumb : notfound}
                        />
                      )}
                    </th>
                    <td
                      style={{ verticalAlign: 'middle', borderColor: '#EAEDF3', color: '#9EA0A5' }}
                    >
                      {item.accommodation && (
                        <Tooltip
                          text={item.title}
                          tooltipText={item.accommodation.name}
                          index={index}
                        />
                      )}
                      {!item.accommodation && <span>{item.title}</span>}
                    </td>

                    <td
                      style={{ verticalAlign: 'middle', borderColor: '#EAEDF3', color: '#9EA0A5' }}
                    >
                      {item.destination && <span>{item.destination.name}</span>}
                      {!item.destination && <span>*</span>}
                    </td>

                    <td
                      style={{ verticalAlign: 'middle', borderColor: '#EAEDF3', color: '#9EA0A5' }}
                    >
                      {item.automatic && <span>Automatisch</span>}
                      {!item.automatic && <span>Handmatig</span>}
                    </td>
                    <td
                      style={{ verticalAlign: 'middle', borderColor: '#EAEDF3', color: '#9EA0A5' }}
                    >
                      {item.publish_date && (
                        <span>
                          {moment(item.publish_date)
                            .format('DD-MM-YYYY')
                            .toLocaleString()}
                        </span>
                      )}
                      {!item.publish_date && <span>*</span>}
                    </td>
                    <td
                      style={{ verticalAlign: 'middle', borderColor: '#EAEDF3', color: '#9EA0A5' }}
                    >
                      {item.price && <span>{item.price}</span>}
                      {!item.price && <span>*</span>}
                    </td>
                    <td
                      style={{ verticalAlign: 'middle', borderColor: '#EAEDF3', color: '#9EA0A5' }}
                    >
                      <Mutation
                        mutation={UPDATE_DEAlSTATUS}
                        onCompleted={handleSuccess}
                        onError={handleError}
                      >
                        {(updateStatus, { loading }) => {
                          return (
                            <>
                              {/*Dropdown to change status of 'status'*/}
                              <FormGroup style={{ minWidth: '100px' }} className="mb-0">
                                <Input
                                  // disabled={loading}
                                  type="select"
                                  name="select"
                                  disabled={
                                    loading || Boolean(getStatus(item.published, item.expire_date).value === 'concept')
                                  }
                                  onChange={event => {
                                    updateStatus({
                                      variables: formatForUpdateStatus(event.target.value, item.id),
                                    });
                                  }}
                                  // defaultValue={currentStatus}
                                  value={getStatus(item.published, item.expire_date).value}
                                >
                                  Status{' '}
                                  <FontAwesomeIcon
                                    icon={faCircle}
                                    size="xs"
                                    className={`${
                                      item.status === 'published' ? 'text-success' : 'text-danger'
                                    } mr-2`}
                                  />
                                  */}
                                  {loading && <Spinner size="sm" color="light" />}
                                  {statusEnums.map(singleEnum => {
                                    return (
                                      <option value={singleEnum.value}>{singleEnum.name}</option>
                                    );
                                  })}
                                </Input>
                              </FormGroup>
                            </>
                          );
                        }}
                      </Mutation>
                    </td>
                    <td
                      style={{ verticalAlign: 'middle', borderColor: '#EAEDF3', color: '#9EA0A5' }}
                    >
                      <Link to={`deals/${item.id}`}>
                        <Button outline>Bewerken</Button>
                      </Link>
                    </td>
                  </tr>
                ))}
              {!loading && data && data.length === 0 && (
                <tr>
                  <TableRow>
                    <Row>
                      <Col lg={9} className="d-flex align-items-center py-3">
                        <span>Geen resultaten</span>
                      </Col>
                    </Row>
                  </TableRow>
                </tr>
              )}
            </>
          </tbody>
        </BootstrapTable>
        <Row className="d-flex justify-content-end">
          {data && pagination && (
            <Pagination>
              <Row className="d-flex justify-content-end">
                <Col lg={3} className="d-flex align-items-center">
                  <strong>{start + 1}&nbsp;</strong>t/m&nbsp;
                  <strong>{start + data.length}&nbsp;</strong> van&nbsp;
                  <strong>{total}</strong>
                </Col>
                <Col
                  lg={{ size: 3, offset: 6 }}
                  className="d-flex justify-content-end align-items-center"
                >
                  <ButtonGroup size="sm">
                    <Button
                      color="primary"
                      onClick={paginationOnClickPrevious}
                      disabled={disablePrevious}
                    >
                      <FontAwesomeIcon icon={faChevronLeft} />
                    </Button>
                    <Button color="primary" disabled>
                      {pageNumber}
                    </Button>
                    <Button color="primary" onClick={paginationOnClickNext} disabled={disableNext}>
                      <FontAwesomeIcon icon={faChevronRight} />
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </Pagination>
          )}
        </Row>
      </Container>
    </div>
  );
};

Table.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array,
  url: PropTypes.string,
};

export default Table;
