import React, { useState, memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Mutation, Query } from 'react-apollo';
import {
  UPDATE_DEAL,
  GET_DEALPROVIDERS_OF_DEAL,
  UPDATE_DEALPROVIDERS,
  UPDATE_DEALURLS,
} from 'queries/deals';
import { DOES_SLUG_EXISTS } from './apollo';
import { isUrl } from './helpers';
import axios from 'axios';
import useDebounce from 'hooks/useDebounce';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Col,
  Row,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
  PopoverBody,
  Popover,
  FormFeedback,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import { Formik } from 'formik';
import UrlIcon from 'assets/icons/url-icon.svg';
import Card from 'components/Card';
import HighlightedPriceModal from 'containers/Deal/HighlightedPriceModal';
import { Link } from 'react-router-dom';
import slugify from 'slugify';

const slugifyConfig = {
  strict: true,
  lower: true,
  trim: false,
};

function compare(a, b) {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
}

function sortDealProviders(dealProviders) {
  return dealProviders.sort(compare);
}

function formatInitialDealProviders(dealProviders) {
  if (dealProviders.length < 1) {
    return [];
  }
  return dealProviders.map(provider => {
    return {
      id: provider.id,
      deals: provider.deals[0].id,
      provider: provider.provider.id,
      deeplink: provider.deeplink,
      active: provider.active,
      original_link: provider.original_link,
      not_affiliate: provider.not_affiliate,
      is_deleted: false,
    };
  });
}

const DealUrls = memo(
  ({
    dealId,
    allProviders,
    airports,
    boardTypes,
    slug = '',
    isAutomaticDeal,
    initialData,
    isValid,
    validValues,
  }) => {
    const [isGenerating, setIsGenerating] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [shortAffiliateUrl, setShortAffiliateUrl] = useState(null);
    const [shortDealUrl, setShortDealUrl] = useState(
      `https://weflycheap.${
        initialData.country_code ? initialData.country_code : 'geen-land-ingesteld'
      }?p=${initialData.wordpress_id ? initialData.wordpress_id : 'nog-niet-gepubliceerd'}`,
    );
    const [activeDealproviderDeeplink, setActiveDealproviderDeeplink] = useState(null);
    const [affiliateUrl, setAffiliateUrl] = useState(
      initialData.affiliate_url ? initialData.affiliate_url : '',
    );
    const [affiliateUrlError, setAffiliateUrlError] = useState(null);
    const [currentSlug, setCurrentSlug] = useState(slug ? slug : '');
    const [fullUrl, setFullUrl] = useState(
      `https://www.weflycheap.${
        initialData.country_code ? initialData.country_code : 'geen-land-ingesteld'
      }/deal/${currentSlug ? currentSlug : ''}`,
    );
    const [bitlyError, setBitlyError] = useState(null);
    const [showSlugWarning, setShowSlugWarning] = useState(false);

    const [fullUrlPopoverOpen, setFullUrlPopoverOpen] = useState(false);
    const [shortUrlPopoverOpen, setShortUrlPopoverOpen] = useState(false);
    const [previewPopoverOpen, setPreviewPopoverOpen] = useState(false);
    const [shortDeeplinkPopoverOpen, setShortDeeplinkPopoverOpen] = useState(false);
    const { debouncedValue, count } = useDebounce(currentSlug, 300);

    useEffect(() => {
      if (!slug) {
        isValid(false);
      }
    }, []);

    //maakt een tijdelijke textarea aan om de url in je clipboard te plaatsen
    function copyUrlToClipboard(urlType) {
      const textArea = document.createElement('textarea');
      // const baseUrl = 'https://www.weflycheap.nl/';
      switch (urlType) {
        case 'full':
          // textArea.value = baseUrl + values.slug;
          textArea.value = fullUrl;
          setFullUrlPopoverOpen(true);
          setTimeout(() => setFullUrlPopoverOpen(false), 3000);
          break;
        case 'affiliate':
          textArea.value = 'https://www.tradetracker.nl/affiliate/link/die/er/nog/niet/is';
          break;
        case 'short':
          // textArea.value = baseUrl + '?p=' + values.id;
          textArea.value = shortDealUrl;
          setShortUrlPopoverOpen(true);
          setTimeout(() => setShortUrlPopoverOpen(false), 3000);
          break;
        case 'short-deeplink':
          // textArea.value = baseUrl + '?p=' + values.id;
          textArea.value = shortAffiliateUrl;
          setShortDeeplinkPopoverOpen(true);
          setTimeout(() => setShortDeeplinkPopoverOpen(false), 3000);
          break;
        case 'wfc-short-url':
          // textArea.value = baseUrl + '?p=' + values.id;
          textArea.value = initialData?.hashed_slug
            ? `https://weflycheap.nl/d/${initialData?.hashed_slug}`
            : '';
          setShortDeeplinkPopoverOpen(true);
          setTimeout(() => setShortDeeplinkPopoverOpen(false), 3000);
          break;
        case 'preview-url':
          // textArea.value = baseUrl + '?p=' + values.id;
          textArea.value = `https://weflycheap.nl/deal/preview/?concept=${currentSlug}`;
          setPreviewPopoverOpen(true);
          setTimeout(() => setPreviewPopoverOpen(false), 3000);
          break;
        default:
        //do nothing
      }
      document.body.appendChild(textArea);
      textArea.select();
      let successful = false;
      try {
        successful = document.execCommand('copy');
        document.body.removeChild(textArea);
      } catch (err) {}
      return successful;
    }

    const handleAffiliateUrlChange = event => {
      const value = event.target.value;
      setAffiliateUrl(value);
      if (!value) {
        isValid(false);
        setAffiliateUrlError('Veld mag niet leeg zijn');
      } else {
        if (!isUrl(value)) {
          isValid(false);
          setAffiliateUrlError('Url moet met https:// beginnen');
          return null;
        }
        isValid(true);
        validValues({ affiliate_url: value });
        if (affiliateUrlError) setAffiliateUrlError(null);
      }
    };

    function handleUpdateCompletion() {
      setSuccessMessage('Urls is succesvol geupdated');
      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
    }

    function handleSlugChange(event) {
      if (!event.target.value || event.target.value === '') {
        setShowSlugWarning(true);
        isValid(false);
      } else {
        setShowSlugWarning(false);
        if (affiliateUrl) {
          isValid(true);
        }
      }

      setCurrentSlug(slugify(event.target.value, slugifyConfig));

      setFullUrl(
        `https://www.weflycheap.${
          initialData.country_code ? initialData.country_code : 'geen-land-ingesteld'
        }/deal/${event.target.value}`,
      );
    }

    function handlePromotionChange(handleChange, values, index) {
      const newValues = values.dealproviders.map((provider, providerIndex) => {
        if (providerIndex === index) {
          return {
            ...provider,
            active: true,
          };
        }
        return {
          ...provider,
          active: false,
        };
      });

      const event = {
        target: {
          name: 'dealproviders',
          value: newValues,
        },
      };

      setActiveDealproviderDeeplink(
        newValues.find(dealprovider => dealprovider.active).deeplink
          ? newValues.find(dealprovider => dealprovider.active).deeplink
          : null,
      );
      handleChange(event);
    }

    function generatePromiseForBitly(long_url) {
      let formData = {};
      formData.long_url = long_url;
      let promis = axios({
        url: 'https://api-ssl.bitly.com/v4/bitlinks',
        method: 'POST',
        headers: {
          authorization: 'Bearer 6c682be4489633f00e1a9ecb7c6d28ed6b48e707',
          'Content-Type': 'application/json',
        },
        data: formData,
      });
      return promis;
    }

    async function generateBitlyLink(type) {
      if (affiliateUrlError) {
        setBitlyError('Vul aub eerst een geldige affiliate link in');
        return null;
      }
      if (bitlyError) {
        setBitlyError(null);
      }

      if (!affiliateUrlError) {
        setBitlyError(null);
        const promises = generatePromiseForBitly(
          type === 'dealurl' ? 'https://weflycheap./langeurl' : affiliateUrl,
        );
        try {
          setIsGenerating(true);
          const results = await Promise.resolve(promises);
          if (type === 'dealurl') {
            setShortDealUrl(results.data.link);
          } else {
            setShortAffiliateUrl(results.data.link);
          }
        } catch (e) {
          setBitlyError('Er ging iets mis tijdens het genereren van de link');
        }
        setIsGenerating(false);
      }
    }

    function handleError() {
      setErrorMessage('Er ging iets mis tijdens het updaten');
    }

    let initialDealProviders = [];

    return (
      <>
        <Mutation mutation={UPDATE_DEAL} onCompleted={handleUpdateCompletion} onError={handleError}>
          {updateAffiliateUrl => (
            <Mutation
              mutation={UPDATE_DEALPROVIDERS}
              onCompleted={handleUpdateCompletion}
              onError={handleError}
              refetchQueries={() => {
                return [
                  {
                    query: GET_DEALPROVIDERS_OF_DEAL,
                    variables: { dealId: dealId },
                  },
                ];
              }}
            >
              {updateDealProviders => (
                <Mutation
                  mutation={UPDATE_DEALURLS}
                  onCompleted={handleUpdateCompletion}
                  onError={handleError}
                >
                  {updateDealUrls => (
                    <Formik
                      enableReinitialize={true}
                      initialValues={initialDealProviders}
                      onSubmit={(values, { setValues }) => {
                        updateAffiliateUrl({
                          variables: {
                            id: dealId,
                            affiliate_url: affiliateUrl.trim(),
                            slug: currentSlug,
                          },
                        });
                      }}
                    >
                      {props => {
                        const { values, handleChange, handleBlur, handleSubmit } = props;
                        return (
                          <Card
                            successMessage={successMessage}
                            errorMessage={errorMessage}
                            title="Urls"
                            subTitle={`https://www.weflycheap.${
                              initialData.country_code
                                ? initialData.country_code
                                : 'geen-land-ingesteld'
                            }/${dealId}`}
                            onSave={handleSubmit}
                            thumbnailSrc={UrlIcon}
                            uniqueId="Urls"
                            disabled={!affiliateUrl || !currentSlug}
                            hideSaveButton={affiliateUrlError}
                          >
                            {isAutomaticDeal && (
                              <Row>
                                <Col md={4}>
                                  <HighlightedPriceModal
                                    slug={slug}
                                    boardTypes={boardTypes}
                                    qennerDeeplink={true}
                                    airports={airports}
                                    providers={allProviders}
                                    dealId={dealId}
                                  />
                                </Col>
                              </Row>
                            )}
                            <Query
                              fetchPolicy="no-cache"
                              query={GET_DEALPROVIDERS_OF_DEAL}
                              onError={e => {}}
                              variables={{ id: dealId }}
                              onCompleted={event => {
                                handleChange({
                                  target: {
                                    name: 'dealproviders',
                                    value: formatInitialDealProviders(
                                      event && event.dealConcept.dealProviders
                                        ? event.dealConcept.dealproviders
                                        : [],
                                    ),
                                  },
                                });
                              }}
                            >
                              {({ loading, error, data }) => {
                                if (error) {
                                }
                                if (error) return <p>Error...</p>;

                                if (loading) return <Spinner type="grow" color="primary" />;
                                if (data && data.dealConcept.dealproviders) {
                                  const activeDealProvider = data.dealConcept.dealproviders.find(
                                    dealprovider => dealprovider.active === true,
                                  );
                                  if (activeDealProvider && !activeDealproviderDeeplink) {
                                    setActiveDealproviderDeeplink(
                                      data.dealConcept.dealproviders.find(
                                        dealprovider => dealprovider.active,
                                      ).deeplink,
                                    );
                                  }
                                  if (!data) return null;
                                  return (
                                    <>
                                      <Row form>
                                        <Col lg={6} sm={12}>
                                          <Query
                                            fetchPolicy="no-cache"
                                            query={DOES_SLUG_EXISTS}
                                            onError={e => {}}
                                            variables={{
                                              dealWhere: {
                                                slug: debouncedValue.trim(),
                                                is_published_deal_ne: true,
                                              },
                                              pageWhere: {
                                                slug: debouncedValue.trim(),
                                              },
                                            }}
                                          >
                                            {({ loading, error, data }) => {
                                              return (
                                                <FormGroup>
                                                  <Label>Slug</Label>
                                                  {!loading &&
                                                    debouncedValue.length > 0 &&
                                                    data?.deals?.length > 0 &&
                                                    data?.deals[0]?.id !== dealId && (
                                                      <p style={{ color: 'red' }}>
                                                        Deze slug bestaat al.{' '}
                                                        <a
                                                          target="_blank"
                                                          href={`/deals/${data.deals[0].id}`}
                                                        >
                                                          Bekijk deal
                                                        </a>
                                                      </p>
                                                    )}
                                                  {!loading &&
                                                    debouncedValue.length > 0 &&
                                                    data?.pages?.length > 0 && (
                                                      <p style={{ color: 'red' }}>
                                                        Deze slug is al in gebruik bij een Page{' '}
                                                      </p>
                                                    )}
                                                  {console.log('DATA', data)}
                                                  <InputGroup size="lg">
                                                    <Input
                                                      name="slug"
                                                      onChange={handleSlugChange}
                                                      value={currentSlug}
                                                      placeholder="Vul een slug in"
                                                      // disabled={Boolean(initialData.slug)}
                                                    />
                                                    <InputGroupAddon addonType="append">
                                                      {!loading &&
                                                        debouncedValue.length > 0 &&
                                                        data?.deals.length > 0 &&
                                                        data?.deals[0]?.id !== dealId && (
                                                          <Button disabled={true} color={'danger'}>
                                                            bestaat al
                                                          </Button>
                                                        )}
                                                    </InputGroupAddon>
                                                  </InputGroup>
                                                  {showSlugWarning && (
                                                    <FormFeedback style={{ display: 'block' }}>
                                                      Slug is verplicht
                                                    </FormFeedback>
                                                  )}
                                                  {error && (
                                                    <FormFeedback style={{ display: 'block' }}>
                                                      Er ging iets mis tijdens het valideren van de
                                                      slug.
                                                    </FormFeedback>
                                                  )}
                                                </FormGroup>
                                              );
                                            }}
                                          </Query>

                                          <FormGroup>
                                            <Label for="fullUrl" size="sm">
                                              DEAL URL
                                            </Label>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                              <Input
                                                type="text"
                                                id="fullUrl"
                                                name="fullUrl"
                                                value={`https://www.weflycheap.${
                                                  initialData.country_code
                                                    ? initialData.country_code
                                                    : 'geen-land-ingesteld'
                                                }/deal/${currentSlug}`}
                                                disabled={true}
                                              />
                                              <div>
                                                <Button
                                                  outline
                                                  color="secondary"
                                                  className="ml-3 d-flex align-items-center"
                                                  type="button"
                                                  onClick={() => copyUrlToClipboard('full')}
                                                  id="FullUrlCopyButton"
                                                >
                                                  <Popover
                                                    placement="right"
                                                    isOpen={fullUrlPopoverOpen}
                                                    target="FullUrlCopyButton"
                                                  >
                                                    <PopoverBody>Gekopieerd!</PopoverBody>
                                                  </Popover>
                                                  <FontAwesomeIcon icon="copy" className="mr-2" />
                                                  Kopieren
                                                </Button>
                                              </div>
                                            </div>
                                          </FormGroup>

                                          {/*
                                          Temporary hide this field in CMS. See issue #WNW-296 in Jira
                                          */}

                                          {/*<FormGroup>*/}
                                          {/*  <Label for="fullUrl" size="sm">*/}
                                          {/*    VERKORTE URL*/}
                                          {/*  </Label>*/}
                                          {/*  <div style={{ display: 'flex', alignItems: 'center' }}>*/}
                                          {/*    <Input*/}
                                          {/*      type="text"*/}
                                          {/*      id="shortDealUrl"*/}
                                          {/*      name="shortDealUrl"*/}
                                          {/*      // value={shortDealUrl}*/}
                                          {/*      value={shortDealUrl}*/}
                                          {/*      disabled={true}*/}
                                          {/*    />*/}
                                          {/*    /!*<Button*!/*/}
                                          {/*    /!*  onClick={() => generateBitlyLink('dealurl')}*!/*/}
                                          {/*    /!*  disabled={isGenerating}*!/*/}
                                          {/*    /!*  className="ml-3"*!/*/}
                                          {/*    /!*>*!/*/}
                                          {/*    /!*  {isGenerating && <Spinner color="white" size="sm" />}*!/*/}
                                          {/*    /!*  {!isGenerating && 'Genereer'}*!/*/}
                                          {/*    /!*</Button>*!/*/}
                                          {/*    <div>*/}
                                          {/*      <Button*/}
                                          {/*        outline*/}
                                          {/*        color="secondary"*/}
                                          {/*        className="ml-3 d-flex align-items-center"*/}
                                          {/*        type="button"*/}
                                          {/*        onClick={() => copyUrlToClipboard('short')}*/}
                                          {/*        id="ShortUrlCopyButton"*/}
                                          {/*      >*/}
                                          {/*        <Popover*/}
                                          {/*          placement="right"*/}
                                          {/*          isOpen={shortUrlPopoverOpen}*/}
                                          {/*          target="ShortUrlCopyButton"*/}
                                          {/*        >*/}
                                          {/*          <PopoverBody>Gekopieerd!</PopoverBody>*/}
                                          {/*        </Popover>*/}
                                          {/*        <FontAwesomeIcon icon="copy" className="mr-2" />*/}
                                          {/*        Kopieren*/}
                                          {/*      </Button>*/}
                                          {/*    </div>*/}
                                          {/*  </div>*/}
                                          {/*</FormGroup>*/}
                                        </Col>
                                      </Row>

                                      {/*{isAutomaticDeal && (*/}
                                      {/*    <>*/}
                                      {/*      <hr />*/}
                                      {/*      <Form onSubmit={handleSubmit}>*/}
                                      {/*        <FieldArray*/}
                                      {/*            name="dealproviders"*/}
                                      {/*            render={arrayHelpers => (*/}
                                      {/*                <>*/}
                                      {/*                  {values.dealproviders &&*/}
                                      {/*                  values.dealproviders.length > 0 &&*/}
                                      {/*                  values.dealproviders.map((provider, index) => (*/}
                                      {/*                      <div key={index}>*/}
                                      {/*                        <Row form>*/}

                                      {/*                          <Col md={6} lg={3} xl={3}>*/}
                                      {/*                            <FormGroup>*/}
                                      {/*                              <Label labelFor={`dealproviders.[${index}].deeplink`}>Deeplink</Label>*/}
                                      {/*                              <Input*/}
                                      {/*                                  type="text"*/}
                                      {/*                                  name={`dealproviders.[${index}].deeplink`}*/}
                                      {/*                                  value={*/}
                                      {/*                                    values.dealproviders[index].deeplink*/}
                                      {/*                                  }*/}
                                      {/*                                  onChange={handleChange}*/}
                                      {/*                                  onBlur={handleBlur}*/}
                                      {/*                                  placeholder="Vul deeplink in"*/}
                                      {/*                                  // disabled={isAutomaticDeal}*/}
                                      {/*                              />*/}
                                      {/*                            </FormGroup>*/}
                                      {/*                          </Col>*/}

                                      {/*                        </Row>*/}
                                      {/*                      </div>*/}
                                      {/*                  ))}*/}
                                      {/*                </>*/}
                                      {/*            )}*/}
                                      {/*        />*/}
                                      {/*      </Form>*/}
                                      {/*      <hr className="mt-0" />*/}
                                      {/*    </>*/}
                                      {/*)}*/}
                                    </>
                                  );
                                }
                              }}
                            </Query>
                            <hr />
                            <Row>
                              <Col lg={8} sm={12}>
                                <FormGroup>
                                  <Label for="fullUrl" size="sm">
                                    AFFILIATE URL
                                  </Label>
                                  <Input
                                    type="text"
                                    id="fullUrl"
                                    name="fullUrl"
                                    value={affiliateUrl}
                                    onChange={handleAffiliateUrlChange}
                                  />
                                  {affiliateUrlError && (
                                    <p style={{ color: 'red' }}>{affiliateUrlError}</p>
                                  )}
                                </FormGroup>
                                <FormGroup>
                                  <Label for="fullUrl" size="sm">
                                    BITLY AFFILIATE URL
                                  </Label>
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Input
                                      type="text"
                                      id="shortAffiliateUrl"
                                      name="shortAffiliateUrl"
                                      value={shortAffiliateUrl}
                                      disabled={true}
                                    />
                                    <Button
                                      onClick={() => generateBitlyLink('affiliateUrl')}
                                      // disabled={isGenerating || !activeDealproviderDeeplink}
                                      className="ml-3"
                                    >
                                      {isGenerating && <Spinner color="white" size="sm" />}
                                      {!isGenerating && 'Genereer'}
                                    </Button>
                                    <Button
                                      outline
                                      color="secondary"
                                      className="ml-3 d-flex align-items-center"
                                      type="button"
                                      onClick={() => copyUrlToClipboard('short-deeplink')}
                                      id="ShortAffiliateUrlCopyButton"
                                    >
                                      <Popover
                                        placement="right"
                                        isOpen={shortDeeplinkPopoverOpen}
                                        target="ShortAffiliateUrlCopyButton"
                                      >
                                        <PopoverBody>Gekopieerd!</PopoverBody>
                                      </Popover>
                                      <FontAwesomeIcon icon="copy" className="mr-2" />
                                      Kopieren
                                    </Button>
                                  </div>
                                </FormGroup>

                                <FormGroup>
                                  <Label for="fullUrl" size="sm">
                                    WFC short url
                                  </Label>
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Input
                                      type="text"
                                      id="shortAffiliateUrl"
                                      name="shortAffiliateUrl"
                                      value={
                                        initialData?.hashed_slug
                                          ? `https://weflycheap.nl/d/${initialData?.hashed_slug}`
                                          : ''
                                      }
                                      disabled={true}
                                    />

                                    <Button
                                      outline
                                      color="secondary"
                                      className="ml-3 d-flex align-items-center"
                                      type="button"
                                      onClick={() => copyUrlToClipboard('wfc-short-url')}
                                      id="ShortAffiliateUrlCopyButton"
                                    >
                                      <Popover
                                        placement="right"
                                        isOpen={shortDeeplinkPopoverOpen}
                                        target="ShortAffiliateUrlCopyButton"
                                      >
                                        <PopoverBody>Gekopieerd!</PopoverBody>
                                      </Popover>
                                      <FontAwesomeIcon icon="copy" className="mr-2" />
                                      Kopieren
                                    </Button>
                                  </div>
                                </FormGroup>

                                <FormGroup>
                                  <Label for="previewUrl" size="sm">
                                    Concept url
                                  </Label>
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Input
                                      type="text"
                                      id="previewUrl"
                                      name="previewUrl"
                                      value={
                                        currentSlug
                                          ? `https://weflycheap.nl/deal/preview/?concept=${currentSlug}`
                                          : ''
                                      }
                                      disabled={true}
                                    />

                                    <Button
                                      outline
                                      color="secondary"
                                      className="ml-3 d-flex align-items-center"
                                      type="button"
                                      onClick={() => copyUrlToClipboard('preview-url')}
                                      id="PreviewDealCopyButton"
                                    >
                                      <Popover
                                        placement="bottom"
                                        isOpen={previewPopoverOpen}
                                        target="PreviewDealCopyButton"
                                      >
                                        <PopoverBody>Gekopieerd!</PopoverBody>
                                      </Popover>
                                      <FontAwesomeIcon icon="copy" className="mr-2" />
                                      Kopieren
                                    </Button>
                                    <Button
                                      color="secondary"
                                      className="ml-3 d-flex align-items-center"
                                      type="button"
                                      onClick={() =>
                                        window.open(
                                          currentSlug
                                            ? `https://weflycheap.nl/deal/preview/?concept=${currentSlug}`
                                            : '',
                                          '_blank',
                                        )
                                      }
                                      id="OpenPreviewUrl"
                                    >
                                      <FontAwesomeIcon icon="external-link" className="mr-2" />
                                      Openen
                                    </Button>
                                  </div>
                                </FormGroup>

                                {/*<hr/>*/}
                                {/*<FormGroup>*/}
                                {/*  <Label labelFor="slug">Slug</Label>*/}
                                {/*  <Input*/}
                                {/*      type="text"*/}
                                {/*      id="slug"*/}
                                {/*      name="slug"*/}
                                {/*      value={currentSlug}*/}
                                {/*      disabled={slug}*/}
                                {/*      onChane={e => setCurrentSlug(e.target.vaue)}*/}
                                {/*  />*/}
                                {/*</FormGroup>*/}
                                {bitlyError && <p style={{ color: 'red' }}>{bitlyError}</p>}
                              </Col>
                            </Row>
                          </Card>
                        );
                      }}
                    </Formik>
                  )}
                </Mutation>
              )}
            </Mutation>
          )}
        </Mutation>
      </>
    );
  },
  areEqual,
);

function areEqual(prevProps, nextProps) {
  return (
    prevProps.dealId === nextProps.dealId && prevProps.isAutomaticDeal === nextProps.isAutomaticDeal
  );
}

DealUrls.propTypes = {
  isAutomaticDeal: PropTypes.bool.isRequired,
  dealId: PropTypes.string.isRequired,
  boardTypes: PropTypes.array.isRequired,
  allProviders: PropTypes.array.isRequired,
  airports: PropTypes.array,
  slug: PropTypes.string,
};

export default DealUrls;
