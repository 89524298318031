import React, { useState } from 'react';
import { Img } from './SelectableImgCSS';

const SelectableImg = ({ src, handleOnSelect, handleOnDeselect, alreadySelected }) => {
  const [selected, setSelected] = useState(false);

  function toggleSelected(handleOnSelect, handleOnDeselect) {
    if (!alreadySelected) {
      setSelected(!selected);
      if (!selected) {
        handleOnSelect();
      } else {
        handleOnDeselect();
      }
    }
  }

  return (
    <Img
      imgsrc={src}
      selected={selected}
      alreadySelected={alreadySelected}
      onClick={() => toggleSelected(handleOnSelect, handleOnDeselect)}
    ></Img>
  );
};

export default SelectableImg;
