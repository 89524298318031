import React from 'react';
import PropTypes from 'prop-types';

import {Row, Col, FormGroup, Input, Label} from 'reactstrap';
import Card from 'components/Card'
import RSSIcon from "assets/icons/rss-icon.svg";
const baseUrl = process.env.REACT_APP_BASE_URL;


const typeNames = {
    top: "Topdeal (Bovenaan)",
    right: "Rechterkolom",
    bottom: "Topdeal (Onderaan)",
    left: "Linkerkolom",
};

const UrlsRss = (props) => {
  return (
    <Row>
        <Col>
            <Card
                thumbnailSrc={RSSIcon}
                title="URL's RSS feeds"
                uniqueId="topdealbovenaan"
            >

                {
                 props.rssColumns.map(column => (
                     <Row>
                     <Col>
                         <FormGroup>
                             <Label>{typeNames[column.type]}</Label>
                             <Input type="text" value={`${baseUrl}/rss_feed/${column.id}`} />
                         </FormGroup>
                     </Col>
                     </Row>

                 ))
                }
            </Card>
        </Col>
    </Row>
  )
};

UrlsRss.propTypes = {
    rssColumns: PropTypes.array,
};

export default UrlsRss;
