import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'weflycheap-cke5-wordcount';
import { ckEditorConfig, getShortCodes } from './helpers';
import { Button, Col, FormFeedback, FormGroup, Label, Row, Input, UncontrolledCollapse } from 'reactstrap';
import LengthValidator from 'components/LengthValidator';
import { createEventTarget } from 'utils/helpers';
import { Field } from 'formik';

const debounce = (callback, wait) => {
  let timeout = null;
  return (...args) => {
    const next = () => callback(...args);
    clearTimeout(timeout);
    timeout = setTimeout(next, wait);
  };
};

let thisEditor = null;

function insert(str, index, value) {
  value = str.substr(index, 1) === ' ' ? ' ' + value : value;
  return str.substr(0, index) + value + str.substr(index);
}

const RichTextEditor = ({
  touched,
  handleChange,
  handleLinks,
  handleBlur,
  values,
  errors,
  title = false,
  tags = false,
}) => {
  const [focusedInput, setFocusedInput] = useState('intro');
  const [titleInputCaretPosition, setTitleInputCaretPosition] = useState(0);
  const titleRef = useRef(null);
  let [wordCount, setWordCount] = useState(0);

  const handleDebounce = debounce(callback => {
    callback();
  }, 500);

  function handleSetFocusedInput(event, input) {
    setFocusedInput(input);
    if (input === 'title') {
      setTitleInputCaretPosition(event.target.selectionEnd);
    }
  }

  function handleInputChange(event, fieldName) {
    handleChange(createEventTarget(fieldName, event.target.value));
    if(fieldName === 'title') {
      setTitleInputCaretPosition(event.target.selectionEnd);
    }
  }

  function makeIntroFrag(shortcode) {
    const docFrag = thisEditor.model.change(writer => {
      const p1 = writer.createElement('paragraph');
      const docFrag = writer.createDocumentFragment();

      writer.append(p1, docFrag);
      if (shortcode.code === '[prijs]') {
        writer.insertText('€' + shortcode.code, p1);
      } else {
        writer.insertText(shortcode.code, p1);
      }
      return docFrag;
    });

    return docFrag;
  }

  function addShortcode(values, index) {
    const shortcode = getShortCodes(values)[index];

    if (focusedInput === 'intro') {
      thisEditor.model.insertContent(makeIntroFrag(shortcode));
      handleChange({
        target: {
          name: 'intro',
          value: thisEditor.getData(),
        },
      });
    } else {
      handleChange({
        target: {
          name: 'title',
          value: insert(values.title, titleInputCaretPosition, shortcode.code === '[prijs]' ? '€' + shortcode.code : shortcode.code),
        },
      });
    }
  }

  function handleLinkChanges(data) {
    const linkRegex = /<a\s+(?:[^>]*?\s+)?href="([^"]*)"/gm;
    const originalLinks = [...data.matchAll(linkRegex)];
    const originalLinksUrls = originalLinks.map(match => match[1]);
    const editedLinks = handleLinks instanceof Function
        ? handleLinks(originalLinksUrls)
        : originalLinksUrls;

    originalLinks.forEach((value, index) => {
      if (value[1] !== editedLinks[index]) {
        data = data.split(value[0]).join(value[0].replace(/href="(.*?)"/, `href="${editedLinks[index]}"`));
      }
    })

    return data;
  }

  return (
    <Row form>
      {title && (
        <Col lg={12}>
          <FormGroup>
            <Label for="title" size="sm">
              Titel
            </Label>
            <Input
              type="text"
              id="title"
              ref={titleRef}
              tag={Field}
              name="title"
              onChange={e => handleInputChange(e, 'title')}
              onMouseUp={e => handleSetFocusedInput(e, 'title')}
              invalid={Boolean(errors.title && touched.title)}
              autoComplete="off"
              placeholder="Vul de titel in"
            />
            {errors.title && touched.title && <FormFeedback>{errors.title}</FormFeedback>}
            <LengthValidator countThisValue={values.title} maxValue={130} />
          </FormGroup>
        </Col>
      )}
      {title && (
          <Col lg={12}>
            <FormGroup>
              <Label for="subtitle" size="sm">
                Subtitel
              </Label>
              <Input
                  type="text"
                  id="subtitle"
                  ref={titleRef}
                  tag={Field}
                  name="subtitle"
                  onChange={e => handleInputChange(e, 'subtitle')}
                  onMouseUp={e => handleSetFocusedInput(e, 'subtitle')}
                  invalid={Boolean(errors.title && touched.title)}
                  autoComplete="off"
                  placeholder="Vul de subtitel in"
              />
              {errors.subtitle && touched.subtitle && <FormFeedback>{errors.subtitle}</FormFeedback>}
              <LengthValidator countThisValue={values.subtitle} maxValue={130} />
            </FormGroup>
          </Col>
      )}


      <Col lg={12}>
        <FormGroup className="mb-0">
          <Label for="intro" size="sm">
            Introductie
          </Label>
          <CKEditor
            editor={ClassicEditor}
            data={values.intro}
            onFocus={() => handleSetFocusedInput(null, 'intro')}
            onInit={editor => {
              thisEditor = editor;
              const textLength = editor.plugins.get('WordCount').characters;
              setWordCount(textLength);
              thisEditor = editor;
            }}
            onChange={(event, editor) => {
              handleDebounce(() => {
                let data = editor.getData();
                const textLength = editor.plugins.get('WordCount').characters;
                setWordCount(textLength);

                handleChange({
                  target: {
                    name: 'introLength',
                    value: textLength,
                  },
                });

                data = handleLinkChanges(data)

                handleChange({
                  target: {
                    name: 'intro',
                    value: data,
                    editor: editor,
                  },
                });
              });
            }}
            config={ckEditorConfig}
            onBlur={handleBlur}
          />
          <Input invalid={Boolean(errors.intro)} style={{ display: 'none' }} />
          {errors.intro && <FormFeedback>{errors.intro}</FormFeedback>}
        </FormGroup>
        <LengthValidator length={wordCount} maxValue={1000} />
      </Col>

      {tags && (
        <Col lg={12}>
          <Row>
            <Col className="d-flex justify-content-center mt-3">
              {getShortCodes(values).map((shortcode, index) => {
                return (
                  <Button
                    outline
                    color="secondary"
                    onClick={() => addShortcode(values, index)}
                    className="mr-0 mr-md-1 mb-2 mb-md-0"
                  >
                    {shortcode.code}
                  </Button>
                );
              })}
            </Col>
          </Row>
        </Col>
      )}
      <Col className="mt-3">
        <hr />
        <Button color="secondary" outline id="toggler" style={{ marginBottom: '1rem' }}>
          HTML toevoegen
        </Button>
        <UncontrolledCollapse toggler="#toggler">
          <Input type="textarea"
            id="additional_html"
            tag={Field}
            name="additional_html"
            onChange={handleChange}>
          </Input>
        </UncontrolledCollapse>
      </Col>
    </Row>
  );
};

RichTextEditor.propTypes = {
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleLinks: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  title: PropTypes.bool,
  tags: PropTypes.bool,
};

export default RichTextEditor;
