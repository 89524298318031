import React from 'react';
import { Query } from 'react-apollo';
import { GET_DESTINATION } from 'queries/deals';
import { Col, Row, Alert, Spinner } from 'reactstrap';
import { Formik, Form } from 'formik';
import DestinationIntroduction from 'containers/Content/Destinations/DestinationIntroduction';
import DestinationImages from 'containers/Content/Destinations/DestinationImages';
import { withRouter } from 'react-router-dom';

let destination = {
  id: '',
  name: '',
  description: '',
  parent: { label: '', value: '' },
  latitude: 0,
  longitude: 0,
};

const DestinationUpdate = ({ match }) => {
  //Als er op nieuwe bestemming wordt geklikt als de gebruiker al op deze pagina zit dan moeten deze waardes weer leeg gemaakt worden
  if (match.params.id === 'new') {
    destination.id = '';
    destination.name = '';
    destination.description = '';
    destination.parent = '';
    destination.latitude = 0;
    destination.longitude = 0;
  }

  return (
    <Query query={GET_DESTINATION} variables={{ id: match.params.id }}>
      {({ loading, errors, data }) => {
        if (loading) return <Spinner type="grow" color="primary" />;
        if (match.params.id !== 'new') {
          if (errors) return `Error! ${errors.message}`;

          if (data.destination) {
            destination = data.destination;
            if (data.destination.parent) {
              destination.parent = {
                label: data.destination.parent.name,
                id: data.destination.parent.id,
              };
            }
          }
        }

        return (
          data && (
            <Formik
              enableReinitialize={true}
              initialValues={destination}
              onSubmit={() => { }}
            >
              {props => {
                const { values, handleChange, handleSubmit } = props;

                return (
                  <Form onSubmit={handleSubmit}>
                    {!data.destination && match.params.id !== 'new' ? (
                      <Alert color="danger">Oeps deze bestemming is niet gevonden.</Alert>
                    ) : (
                        <Row className="d-flex justify-content-center">
                          <Col>
                            <Row className="pt-4">
                              <Col lg={12}>
                              <DestinationIntroduction
                                  values={values}
                                  handleChange={handleChange}
                                />
                                {values.id && (
                                  <DestinationImages
                                    values={values}
                                    handleChange={handleChange}
                                  />
                                )}
                              </Col>
                              <Col lg={12}>{/* <Button type="submit">Opslaan</Button> */}</Col>
                            </Row>
                          </Col>
                        </Row>
                      )}
                  </Form>
                );
              }}
            </Formik>
          )
        );
      }}
    </Query>
  );
};

export default withRouter(DestinationUpdate);
