import React, { useState, useEffect } from 'react';
import {
  InputGroup,
  Input,
  Button,
  InputGroupAddon,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  InputGroupButtonDropdown,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import useDebounce from '../../hooks/useDebounce.js';
import { Query } from 'react-apollo';
import { GET_DEALTYPES } from 'queries/deals';

function compare( a, b ) {
  if ( a.name < b.name ){
    return -1;
  }
  if ( a.name > b.name ){
    return 1;
  }
  return 0;
}

function sortDealTypes(dealTypes){
  return dealTypes ? dealTypes.sort(compare) : null;
}

const Search = ({
  handleNewSearchTextValue,
  history,
  isUsedInDeals,
  handleNewSelectedDealtype,
  placeholder = '',
}) => {
  const [localSearchValue, setLocalSearchValue] = useState('');
  const { debouncedValue, count } = useDebounce(localSearchValue, 400);
  const [splitButtonOpen, setSplitButtonOpen] = useState(false);
  const [selectedDealtype, setSelectedDealtype] = useState('Alle types');

  function handleSelectedDealtypeChange(dealtype) {
    setSelectedDealtype(dealtype.name);
    handleNewSelectedDealtype(dealtype);
  }

  function toggleSplit() {
    setSplitButtonOpen(!splitButtonOpen);
  }

  function handleInputChange(event) {
    setLocalSearchValue(event.target.value);
  }

  useEffect(
    () => {
      if (count !== 0) {
        handleNewSearchTextValue(debouncedValue);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedValue],
  );

  return (
    <InputGroup>
      <Input
        onChange={handleInputChange}
        value={localSearchValue}
        bsSize="lg"
        placeholder={placeholder}
      />
      {isUsedInDeals && (
        <InputGroupButtonDropdown addonType="prepend" isOpen={splitButtonOpen} toggle={toggleSplit}>
          <DropdownToggle split outline className="searchbar-dropdown-toggle">
            <span style={{ minWidth: '95px', display: 'inline-block' }}>{selectedDealtype}</span>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              onClick={() => handleSelectedDealtypeChange('Alle types')}
              active={selectedDealtype === 'Alle types'}
            >
              Alle types
            </DropdownItem>
            <Query query={GET_DEALTYPES}>
              {({ loading, error, data }) => {
                if (loading) return <p>laden ...</p>;
                if (error) return <p>er ging iets mis</p>;
                const sortedDealTypes = sortDealTypes(data.dealtypes);
                return (
                  <>
                    {data &&
                      data.dealtypes &&
                      sortedDealTypes.map(dealtype => (
                        <DropdownItem
                          key={dealtype.id}
                          onClick={() => handleSelectedDealtypeChange(dealtype)}
                          active={dealtype.name === selectedDealtype}
                        >
                          {dealtype.name}
                        </DropdownItem>
                      ))}
                  </>
                );
              }}
            </Query>
          </DropdownMenu>
        </InputGroupButtonDropdown>
      )}
      <InputGroupAddon addonType="append">
        <Button color="primary">
          Zoeken
        </Button>
      </InputGroupAddon>
    </InputGroup>
  );
};

export default withRouter(Search);
