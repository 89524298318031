import axios from "axios";

export const formatBoardTypes = (boardTypes) => {
  return boardTypes.map(boardType => ({
    label: boardType.name, value: boardType.id
  }))
};

function generatePromiseForBitly(longUrl) {
  let formData = {};
  formData.long_url = longUrl;
  return axios({
    url: 'https://api-ssl.bitly.com/v4/bitlinks',
    method: 'POST',
    headers: {
      authorization: 'Bearer 6c682be4489633f00e1a9ecb7c6d28ed6b48e707',
      'Content-Type': 'application/json',
    },
    data: formData,
  });
}


export async function generateBitlyLink(longUrl) {
  const promises = generatePromiseForBitly(longUrl);
  const results = await Promise.resolve(promises);
  return results.data.link
}
