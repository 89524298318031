import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import { FormGroup, Input, Label } from 'reactstrap';
import useDebounce from 'hooks/useDebounce';
import LengthValidator from "components/LengthValidator";

const DebouncedInput = props => {
  const [localValue, setLocalValue] = useState(props.value);
  const { debouncedValue } = useDebounce(localValue, 500);

  useEffect(() => {
    props.handleChange(props.name, debouncedValue);
  }, [debouncedValue]);

  useEffect(() => {
    if(props.reInitialize){
      setLocalValue(props.value)
    }
  }, [props.value]);

  const handleChange = event => {
    setLocalValue(event.target.value);
  };

  return (
    <FormGroup>
      <Label for="date" size="sm">
        {props.label}
      </Label>
      <Input {...props} value={localValue} onChange={handleChange} />
      {props.maxLength && (
          <LengthValidator length={localValue.length} maxValue={props.maxLength} />
      )}
    </FormGroup>
  );
};

DebouncedInput.propTypes = {
  handleChange: PropTypes.func,
  reInitialize: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.object,
  maxLength: PropTypes.number
};

export default DebouncedInput;
