import React, { useState, useEffect } from 'react';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { UPDATE_BOOKING_EXAMPLE } from 'queries/deals';
import { Mutation } from 'react-apollo';

const UpdateBookingExample = ({
  toggleUpdateBookingExampleOpen,
  currentBookingExample,
  onSave,
}) => {
  const [bookingExample, setBookingExample] = useState(null);

  useEffect(() => {
    setBookingExample(currentBookingExample);
  }, [currentBookingExample]);

  function handleTitleChange(e) {
    const newbookingExample = { ...bookingExample, title: e.target.value };
    setBookingExample(newbookingExample);
  }

  function handleUrlChange(e) {
    const newbookingExample = { ...bookingExample, url: e.target.value };
    setBookingExample(newbookingExample);
  }

  function handleSaveBookingExample() {
    const variables = {
          id: bookingExample.id,
          title: bookingExample.title,
          url: bookingExample.url,
        };
    onSave(variables);
    return {
     variables
    };
  }

  function handleUpdateCompletion(event) {}

  return (
    <Mutation mutation={UPDATE_BOOKING_EXAMPLE} onCompleted={handleUpdateCompletion}>
      {(saveBookingExample, { loading, error }) => (
        <Modal
          size="lg"
          isOpen={Boolean(currentBookingExample)}
          toggle={() => toggleUpdateBookingExampleOpen(null)}
        >
          <ModalHeader toggle={() => toggleUpdateBookingExampleOpen(null)}>
            Wijzig boekingsvoorbeeld
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col sm={12}>
                <FormGroup>
                  <Label for="price" size="sm">
                    Titel
                  </Label>
                  <Input
                    value={bookingExample && bookingExample.title ? bookingExample.title : ''}
                    onChange={handleTitleChange}
                    type="text"
                    id="days"
                    name="days"
                    autoComplete="off"
                    placeholder="Vul titel in"
                  />
                </FormGroup>
              </Col>

              <Col sm={12}>
                <FormGroup>
                  <Label for="price" size="sm">
                    Url
                  </Label>
                  <Input
                    value={bookingExample && bookingExample.url ? bookingExample.url : ''}
                    onChange={handleUrlChange}
                    type="text"
                    id="days"
                    name="days"
                    autoComplete="off"
                    placeholder="Vul URL in"
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="success" onClick={() => saveBookingExample(handleSaveBookingExample())}>
              Wijzig
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </Mutation>
  );
};

export default UpdateBookingExample;
