import gql from "graphql-tag";

export const GET_PRICES = gql`
    query getPrices($dealId: ID!, $manual_price: Boolean) {
        getPrices(where: {deal: $dealId, manual_price: $manual_price}) {
            prices {
                id
                date_from
                date_to
                persons
                night_count
                day_count
                departureairport {
                    id
                    name
                    iata
                }
                arrival_airport {
                    id
                    name
                    iata
                }
                board_type{
                    id
                    name
                }
                price
                provider {
                    id
                    name
                }
                manual_price
                expire_date
                transport_type
                highlight
                deeplink
            }
        }
    }
`;
